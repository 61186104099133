export const SET_STATUS = 'singleViewMenu:setStatus';
export const SET_HOST = 'singleViewMenu:setHost';
export const SET_TEXT_SEARCH = 'singleViewMenu:textSearch';
export const SET_NOTES_SEARCH = 'singleViewMenu:notesSearch';
export const SET_ADDITIONAL_INFORMATION_SEARCH = 'singleViewMenu:additionalInformationSearch';
export const SET_PROPERTY_TYPE = 'singleViewMenu:setPropertyType';
export const SET_DK_SELLER_RATING = 'singleViewMenu:setDkSellerRating';
export const SET_FEATURE = 'singleViewMenu:setFeature';
export const SET_ACTION_RECOMMENDED = 'singleViewMenu:setActionRecommended';

export const SET_SORT = 'singleViewMenu:setSort'

// ------------------------------- FILTERS ----------------------------------------
export function setStatus(statusValue) {
    return {
        type: SET_STATUS,
        payload: {
            statusValue
        }
    }
}

export function setHost(hostValue) {
    return {
        type: SET_HOST,
        payload: {
            hostValue
        }
    }
}

export function setTextSearch(value) {
    return {
        type: SET_TEXT_SEARCH,
        payload: {
            value
        }
    }
}

export function setNotesSearch(value) {
    return {
        type: SET_NOTES_SEARCH,
        payload: {
            value
        }
    }
}
export function setAdditionalInformationSearch(value) {
    return {
        type: SET_ADDITIONAL_INFORMATION_SEARCH,
        payload: {
            value
        }
    }
}
export function setPropertyType(propertyType) {
    return {
        type: SET_PROPERTY_TYPE,
        payload: {
            propertyType
        }
    }
}

export function setDkSellerRating(dkSellerRating) {
    return {
        type: SET_DK_SELLER_RATING,
        payload: {
            dkSellerRating
        }
    }
}

export function setFeature(feature) {
    return {
        type: SET_FEATURE,
        payload: {
            feature
        }
    }
}

export function setActionRecommended(actionRecommended) {
    return {
        type: SET_ACTION_RECOMMENDED,
        payload: {
            actionRecommended
        }
    }
}

// ------------------------------- SORTING ----------------------------------------

export function setSort(sortOrder) {
    return {
        type: SET_SORT,
        payload: {
            sortOrder
        }
    }
}


