import React, { Component } from "react";

class AnalysisInput extends Component {

    render() {
        return (
          <div className="form-group row">
              <label htmlFor={this.props.id} className="col-sm-3 col-form-label">{this.props.label}</label>
              <div className="col-sm-9">
                  <div className="input-group mb-2">
                      <input onChange={(e) => this.props.handleOnChange(e.target.name, e.target.value)} type={this.props.type} className="form-control" id={this.props.id} value={this.props.value}
                             placeholder={this.props.label} disabled={this.props.disabled} name={this.props.id} />
                  </div>
              </div>
          </div>
        )
    }
}

export default AnalysisInput;
