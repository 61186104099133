import prepHeaders from "../../utils/prepHeaders";
import moment from "moment";
import axios from "axios";

export const SET_SUMMARY_LIST = 'summary:setList';
export const SET_FETCHING = 'summary:setFetching';
export const SET_SUMMARY = 'summary:set';
export const SET_SUMMARY_FETCHING = 'summary:setSummaryFetching';
export const ADD_SUMMARY_TO_LIST = 'summary:addToList';
export const SET_FETCHING_GENERATE = 'summary:setFetchingGenerate'

export const setFetching = (status) => {
    return {
        type: SET_FETCHING,
        payload: {
            status
        }
    }
}

export const setSummaryList = (summaryListData) => {
    return {
        type: SET_SUMMARY_LIST,
        payload: {
            summaryListData
        }
    }
}

export const setSummary = (summary) => {
    return {
        type: SET_SUMMARY,
        payload: {
            summary
        }
    }
}

export const setSummaryFetching = (status) => {
    return {
        type: SET_SUMMARY_FETCHING,
        payload: {
            status
        }
    }
}

export const addSummaryToList = (summary) => {
    return {
        type: ADD_SUMMARY_TO_LIST,
        payload: {
            summary
        }
    }
}

export const setFetchingGenerate = (status) => {
    return {
        type: SET_FETCHING_GENERATE,
        payload: {
            status
        }
    }
}

export const doFetchSummaryList = () => {
    return dispatch => {

        let projectId = JSON.parse(localStorage.getItem('project')).id

        dispatch(setFetching(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/summary/list/${projectId}`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setSummaryList(response.data))
            dispatch(setFetching(false))
        }).catch(error => {
            dispatch(setFetching(false))
            console.log(error)
        })
    }
}

export const doFetchSummaryReport = (summaryId) => {
    return dispatch => {

        dispatch(setSummaryFetching(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/summary/${summaryId}`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setSummary(response.data))
            dispatch(setSummaryFetching(false))
        }).catch(error => {
            dispatch(setSummaryFetching(false))
            console.log(error)
        })
    }
}

export const doPublishStatus = (status, summaryId) => {
    return dispatch => {

        let payload = {
            status: status,
            summary_id: summaryId
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/summary/publish`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(setSummary(response.data))
        }).catch(error => {
            console.log(error.message)
        })
    }
}

export const doGenerateSummary = (data) => {
    return dispatch => {
        dispatch(setFetchingGenerate(true));

        let payload = {
            title: data.title,
            from: moment(data.from).format('yyyy-MM-DD'),
            to: moment(data.to).format('yyyy-MM-DD'),
            project_id: JSON.parse(localStorage.getItem('project')).id
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/summary/generate`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(addSummaryToList(response.data))
            dispatch(setFetchingGenerate(false));
        }).catch(error => {
            dispatch(setFetchingGenerate(false));
            console.log(error)
        })
    }
}
