import * as imagesDownloadActions from "../actions/imagesDownload/imagesDownloadActions";

const initialState = {
    fetching: false,
    list: [],
}

export default function imagesDownloadReducer(state = initialState, action) {
    switch (action.type) {
        case imagesDownloadActions.FETCHING:
            return {
                ...state,
                fetching: action.payload.status
            }

        case imagesDownloadActions.SET_DOWNLOAD_LIST:
            return {
                ...state,
                list: action.payload.list
            }
        default:
            return state
    }
}
