export function handleDkSellerRatingDisplayLabel(dkSellerRating) {
    switch (parseInt(dkSellerRating)) {
        case 1:
            return 'Low volume'
        case 2:
            return 'Medium volume'
        case 3:
            return 'High volume'
    }
}

export function handleActionRecommendedDisplayLabel(actionRecommended) {
    switch (parseInt(actionRecommended)) {
        case 0:
            return 'No';
        case 1:
            return 'Yes';
    }
}

export function handlePropertyTypeDisplayLabel(propertyType) {
    switch (parseInt(propertyType)) {
        case 1:
            return 'Marketplace';
        case 2:
            return 'Social';
        case 3:
            return 'Website';
    }
}

export function handleTestPurchaseDisplayLabel(testPurchase) {
    switch (parseInt(testPurchase)) {
        case 0:
            return 'No';
        case 1:
            return 'Yes';
    }
}

export function handleEnforcementStatusDisplayLabel(enforcementStatus) {
    switch (parseInt(enforcementStatus)) {
        case 1:
            return 'No action';
        case 2:
            return 'Identified';
        case 3:
            return 'Awaiting instructions';
        case 4:
            return 'No further action';
    }
}

export function handleDataMappings(dataKey, dataValue) {
    switch (dataKey) {
        case 'dk_seller_rating':
            return handleDkSellerRatingDisplayLabel(dataValue)
        case 'action_recomended':
            return handleActionRecommendedDisplayLabel(dataValue)
        case 'property_type':
            return handlePropertyTypeDisplayLabel(dataValue)
        case 'test_purchase':
            return handleTestPurchaseDisplayLabel(dataValue)
        case 'enforcement_status':
            return handleEnforcementStatusDisplayLabel(dataValue)
        case 'estimated_monthly_visits':
            return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(dataValue)
        case 'dupe_value':
            return dataValue === null ? 'N/A' : dataValue.toFixed(2) + ' %';
        default:
            return dataValue
    }
}

