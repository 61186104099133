import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo_kemp_little_w.png";
import { withRouter } from "react-router-dom";

class Sidebar extends Component {

    getCurrentProject = () => {
        return JSON.parse(localStorage.getItem('project')).id
    }

    render() {
        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                <div className={'d-flex align-items-center justify-content-center'}>
                    <NavLink to="/" className={'sidebar-brand sidebar-brand-text mx-3'}>Dupe Killer</NavLink>
                </div>

                <hr className="sidebar-divider my-0"/>

                <li  className={`nav-item`}>
                    <NavLink activeClassName={"active"} to={`/project/${this.getCurrentProject()}/dashboard`} className="nav-link">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span>
                    </NavLink>
                </li>

                <hr className="sidebar-divider"/>

                <div className="sidebar-heading">
                    Find
                </div>

                <li className={`nav-item`}>
                    <NavLink activeClassName={"active"} to={`/project/${this.getCurrentProject()}/search`} className="nav-link">
                        <i className="fas fa-fw fa-search"></i>
                        <span>Searches</span>
                    </NavLink>
                </li>
                <hr className="sidebar-divider"/>

                <div className="sidebar-heading">
                    Review
                </div>
                <li className={`nav-item`}>
                    <NavLink activeClassName={"active"} to={`/project/${this.getCurrentProject()}/shortlist`} className="nav-link">
                        <i className="fas fa-fw fa-list"></i>
                        <span>Shortlist</span>
                    </NavLink>
                </li>

                {/*<li className={`nav-item`}>*/}
                {/*    <NavLink activeClassName={"active"} to={`/project/${this.getCurrentProject()}/search-result`} className="nav-link">*/}
                {/*        <i className="fas fa-pager"></i>*/}
                {/*        <span>Single View Shortlist</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                <hr className="sidebar-divider"/>

                <div className="sidebar-heading">
                    Deliver
                </div>
                <li className={`nav-item`}>
                    <NavLink activeClassName={"active"} to={`/reports/${this.getCurrentProject()}`} className="nav-link">
                        <i className="fas fa-fw fa-file-excel"></i>
                        <span>Reports</span>
                    </NavLink>
                </li>
                <li className={`nav-item`}>
                    <NavLink activeClassName={"active"} to={`/reports/ff/${this.getCurrentProject()}`} className="nav-link">
                        <i className="fas fa-fw fa-file-excel"></i>
                        <span>FF Reports</span>
                    </NavLink>
                </li>
                <li className={`nav-item`}>
                    <NavLink activeClassName={"active"} to={`/summary`} className="nav-link">
                        <i className="fas fa-chart-bar"></i>
                        <span>Summary</span>
                    </NavLink>
                </li>
                <hr className="sidebar-divider"/>
                <div className="sidebar-heading">
                    Operations
                </div>
                <li className={`nav-item`}>
                    <NavLink activeClassName={"active"} to={`/images-download`} className="nav-link">
                        <i className="fas fa-image"></i>
                        <span>Images download</span>
                    </NavLink>
                </li>
            </ul>
        )
    }
}

export default withRouter(Sidebar);
