import {AUTH_DATA_IS_BEING_FETCHED, AUTH_DATA_IS_FETCHED, AUTH_DATA_ERROR, LOGOUT, SESSION_EXPIRED } from '../actions/authentication/authenticationAction';

const initialState = {
        email: localStorage.getItem('email') || null,
        userRoles: localStorage.getItem('roles') || null,
        authError: null,
        isBeingFetched: false,
        sessionExpiredStatus: false
};

export default function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_DATA_IS_BEING_FETCHED:
            return {
                ...state,
                isBeingFetched: action.payload.isBeingFetched
            };
        case AUTH_DATA_IS_FETCHED:
            return {
                ...state,
                email: action.payload.email
            };
        case AUTH_DATA_ERROR:
            return {
                ...state,
                authError: action.payload.authError
            };
        case LOGOUT:
            return {
                ...state,
                email: action.payload.email,
            };
        case SESSION_EXPIRED:
            return {
                ...state,
                sessionExpiredStatus: action.payload.sessionExpiredStatus
            };
        default:
            return state
    }
}
