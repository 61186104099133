import React, {Component} from 'react';
import {compose} from "redux";
import SuperAdminSidebar from "../Sidebar/SuperAdminSidebar";
import Topbar from "../Topbar";
import {connect} from "react-redux";
import {getSingleUser} from "../../actions/userManagement/userManagementAction";
import {
    fetchClientAndProjectList,
    openClientProjects
} from "../../actions/clientAndProjectList/clientAndProjectListAction";
import {Redirect, withRouter} from "react-router-dom";
import UserProjectRow from "./UserProjectRow";
import prepHeaders from "../../utils/prepHeaders";
import getUserRoles from "../../utils/auth/getUserRoles";
import hasAccessLevel from "../../utils/auth/hasAccessLevel";
import {ROLES} from "../../utils/enums/Roles";
import axios from "axios";


class UserProjects extends Component {

    handleProjectButton(userId, getSingleUser) {

        return (projectId, activeProject) => {

            // ADD - activeProject = false
            // REMOVE - activeProject = true

            let requestPayload = {
                user_id: userId,
                project_id: projectId
            }

            axios({
                url: process.env.REACT_APP_API_BASE_URL + (() => activeProject ? '/user/remove-project' : '/user/add-project')(),
                method: 'POST',
                withCredentials: true,
                headers: prepHeaders(),
                data: requestPayload
            }).then(response => {
                this.props.getSingleUser(userId)
            }).catch(error => {
                console.log(error)
            })
        }
    }


    componentDidMount() {
        this.props.getSingleUser(this.props.match.params.userId)
        this.props.fetchClientAndProjectList()
    }

    render() {
        let selectedProjectsIds = [];
        if (this.props.userEdit) {
            selectedProjectsIds = this.props.userEdit.projects.map(x => x.id )
        }

        if (hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) === false) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <div id="page-top">
                <div id="wrapper">
                    <SuperAdminSidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs">
                            <Topbar withoutProjectStats={true} withoutBranding={true}/>
                            {this.props.userEdit && this.props.clientAndProjectList &&
                                <div className="container">
                                    <h1 className="h3 mb-0 text-white-800">Projects for: <span className={'font-weight-bold'}>{this.props.userEdit.email}</span></h1>
                                    <hr/>

                                    <h4 className="mt-4 mb-3">Clients</h4>
                                    <div className="accordion">
                                        {this.props.clientAndProjectList.clientAndProjectList.length > 0 &&
                                            this.props.clientAndProjectList.clientAndProjectList.map(client => {
                                                return <div className="card" key={client.id}>
                                                    <div className="card-header" id="headingOne">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link" type="button" aria-expanded="true" aria-controls="collapseOne" onClick={() => this.props.handleOpenClientProjects(client.id)}>
                                                                {client.name}
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseOne" className={client.id === this.props.clientAndProjectList.openedClientId ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            {client.projects.map(project => {
                                                                return <UserProjectRow
                                                                    key={project.id}
                                                                    activeProject={selectedProjectsIds.includes(project.id)}
                                                                    project={project}
                                                                    buttonHandler={this.handleProjectButton(this.props.userEdit.id)}
                                                                />
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                    </div>

                                    <h4 className="mt-4 mb-3">Miscellaneous Projects</h4>
                                    <div className="card mb-4">
                                    {this.props.clientAndProjectList.miscellaneousProjectList.length > 0 &&
                                        this.props.clientAndProjectList.miscellaneousProjectList.map(project => {
                                            return (
                                                <UserProjectRow
                                                    key={project.id}
                                                    activeProject={selectedProjectsIds.includes(project.id)}
                                                    project={project}
                                                    buttonHandler={this.handleProjectButton(this.props.userEdit.id)}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userEdit: state.userManagement.userEdit,
        clientAndProjectList: state.clientAndProjectList
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        getSingleUser: (userId) => {dispatch(getSingleUser(userId))},
        // fetchProjectList: () => {dispatch(fetchProjectList())},
        fetchClientAndProjectList: () => {dispatch(fetchClientAndProjectList())},
        handleOpenClientProjects: (clientId) => {dispatch(openClientProjects(clientId))},
    }
}

export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(UserProjects)
