import React, {Component, Fragment} from 'react';
import {compose} from "redux";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import Topbar from "../Topbar";
import SuperAdminSidebar from "../Sidebar/SuperAdminSidebar";
import UserRow from "./UserRow";
import {connect} from "react-redux";
import {getAllUsers} from "../../actions/userManagement/userManagementAction";
import getUserRoles from "../../utils/auth/getUserRoles";
import hasAccessLevel from "../../utils/auth/hasAccessLevel";
import {ROLES} from "../../utils/enums/Roles";


class UserManagement extends Component {

    constructor(props) {
        super(props)

        this.state = {
            slidingFilterPanelStatus: false
        }
    }

    componentDidMount() {
        this.props.getAllUsers()
    }

    render() {
        if (hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) === false) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <div id="page-top">
                <div id="wrapper">
                    <SuperAdminSidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs">
                            <Topbar withoutProjectStats={true} withoutBranding={true}/>
                            <div className="container">
                                <h1 className="h3 mb-0 text-white-800">User Management</h1>

                                <hr/>
                                <div className="row">
                                    <div className={'col-12 mt-3 d-flex justify-content-center'}>
                                        <NavLink to="/users/new" className={'btn btn-primary'}>New User</NavLink>
                                    </div>
                                </div>

                                {this.props.users.length > 0 &&
                                    this.props.users.map( user => {
                                        return (
                                            <Fragment key={user.id}>
                                                <hr/>
                                                <UserRow {...user} />
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.userManagement.users,
        fetchingUsers: state.userManagement.fetchingUsers
    }
};

const mapActionsToProps = (dispatch) => {
    return {
        getAllUsers: () => {dispatch(getAllUsers())}
    }
}


export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(UserManagement);