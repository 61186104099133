import React, {Component} from 'react';

class SvsNotes extends Component {

    render() {
        return (
          <div className="row">
              <div className={`col-${this.props.labelCol}`}><p>Notes</p></div>
              <div className={`col-${this.props.valueCol}` + " px-0"}>
                  <input onBlur={() => this.props.saveNotes(this.props.id, this.props.notesValue)} onChange={(e) => this.props.setNotes(e.target.value)} type="text" className="form-control form-control-user" defaultValue={this.props.notesData} />
              </div>
          </div>
        )
    }
}

export default SvsNotes