import {SET_MONITORING_DATA} from "../actions/monitoring/monitoringActions";

const initialState = {
    fetchingMonitoringData: false, // TODO - perhaps this should be true
    services: [],
    workers: [],
    latest_search_results: [],
    system_healthy: true,
}
export default function monitoringReducer(state = initialState, action)
{
    switch(action.type) {
        case SET_MONITORING_DATA:
            return {
                ...state,
                services: action.payload.monitoringData.services,
                workers: action.payload.monitoringData.workers,
                latest_search_results: action.payload.monitoringData.latest_search_results,
                system_healthy: action.payload.monitoringData.system_healthy
            };
        default: return state
    }
}