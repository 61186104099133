import React, {Component} from 'react';
import Modal from 'react-bootstrap4-modal';
import DatePicker from 'react-datepicker';

class NewSummaryModal extends Component {

    constructor(props) {
        super(props);

        let fromDate = new Date()
        let pastDate = fromDate.setDate(fromDate.getDate() - 7)

        this.state = {
            title: 'Summary - ',
            from: pastDate,
            to: new Date()
        }
    }

    handleGenerate = () => {
        this.props.doGenerateSummary(this.state)
        this.props.handleModalVisibility(false)
    }

    render() {
        return (
          <div className="query-modal">
              <Modal dialogClassName="modal-md" visible={this.props.visibility} onClickBackdrop={() => {
                  this.props.handleModalVisibility(false)
              }}>
                  <div className="modal-header">
                      <h5 className="modal-title">New summary report</h5>
                  </div>
                  <div className="modal-body">
                      <div className="row justify-content-center">
                          <div className="col-12">
                              <label>Title</label>
                              <input value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} className="form-control" type="text" name="title"/>
                          </div>

                          <div className="col-12 mt-3">
                              <span>Period</span>
                          </div>
                          <div className="col-sm-12 mb-3">
                              <DatePicker
                                selected={this.state.from}
                                onChange={date => this.setState({from: date})}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="From"
                              />
                          </div>
                          <div className="col-sm-12">
                              <DatePicker
                                selected={this.state.to}
                                onChange={date => this.setState({to: date})}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="To"
                              />
                          </div>
                      </div>
                  </div>
                  <div className="modal-footer">
                      <button onClick={() => {
                          this.props.handleModalVisibility(false)
                      }} type="button" className="btn btn-danger">
                          Cancel
                      </button>

                      <button onClick={(e) => this.handleGenerate(e)}
                              className="btn btn-success btn-icon-split">
                                          <span className="icon text-white-50">
                                            <i className="fas fa-file-download"></i>
                                          </span>
                          <span className="text">Generate</span>
                      </button>
                  </div>
              </Modal>
          </div>
        )
    }
}

export default NewSummaryModal;