import React, {Component} from "react";
import {formatNumberAsIntl} from "../../../utils/format";
import moment from "moment";
import {Link} from "react-router-dom";
import {getStatusFilterOptions} from "../../../utils/status/statusFilterOptions";

class ExecutiveSummary extends Component {

    handleNumberOfDupesCountClick = (e) => {
        e.preventDefault()
        this.props.resetFiltersToDefaul()
        this.props.setRemoteStatus(getStatusFilterOptions()[0])
        this.props.setIsDatePresentedToClient(true)
        this.props.setDatePresentedToClientStart(new Date(this.props.summary.from))
        this.props.setDatePresentedToClientEnd(new Date(this.props.summary.to))
        this.props.router.push(`/project/${JSON.parse(localStorage.getItem('project')).id}/shortlist`)
    }

    render() {

        let project = JSON.parse(localStorage.getItem('project'));
        let route = `/project/${JSON.parse(localStorage.getItem('project')).id}/shortlist`

        return (
            <div className="card shadow executive-summary">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Executive summary</h6>
                    <h6>{`${moment(this.props.summary.from).format('DD/MM/YYYY')} - ${moment(this.props.summary.to).format('DD/MM/YYYY')}`}</h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-6 dupes d-flex justify-content-center align-items-center flex-column text-center py-5">
                            <p>We found <span className="number-of-dupes" onClick={(e) => this.handleNumberOfDupesCountClick(e)}>{formatNumberAsIntl(this.props.summary.summary_data.dupe_count)}</span> dupes</p>
                            <p> across <span>{formatNumberAsIntl((this.props.summary.summary_data.pages_scanned))}</span> scanned pages</p>
                            <p>The top website on which a dupe was found is:</p>
                            <a className="top-website" target="_blank" rel="noopener noreferrer" href={"//" + this.props.topWebsitesByCount[0].domain}>{this.props.topWebsitesByCount[0].domain}</a>
                            <div className="d-flex mt-3">
                                <Link to={route} className="btn btn-secondary">{`Go to Shortlist`}</Link>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 py-5">
                            <div className="product-container w-100">
                                <div style={{backgroundImage: `url("${project.view_template.styles.image_url}")`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}} className="product-image-container">
                                    {/*<img alt="original product image" className="img img-fluid product-image" src={project.styles.image_url} />*/}
                                </div>
                                <p>Original Product Image</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExecutiveSummary;