import React, {Component} from "react";
import Select from "react-select";
import getPropertyTypeFilterOptions from "../../utils/filters/propertyTypeFilterOptions";

class EditForm extends Component {

    constructor(props) {
        super(props);

        this.state = this.getInitialState()
    }

    getInitialState = () => {
        return {
            domain: this.props.data.domain,
            domainOnly: this.props.data.domain_only,
            emv: this.props.data.estimated_monthly_visits || '',
            countries: this.props.data.countries,
            region: this.props.data.region,
            audience: this.props.data.audience,
            propertyType: getPropertyTypeFilterOptions().filter(option => option.value === this.props.data.property_type)[0]
        }
    }

    handleInputChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
          <>
              <div className="form-row">
                  <div className="form-group col-md-6">
                      <label>Estimated monthly visits</label>
                      <input className="form-control" min={0} name="emv"
                             onChange={(e) => this.handleInputChange(e)} type="number"
                             value={this.state.emv}/>
                  </div>
                  <div className="form-group col-md-6">
                      <label>Property type</label>
                      <Select
                        options={getPropertyTypeFilterOptions()}
                        value={this.state.propertyType}
                        onChange={(option) => this.setState(({
                            propertyType: option
                        }))}
                      />
                  </div>
              </div>
              <div className="form-row">
                  <div className="form-group col-md-6">
                      <label>Shipping</label>
                      <textarea
                        className="form-control"
                        rows={5}
                        cols={20}
                        value={this.state.countries}
                        name="countries"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                  </div>
                  <div className="form-group col-md-6">
                      <label>Shipping region</label>
                      <textarea
                        className="form-control"
                        rows={5}
                        cols={20}
                        value={this.state.region}
                        name="region"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                  </div>
              </div>
              <div className="form-row">
                  <div className="form-group col-md-6">
                      <label>Audience</label>
                      <textarea
                        className="form-control"
                        rows={5}
                        cols={20}
                        value={this.state.audience}
                        name="audience"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                  </div>
              </div>
              <div className="form-row mt-4">
                  <div className="form-group col-md-12 text-center">
                        <button onClick={() => this.props.saveDomainMeta(this.props.data.id, this.state)} className="btn btn-primary">
                            {this.props.posting ? <i className="fas fa-circle-notch fa-spin text-white"></i> : 'Save'}
                        </button>
                  </div>
              </div>
          </>
        )
    }
}

export default EditForm;