import React, {Component, Fragment} from "react";
import SuperAdminSidebar from "../Sidebar/SuperAdminSidebar";
import Topbar from "../Topbar";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getAllImportProjects} from "../../actions/importProject/importProjectAction";
import ImportProjectRow from "./ImportProjectRow";


class ListImportProjects extends Component {

    componentDidMount() {
        this.props.getAllImportProjects()
    }
    render() {
        return (
            <div id="page-top">
                <div id="wrapper">
                    <SuperAdminSidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs">
                            <Topbar withoutProjectStats={true} withoutBranding={true}/>
                            <div className="container">
                                <h1 className="h3 mb-0 text-white-800">Import Projects</h1>

                                {this.props.importProjects.length > 0 &&
                                    this.props.importProjects.map( importProject => {
                                        return (
                                            <Fragment key={importProject.id}>
                                                <hr/>
                                                <ImportProjectRow {...importProject} />
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        importProjects: state.importProjects.importProjects,
        fetchingImportProjects: state.importProjects.fetchingImportProjects,
    }
};

const mapActionsToProps = (dispatch) => {
    return {
        getAllImportProjects: () => {dispatch(getAllImportProjects())}
    }
}

export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(ListImportProjects)
