import React, {Fragment} from 'react';

const ImagesDownloadTableHeader = props => {
    return (
        <Fragment>
            <div className="form-inline">
                <button
                    onClick={() => props.toggleModal(true)}
                    className="btn btn-success btn-icon-split ml-auto">
                                      <span className="icon text-white-50">
                                          <i className="fas fa-file-download"></i>
                                      </span>
                    <span className="text">New download</span>
                </button>
            </div>
        </Fragment>
    )
};

export default ImagesDownloadTableHeader;
