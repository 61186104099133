import prepHeaders from '../../utils/prepHeaders';
import { isBeingClassifiedShortlist, classifiedShortlist } from "../images/imagesAction";
import { setStatusFilter } from "../filter/filterAction";
import axios from "axios";

export const doClassifyShortlist = (images, selectedModelId, statusFilter) => {
    return dispatch => {

        if (images.length !== 0) {
            images.forEach((imageObject, index) => {
                dispatch(setStatusFilter(statusFilter))
                let currentImage = images[index];

                let payload = {
                    search_result: {...currentImage.search_result},
                    model: {
                        id: selectedModelId
                    }
                };

                dispatch(isBeingClassifiedShortlist(imageObject.search_result.search_hash, true));

                axios({
                    url: process.env.REACT_APP_API_BASE_URL + "/classify",
                    method: 'POST',
                    withCredentials: true,
                    headers: prepHeaders(),
                    data: payload
                }).then(response => {
                    dispatch(classifiedShortlist(response.data, imageObject.search_result.search_hash));
                    dispatch(isBeingClassifiedShortlist(imageObject.search_result.search_hash, false));
                    dispatch(setStatusFilter(statusFilter))
                }).catch(error => {
                    dispatch(setStatusFilter(statusFilter))
                    console.log(error)
                })
            })
        }
    }
};
