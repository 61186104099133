import { SET_SHORTLIST_IMAGES, UPDATE_SEARCH_RESULT_FEATURES, FETCHINING } from "../actions/shortlist/shortlist";
import {
    IS_BEING_CLASSIFIED_SHORTLIST, CLASSIFIED_SHORTLIST,
} from "../actions/images/imagesAction";

const initialState = {
    list: [],
    fetching: false
};

export default function shortlistReducer(state = initialState, action) {
    switch (action.type) {
        case FETCHINING:
            return {
              ...state,
              fetching: action.payload.status
            }
        case SET_SHORTLIST_IMAGES:
            return {
                ...state,
                list: action.payload.images
            }
        case IS_BEING_CLASSIFIED_SHORTLIST:
            return {
                ...state,
                list: state.list.map((image) => {
                    if (image.search_result.search_hash === action.payload.searchHash) {
                        image.isBeingClassified = action.payload.status
                    }
                    return image
                })
            };
        case CLASSIFIED_SHORTLIST:
            return {
                ...state,
                list: state.list.map((image) => {
                    if (image.search_result.search_hash === action.payload.searchHash) {
                        image.search_result = action.payload.searchResult
                    }
                    return image
                })
            };
        case UPDATE_SEARCH_RESULT_FEATURES:
            return {
                ...state,
                list: state.list.map(image => {
                    if (image.search_result.search_hash === action.payload.searchHash) {
                        image.search_result.features = action.payload.features
                    }
                    return image
                })
            }
        default:
            return state
    }
};
