import {
    SET_IDS_LIST,
    SET_FETCHING_IDS,
    SET_IDS_FOR_NAVIGATION,
    SET_INDEX_IDS_FOR_NAVIGATION,
    SET_SINGLE_VIEW_IMAGE_DATA,
    SET_FETCHING_SINGLE_VIEW_IMAGE_DATA,
} from "../actions/shortlist/singleViewShortlist";

import {UPDATE_SEARCH_RESULT_FEATURES} from "../actions/shortlist/shortlist";
import {SET_NOTES} from "../actions/notes/notes";

const initialState = {
    ids: [],
    imageData: null,
    fetching: false,
    fetchingIds: false,
    currentIdIndex: null,
    nextIdIndex: null,
    previousIdIndex: null,
    currentId: null,
    nextId: null,
    prevId: null,
    notes: ''
};

export default function singleViewShortlistReducer(state = initialState, action) {
    switch (action.type) {

        case SET_FETCHING_SINGLE_VIEW_IMAGE_DATA:
            return {
                ...state,
                fetching: action.payload.status
            }
        case SET_IDS_LIST:
            return {
                ...state,
                ids: action.payload.idsList
            }
        case SET_FETCHING_IDS:
            return {
                ...state,
                fetchingIds: action.payload.status
            }
        case SET_SINGLE_VIEW_IMAGE_DATA:
            return {
                ...state,
                imageData: action.payload.imageData
            }
        case SET_INDEX_IDS_FOR_NAVIGATION:
            return {
                ...state,
                currentIdIndex: action.payload.currentIdIndex,
                nextIdIndex: action.payload.nextIdIndex,
                previousIdIndex: action.payload.prevIdIndex
            }
        case SET_IDS_FOR_NAVIGATION:
            return {
                ...state,
                currentId: action.payload.currentId,
                nextId: action.payload.nextId,
                prevId: action.payload.prevId
            }
        case UPDATE_SEARCH_RESULT_FEATURES:

            let copyOfImagedata = {...state.imageData}

            copyOfImagedata.features = action.payload.features

            return {
                ...state,
                imageData: copyOfImagedata
            }
        case SET_NOTES:
            return {
                ...state,
                notes: action.payload.notes
            }
        default:
            return state
    }
}