import prepHeaders from "./prepHeaders";
import axios from "axios";

export default function cleanupLocalStorage() {

    axios({
        url: process.env.REACT_APP_API_BASE_URL + "/logout",
        method: 'GET',
        withCredentials: true,
        headers: prepHeaders(),
    }).then(() => {
    }).catch((error) => {
        console.log(error)
    })

    localStorage.removeItem('roles')
    localStorage.removeItem('project_template')
    localStorage.removeItem('email')
    localStorage.removeItem('project');
    localStorage.removeItem('project_stats');
}
