import React, {Fragment} from 'react';
import DatePicker from "react-datepicker";

const HeaderMenu = (props) => {
    return (
        <Fragment>
            <div className="form-inline">
                <div className="form-group mr-3">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="">Find in list</span>
                        </div>
                        <input onChange={e => props.handleOnFilterTextChange(e.target.value)} value={props.value} type="text" className="form-control form-control-user" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="mr-1">Date from</label>
                    <div className="input-container-date">
                        <DatePicker
                            selected={props.startDate}
                            onChange={date => props.handleStartDateChange(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select date"
                        />
                    </div>
                </div>
                <div className="form-group ml-3">
                    <label className="mr-1">Date to</label>
                    <div className="input-container-date">
                        <DatePicker
                            selected={props.endDate}
                            onChange={date => props.handleEndDateChange(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select date"
                        />
                    </div>
                </div>
            </div>
            <button onClick={() => props.toggleModal(true)} className="btn btn-success btn-icon-split ml-auto">
                                          <span className="icon text-white-50">
                                              <i className="fas fa-file-download"></i>
                                          </span>
                <span className="text">New report</span>
            </button>
        </Fragment>
    )
};

export default HeaderMenu;