import React, {Component} from "react";

class UserProjectRow extends Component {

    render () {
        return (
            <div className="row justify-content-center mt-2">
                <div className='col-8 border-bottom'>
                    <div className={'mt-2'}>{this.props.project.display_name}</div>
                </div>
                <div className="col-1 border-bottom">
                    {this.props.activeProject ?
                        <button onClick={() => this.props.buttonHandler(this.props.project.id, true)} className='btn btn-danger image-info-btn mb-2'>
                            Remove
                        </button> :
                        <button onClick={() => this.props.buttonHandler(this.props.project.id, false)} className='btn btn-primary image-info-btn mb-2'>
                            Add
                        </button>
                    }

                </div>
            </div>
        )
    }
}

export default UserProjectRow