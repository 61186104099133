export function getStatusFilterOptions() {
    return [
        {value: null, label: 'All'},
        {value: 6, label: "Auto Shortlisted"},
        {value: 3, label: "Auto Rejected"},
        {value: 10, label: "Query"},
        {value: 1, label: "Shortlisted"},
        {value: 2, label: "Rejected"},
        {value: 4, label: "Confirmed"},
        {value: 8, label: "Staging"},
        {value: 11, label: "Incorrect"},
        {value: 12, label: "Counterfeit"},
        {value: 7, label: "Current Month"},
        {value: 5, label: "Enforced"},
        {value: 9, label: "Previous Months"}
    ]
}

export const labelFromStatusCode = statusCode => {

    if (typeof statusCode === 'undefined') {
        return ''
    }

    let result = getStatusFilterOptions().filter(status => status.value === parseInt(statusCode))

    if (result.length === 0) {
        throw new Error(`No search result status exists for the status code: ${statusCode}`)
    }

    return result[0].label;
}