export default function prepareFilters(filters) {

    let payload = {
        status: filters.status.value,
        host: filters.host,
        textSearch: filters.textSearch,
        notesSearch: filters.notesSearch,
        additionalInformationSearch: filters.additionalInformationSearch,
        propertyType: filters.propertyType.map(property => property.value) || [],
        dkSellerRating: filters.dkSellerRatingFilterValue.map(dkSellerRating => dkSellerRating.value),
        features: filters.features,
        analyzed: filters.analyzedFilterValue,
        currentPageValue: filters.currentPageValue,
        itemsCountPerPage: filters.itemsCountPerPage,
        lastStatusChangedBy: filters.searcher.value,
        startDate: filters.createdStartDate,
        endDate: filters.createdEndDate,
        searcherStartDate: filters.searcherStartDate,
        searcherEndDate: filters.searcherEndDate,
        isDatePresentedToClient: filters.isDatePresentedToClient,
        sortKey: filters.sortKey.value,
        sortDkSellerRating: filters.sortDkSellerRating.value,
        sortDateFound: filters.sortDateFound.value,
        sortEstimatedMonthlyVisits: filters.sortEstimatedMonthlyVisits.value,
        sortDomain: filters.sortDomain.value,
        sortNumberOfFeatures: filters.sortNumberOfFeatures.value,
        sortPropertyType: filters.sortPropertyType.value,
    }

    if (filters.remoteStatusValue !== -1) {
        payload.status = filters.remoteStatusValue.value
    }

    if (filters.isDatePresentedToClient === true) {
        payload.startDate = filters.datePresentedToClientStart
        payload.endDate = filters.datePresentedToClientEnd
    }

    return payload
}
