import React, {Component} from 'react';

class Menu extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectAll: false
        }
    }

    isSelectModelDropdownDisabled = () => {

        if (this.props.actionValue === 'classify' && this.props.selectedImages.length > 0) return false

        return true
    }

    isSelectStatusDropdownDisabled = () => {

        if (this.props.actionValue === 'mark' && this.props.selectedImages.length > 0) return false

        return true
    }

    handleSelectAll = () => {

        if (this.state.selectAll === false) {

            this.setState({
                selectAll: true
            })
            this.props.images.forEach(image => {
                this.props.doSelect(image)
            })
            return
        }

        this.setState({
            selectAll: false
        })

        this.props.setSelectedImages()
    };

    handleMark = () => {
        this.props.setConfirmationModalModalStatus(true);
        // this.props.doMark(this.props.selectedImages, this.props.statusValue, this.props.images, this.props.searchTermId, this.props.projectId)
    };

    handleClassify = () => {
        this.props.doClassify(this.props.selectedImages, this.props.selectedMenuModelValue, this.props.selectedTab)
    };

    handleAnalyze = () => {
        this.props.doAnalyze(this.props.selectedImages, this.props.selectedTab)
    };

    handleReport = () => {
        this.props.doReport(this.props.selectedImages, this.props.projectId)
    };

    handleMenuSubmit = (e) => {
        e.preventDefault()

        switch (this.props.actionValue) {
            case 'mark':
                this.handleMark();
                break;
            case 'classify':
                this.handleClassify();
                break;
            case 'analyze':
                this.handleAnalyze();
                break;
            case 'report':
                this.handleReport();
                break;
            default:
                return
        }
    }

    getStatusOptions = () => {
        return this.props.statusOptions
    }

    render() {
        return (
          <React.Fragment>
              <div className="row">
                  <div className="col-1">
                      <div className="form-group">
                          <div className="custom-control custom-checkbox my-1 mr-sm-2">
                              <input onChange={this.handleSelectAll} type="checkbox"
                                     className="custom-control-input" id="customControlInline"
                                     checked={this.state.selectAll}
                              />
                              <label className="custom-control-label" htmlFor="customControlInline">Select all</label>
                          </div>
                      </div>
                  </div>

                  <div className="col">
                      <div className="form-group">
                          <div className="input-group mr-sm-2">
                              <div className="input-group-prepend">
                                  <span className="input-group-text" id="">Action</span>
                              </div>
                              <select onChange={(e) => {
                                  this.props.setMenuAction(e.target.value)
                              }} className="form-control form-control-user custom-control custom-select"
                                      value={this.props.actionValue}>
                                  {
                                      this.props.actionOptions.map((action) => {
                                          return <option key={action.value} value={action.value}>{action.label}</option>
                                      })
                                  }
                              </select>
                          </div>
                      </div>
                  </div>

                  {this.props.searchTermId === null &&
                  <div className="col">
                      <div className="form-group">
                          <div className="input-group mr-sm-2">
                              <div className="input-group-prepend">
                                  <span className="input-group-text" id="">Model</span>
                              </div>
                              <select onChange={(e) => {
                                  this.props.setSelectedMenuModel(e.target.value)
                              }} className="form-control form-control-user custom-control custom-select"
                                      value={this.props.selectedMenuModelValue}
                                      disabled={this.isSelectModelDropdownDisabled()}
                              >
                                  {
                                      this.props.modelList.map((model) => {
                                          return <option key={model.id} value={model.id}>{model.display_name}</option>
                                      })
                                  }
                              </select>
                          </div>
                      </div>
                  </div>
                  }

                  <div className="col">
                      <div className="form-group">
                          <div className="input-group mr-sm-2">
                              <div className="input-group-prepend">
                                  <span className="input-group-text" id="">Status</span>
                              </div>
                              <select onChange={(e) => {
                                  this.props.setSelectedStatus(e.target.value)
                              }} className="form-control form-control-user custom-control custom-select"
                                      value={this.props.statusValue}
                                      disabled={this.isSelectStatusDropdownDisabled()}
                              >
                                  {
                                      this.getStatusOptions().map((status) => {
                                          return <option key={status.value} value={status.value}>{status.label}</option>
                                      })
                                  }
                              </select>
                          </div>
                      </div>
                  </div>
                  <div className="col-1">
                      <div className="form-group ml-sm-2">
                          <button onClick={(e) => {
                              this.handleMenuSubmit(e)
                          }} className="btn btn-primary">Submit
                          </button>
                      </div>
                  </div>
                  <div className="col-1">
                      <input onClick={this.props.openFiltersPanel} type="button" className="btn btn-primary" value="Filters" />
                  </div>
                  <div className="col text-right">
                      <span className="mr-1">{this.props.selectedImages.length}</span>
                      <button onClick={() => this.props.setSelectedImages()} className="btn btn-outline-warning btn-sm ">Clear selected</button>
                  </div>
              </div>
          </React.Fragment>
        );
    }
}

export default Menu;