import React from "react";
import {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import hasAccessLevel from "../utils/auth/hasAccessLevel";
import {ROLES} from "../utils/enums/Roles";
import getUserRoles from "../utils/auth/getUserRoles";
import {compose} from "redux";
import {connect} from "react-redux";
import {getMonitoringData} from "../actions/monitoring/monitoringActions";

class TopBarAdminCogMenu extends Component
{
    componentDidMount() {
        this.props.getMonitoringData()
        this.interval = setInterval(() => this.props.getMonitoringData(), 600000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {

        return (
            <ul className="navbar-nav">
                <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="appAdminDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className={(() => this.props.system_healthy ? 'fas fa-cog fa-sm fa-fw m-auto text-gray-400' : 'fas fa-cog fa-sm fa-fw m-auto text-danger')()} to={"/monitoring"}></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="appAdminDropdown">
                        {hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) &&<Link className="dropdown-item" to={"/searcher-report-data"}>Searcher Report Data</Link>}
                        {hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) &&<Link className="dropdown-item" to={"/users"}>Users Management</Link>}
                        {hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) &&<Link className="dropdown-item" to={"/domains-meta"}>Domain Meta</Link>}
                        <Link className="dropdown-item" to={"/import-projects"}>Import Projects</Link>
                        <Link className={(() => this.props.system_healthy ? 'dropdown-item' : 'dropdown-item text-danger')()} to={"/monitoring"}>Monitoring</Link>
                    </div>
                </li>
            </ul>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        system_healthy: state.monitoring.system_healthy,
    }
}
const mapActionsToProps = (dispatch) => {
    return {
        getMonitoringData: () => {
            dispatch(getMonitoringData())
        }
    }
}

export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(TopBarAdminCogMenu)