export const SET_ACTION = 'menu:setAction';

export function setMenuAction(type) {
    return {
        type: SET_ACTION,
        payload: {
            type
        }
    }
}
