import { FETCH_MODELS, SELECT_MODEL, SELECT_MENU_MODEL } from '../actions/models/modelsAction';

const initialState = {
    selectedValue: '',
    selectedMenuModelValue: '',
    list: []
};

export default function modelsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MODELS:
            return {
                ...state,
                list: action.payload.list
            };
        case SELECT_MODEL:
            return {
                ...state,
                selectedValue: action.payload.selectedValue
            }
        case SELECT_MENU_MODEL:
            return {
                ...state,
                selectedMenuModelValue: action.payload.selectedMenuModelValue
            }
        default:
            return state
    }
}
