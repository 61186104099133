import React, { Component } from "react";

class AnalysisSelectInput extends Component {

    render() {
        return (
          <div className="form-group row">
              <label className="col-sm-3 col-form-label" htmlFor={this.props.id}>{this.props.label}</label>
              <div className="col-sm-9">
                  <select onChange={(e) => this.props.handleOnChange(e.target.name, e.target.value)} className="custom-select mr-sm-2" id={this.props.id} value={this.props.value} name={this.props.id}>
                      {this.props.options.map((option) => {
                          return <option key={option.value} value={option.value}>{option.label}</option>
                      })}
                  </select>
              </div>
          </div>
        )
    }
}

export default AnalysisSelectInput;
