import React, {Component} from "react";

class WordCloud extends Component {
    render() {
        return (
          <div className="card shadow">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Word cloud</h6>
              </div>
              <div className="card-body">
                  <div className="text-center">
                      <img className="img-fluid" src={this.props.img} alt="wordcloud"/>
                  </div>
              </div>
          </div>
        )
    }
}

export default WordCloud;