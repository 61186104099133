import React, { Component } from 'react';

class ProjectListItem extends Component {

    handleSelect = (e, projectId) => {
        e.preventDefault()

        this.props.setSelectedProject(this.props.project);
        this.props.fetchProjectStats(projectId)
        this.props.setProjectTemplate(projectId)
        localStorage.setItem('project', JSON.stringify(this.props.project))
        this.props.router.push(`/project/${projectId}/dashboard`)
    }

    render() {
        return (
          <a onClick={(e) => this.handleSelect(e, this.props.project.id)}>{this.props.project.display_name}</a>
        )
    }
}

export default ProjectListItem