import * as project from "../actions/clientAndProjectList/clientAndProjectListAction";

const initialState = {
    fetching: false,
    fetchingStats: false,
    clientAndProjectList: [],
    miscellaneousProjectList: [],
    projectListError: [],
    openedClientId: null,
    selectedProject: JSON.parse(localStorage.getItem('project')) || null,
    projectStats: JSON.parse(localStorage.getItem('project_stats')) || null,
    projectTemplate: JSON.parse(localStorage.getItem('project_template')) || null,
}

export default function clientAndProjectListReducer(state = initialState, action) {
    switch (action.type) {
        case project.OPENED_CLIENT_PROJECT:
            return {
                ...state,
                openedClientId: action.payload.openedClientId
            }
        case project.FETCHING_CLIENT_AND_PROJECT_LIST:
            return {
                ...state,
                fetching: action.payload.fetching
            };
        case project.FETCHED_CLIENT_AND_PROJECT_LIST:
            let clientAndProjectList = action.payload.clientAndProjectList.filter((element, index, array) => {
                return element.id !== null
            })
            let miscellaneousProjectList = action.payload.clientAndProjectList.filter((element, index, array) => {
                return element.id === null
            })
            miscellaneousProjectList = miscellaneousProjectList.length > 0 ? miscellaneousProjectList[0].projects : []

            return {
                ...state,
                clientAndProjectList: clientAndProjectList,
                miscellaneousProjectList: miscellaneousProjectList
            }
        case project.SET_PROJECT_STATS:
            return {
                ...state,
                projectStats: action.payload.projectStats
            }
        case project.SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProject: action.payload.selectedProject
            }
        case project.SET_FETCHING_STATS:
            return {
                ...state,
                fetchingStats: action.payload.status
            }
        default:
            return state
    }
}
