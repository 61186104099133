import prepHeaders from "../../utils/prepHeaders"
import axios from "axios";

export const SET_NOTES = "notes:set";

export const setNotes = (value) => {
    return {
        type: SET_NOTES,
        payload: {
            notes: value
        }
    }
}

export const saveNotes = (searchResultId, noteValue) => {

    return dispatch => {

        let payload = {
            id: searchResultId,
            notes: noteValue
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/search-result/notes`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(() => {
        }).catch((error) => {
            console.log(error)
        })
    }
}


