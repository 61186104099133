import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/js/bootstrap";
import "./assets/sass/main.scss";
import AppRouter from './components/AppRouter';
import * as serviceWorker from './serviceWorker';
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./utils/sso/authConfig";
import axios from "axios";

import {MsalProvider} from "@azure/msal-react";

// Redux imports
import {combineReducers, createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import authenticationReducer from './reducers/authenticationReducer';
// import projectListReducer from './reducers/projectListReducer';
import modelsReducer from './reducers/modelsReducer';
import queryReducer from './reducers/queryReducer';
import imagesReducer from './reducers/imagesReducer';
import filterReducer from "./reducers/filterReducer";
import sidepanelReducer from "./reducers/sidepanelReducer";
import statusReducer from "./reducers/statusReducer";
import shortlistReducer from "./reducers/shortlistReducer";
import menuReducer from "./reducers/menuReducer";
import {LOGOUT} from "./actions/authentication/authenticationAction";
import cleanupLocalStorage from "./utils/cleanupLocalStorage";
import searchTermReducer from "./reducers/searchTermReducer";
import modalReducer from './reducers/modalReducer';
import reportsReducer from './reducers/reportsReducer';
import ffReportsReducer from "./reducers/ffReportsReducer";
import singleViewMenuReducer from './reducers/singleViewMenuReducer';
import singleViewShortlistReducer from "./reducers/singleViewShortlistReducer";
import summaryReducer from "./reducers/summaryReducer";
import domainMetaReducer from "./reducers/domainMetaReducer";
import searcherReportReducer from "./reducers/searcherReportReducer";
import userManagementReducer from "./reducers/userManagementReducer";
import importProjectReducer from  "./reducers/importProjectReducer"
import clientAndProjectListReducer from "./reducers/clientAndProjectListReducer";
import monitoringReducer from "./reducers/monitoringReducer";
import imagesDownloadReducer from "./reducers/imagesDownloadReducer";

const allReducers = combineReducers({
    authentication: authenticationReducer,
    // projectList: projectListReducer,
    clientAndProjectList: clientAndProjectListReducer,
    models: modelsReducer,
    query: queryReducer,
    images: imagesReducer,
    filter: filterReducer,
    sidepanel: sidepanelReducer,
    status: statusReducer,
    shortlist: shortlistReducer,
    menu: menuReducer,
    searchTerm: searchTermReducer,
    modal: modalReducer,
    reports: reportsReducer,
    ffReports: ffReportsReducer,
    singleViewMenu: singleViewMenuReducer,
    singleViewShortlist: singleViewShortlistReducer,
    summary: summaryReducer,
    domainMeta: domainMetaReducer,
    imagesDownload: imagesDownloadReducer,
    searcherReport: searcherReportReducer,
    userManagement: userManagementReducer,
    importProjects: importProjectReducer,
    monitoring: monitoringReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
        cleanupLocalStorage()
    }

    return allReducers(state, action)
}

const allStoreEnhancers = compose(
  applyMiddleware(thunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const store = createStore(
  rootReducer,
  {},
  allStoreEnhancers
);

const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    console.log(error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    switch (error.response.status) {
        case 401:
        case 422:
            window.location.replace(`${window.location.protocol.concat('//')}${window.location.host}/login`)
            break;
        default:
            return Promise.reject(error);
    }
    return Promise.reject(error);
});

ReactDOM.render(
  <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <AppRouter/>
      </MsalProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
