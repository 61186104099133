import prepHeaders from "../../utils/prepHeaders";
import axios from "axios";

export const FETCHING_CLIENT_AND_PROJECT_LIST = 'clientAndProjectList:fetching';
export const FETCHED_CLIENT_AND_PROJECT_LIST = 'clientAndProjectList:fetched';
export const OPENED_CLIENT_PROJECT = 'clientAndProjectList:openedClientProject';
export const SET_FETCHING_STATS = 'clientAndProjectList:setFetchingStats';
export const SET_PROJECT_STATS = 'clientAndProjectList:setProjectStats';
export const FETCHING = 'clientAndProjectList:fetching';
export const FETCHED = 'clientAndProjectList:fetched';
export const PROJECT_LIST_FETCH_ERROR = 'clientAndProjectList:error';
export const SET_SELECTED_PROJECT = 'clientAndProjectList:setSelected';
export const SET_PROJECT_TEMPLATE = 'clientAndProjectList:setTemplate';

export const setFetchingStats = status => {
    return {
        type: SET_FETCHING_STATS,
        payload: {
            status
        }
    }
}

export const setProjectStats = projectStats => {
    return {
        type: SET_PROJECT_STATS,
        payload: {
            projectStats
        }
    }
}

export function clientAndProjectListIsBeingFetched(status) {
    return {
        type: FETCHING_CLIENT_AND_PROJECT_LIST,
        payload: {
            fetching: status,
        }
    }
}

export function clientAndProjectListFetched(clientAndProjectList) {
    return {
        type: FETCHED_CLIENT_AND_PROJECT_LIST,
        payload: {
            clientAndProjectList: clientAndProjectList,
        }
    }
}

export function openClientProjects(clientId) {
    return {
        type: OPENED_CLIENT_PROJECT,
        payload: {
            openedClientId: clientId
        }
    }
}

export function projectListIsBeingFetched(status) {
    return {
        type: FETCHING,
        payload: {
            fetching: status,
        }
    }
}

export function projectListFetched(projectList) {
    return {
        type: FETCHED,
        payload: {
            list: projectList,
        }
    }
}

export function projectListError(message) {
    return {
        type: PROJECT_LIST_FETCH_ERROR,
        payload: {
            projectListError: message,
        }
    }
}

export  const setSelectedProject = selectedProject => {
    return {
        type: SET_SELECTED_PROJECT,
        payload: {
            selectedProject
        }
    }
}

export function setProjectTemplate(templateData) {
    return {
        type: SET_PROJECT_TEMPLATE,
        payload: {
            templateData
        }
    }
}

export const fetchProjectTemplate = (projectId) => {
    return dispatch => {

        let endpoint = `/project/template/${projectId}`;

        axios({
            url: process.env.REACT_APP_API_BASE_URL + endpoint,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setProjectTemplate(response.data))
            localStorage.setItem('project_template', JSON.stringify(response.data))
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const fetchProjectList = () => {
    return dispatch => {

        dispatch(projectListIsBeingFetched(true));

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/list-projects",
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(projectListFetched(response.data));
            dispatch(projectListIsBeingFetched(false));
        }).catch((error) => {
            dispatch(projectListIsBeingFetched(false));
            console.log(error)
        })
    }
}

export const fetchClientAndProjectList = () => {
    return dispatch => {
        dispatch(clientAndProjectListIsBeingFetched(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/list-org-projects",
            withCredentials: true,
            method: 'GET',
            headers: prepHeaders(),
        }).then(response => {
            dispatch(clientAndProjectListFetched(response.data));
            dispatch(clientAndProjectListIsBeingFetched(false));
        }).catch((error) => {
            dispatch(clientAndProjectListIsBeingFetched(false));
            console.log(error)
        })
    }
}

export const fetchProjectStats = projectId => {
    return dispatch => {
        dispatch(setFetchingStats(true))

        let endpoint = `/project/stats/${projectId}`;

        axios({
            url: process.env.REACT_APP_API_BASE_URL + endpoint,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setProjectStats(response.data))
            localStorage.setItem('project_stats', JSON.stringify(response.data));
            dispatch(setFetchingStats(false))
        }).catch((error) => {
            dispatch(setFetchingStats(false))
            console.log(error)
        })
    }
};
