export const SET_CONFIRMATION_MODAL_STATUS = 'shortlist:setConfirmationModalStatus';
export const SET_CONFIRMATION_MODAL_STATUS_IN_SEARCH_VIEW = 'shortlist:setConfirmationModalStatusInSearchView';

export function setShortlistConfirmationModalStatus(status) {
    return {
        type: SET_CONFIRMATION_MODAL_STATUS,
        payload: {
            status
        }
    }
}

export function setConfirmationModalStatusInSearchView(status) {
    return {
        type: SET_CONFIRMATION_MODAL_STATUS_IN_SEARCH_VIEW,
        payload: {
            status
        }
    }
}
