import { setSelectedTab, TAB_FILTER_ALL } from "../filter/filterAction";
import prepHeaders from '../../utils/prepHeaders';
import axios from "axios";

export const FETCHING_IMAGES = 'images:fetching';
export const SET_IMAGES = 'images:set';
export const IS_BEING_CLASSIFIED_SHORTLIST = 'images:isBeingClassifiedShortlist';
export const CLASSIFIED_SHORTLIST = 'images:classifiedShortlist';
export const ANALYZED_SHORTLIST = 'images:analyzedShortlist';
export const SET_IMAGE_FOR_SIDE_PANEL = 'images:setForSidePanel';
export const UNSET_IMAGE_FOR_SIDE_PANEL = 'images:unsetForSidePanel';
export const SELECTED = 'images:selected';
export const DESELECT = 'images:deselected';
export const SET_SELECTED_IMAGES = 'images:setSelected';
export const SELECTED_SHORTLIST = 'images:selectedShortlist';
export const DESELECT_SHORTLIST = 'images:deselectedShortlist';
export const SET_SELECTED_FOR_SHORTLIST = 'images:setSelectedForShortlist';
export const SET_IMAGE_FOR_SIDE_PANEL_IN_SHORTLIST = 'images:setForSidePanelInShortlist';
export const UNSET_IMAGE_FOR_SIDE_PANEL_IN_SHORTLIST = 'images:unsetForSidePanelInShortlist';

export function setFetchingImages(status) {
    return {
        type: FETCHING_IMAGES,
        payload: {
            fetching: status
        }
    }
}

export function setImages(imageData) {
    return {
        type: SET_IMAGES,
        payload: {
            images: imageData
        }
    }
}

export function isBeingClassifiedShortlist(searchHash, status) {
    return {
        type: IS_BEING_CLASSIFIED_SHORTLIST,
        payload: {
            searchHash,
            status,
        }
    }
}

export function classifiedShortlist(searchResult, searchHash) {
    return {
        type: CLASSIFIED_SHORTLIST,
        payload: {
            searchResult,
            searchHash
        }
    }
}

export function analyzedShortlist(searchResult, searchHash) {
    return {
        type: ANALYZED_SHORTLIST,
        payload: {
            searchResult,
            searchHash
        }
    }
}


export function setImageForSidePanel(image) {
    return {
        type: SET_IMAGE_FOR_SIDE_PANEL,
        payload: {
            image
        }
    }
}

export function unsetImageForSidePanel() {
    return {
        type: UNSET_IMAGE_FOR_SIDE_PANEL,
        payload: {
            image: null
        }
    }
}

export function setImageForSidePanelInShortlist(image) {
    return {
        type: SET_IMAGE_FOR_SIDE_PANEL_IN_SHORTLIST,
        payload: {
            image
        }
    }
}

export function unsetImageForSidePanelInShortlist() {
    return {
        type: UNSET_IMAGE_FOR_SIDE_PANEL_IN_SHORTLIST,
        payload: {
            image: null
        }
    }
}

export function doSelect(image) {
    return {
        type: SELECTED,
        payload: {
            image
        }
    }
}

export function doDeselect(index) {
    return {
        type: DESELECT,
        payload: {
            index
        }
    }
}

export function setSelectedImages(images = []) {
    return {
        type: SET_SELECTED_IMAGES,
        payload: {
            images
        }
    }
}

export function doSelectForShortlist(shortlistImage) {
    return {
        type: SELECTED_SHORTLIST,
        payload: {
            shortlistImage
        }
    }
}

export function doDeselectForShortlist(shortlistIndex) {
    return {
        type: DESELECT_SHORTLIST,
        payload: {
            shortlistIndex
        }
    }
}

export function setSelectedForShortlist(images = []) {
    return {
        type: SET_SELECTED_FOR_SHORTLIST,
        payload: {
            images
        }
    }
}

export function doFetchSearchViewImages(searchTermId) {
    return dispatch => {

        let url = `/search/${searchTermId}`;

        dispatch(setFetchingImages(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + url,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {

            let imageData = response.data.result.map((image, index) => {
                return {
                    search_result: {...image},
                    temp_id: index,
                    isBeingClassified: false,
                    isBeingAnalyzed: false,
                }
            });

            dispatch(setImages(imageData));
            dispatch(setFetchingImages(false));
            dispatch(setSelectedTab(TAB_FILTER_ALL));

        }).catch(error => {
            dispatch(setFetchingImages(false));
            console.log(error)
        })
    }
}
