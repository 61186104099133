import cleanupLocalStorage from '../../utils/cleanupLocalStorage';
import axios from "axios";

export const AUTH_DATA_IS_BEING_FETCHED = 'auth:dataIsBeingFetched';
export const AUTH_DATA_IS_FETCHED = 'auth:dataIsFetched';
export const AUTH_DATA_ERROR = 'auth:dataError';
export const LOGOUT = 'auth:logout';
export const SESSION_EXPIRED = 'auth:sessionExpired';

export function authDataIsBeingFetched(bool) {
    return {
        type: AUTH_DATA_IS_BEING_FETCHED,
        payload: {
            isBeingFetched: bool,
        }
    }
}

export function authDataIsFetched(email) {
    return {
        type: AUTH_DATA_IS_FETCHED,
        payload: {
            email
        }
    }
}

export function authDataError(error) {
    return {
        type: AUTH_DATA_ERROR,
        payload: {
            authError: error,
        }
    }
}

export function doLogout() {
    return {
        type: LOGOUT,
        payload: {
            email: null,
        }
    }
}

export function sessionExpired(sessionExpiredStatus) {
    return {
        type: SESSION_EXPIRED,
        payload: {
            sessionExpiredStatus
        }
    }
}

export const fetchAuthDataSso = (idToken, history) => {

    return dispatch => {

        let payload = {
            idToken
        }

        dispatch(authDataIsBeingFetched(true));

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/authenticate",
            withCredentials: true,
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            data: payload
        }).then(response => {
            dispatch(authDataIsFetched(response.data.data.email));
            dispatch(authDataError(null));
            dispatch(authDataIsBeingFetched(false));
            dispatch(sessionExpired(false));

            localStorage.setItem('roles', JSON.stringify(response.data.data.user.roles))
            localStorage.setItem('email', response.data.data.email);
            history.push("/")

        }).catch((error) => {
            dispatch(authDataIsBeingFetched(false));
            let message;
            switch (error.message) {
                case "404":
                    message = 'Invalid credentials';
                    dispatch(authDataError(message));
                    break
                case "400":
                    message = 'Email and password fields must not be empty and be valid';
                    dispatch(authDataError(message));
                    break;
                default:
                    message = 'Something went wrong';
                    dispatch(authDataError(message));
            }
        })
    }
};

export const fetchAuthData = (email, password, history) => {

    return dispatch => {

        let payload = {
            email,
            password
        }

        dispatch(authDataIsBeingFetched(true));

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/login",
            withCredentials: true,
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            data: payload
        }).then(response => {
            dispatch(authDataIsFetched(response.data.data.email));
            dispatch(authDataError(null));
            dispatch(authDataIsBeingFetched(false));
            dispatch(sessionExpired(false));

            localStorage.setItem('roles', JSON.stringify(response.data.data.user.roles))
            localStorage.setItem('email', response.data.data.email);

            history.push("/")

        }).catch((error) => {
            dispatch(authDataIsBeingFetched(false));
            let message;
            switch (error.message) {
                case "404":
                    message = 'Invalid credentials';
                    dispatch(authDataError(message));
                    break
                case "400":
                    message = 'Email and password fields must not be empty and be valid';
                    dispatch(authDataError(message));
                    break;
                default:
                    message = 'Something went wrong';
                    dispatch(authDataError(message));
            }
        })
    }
};

export const loggingOut = (history) => {
    return dispatch => {
        cleanupLocalStorage();
        dispatch(doLogout());
        history.push('/login');
    }
};
