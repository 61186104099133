import moment from 'moment';

export default function determineAnalysisTemplateAndPopulateStateWithData(image) {
    switch (image.search_provider) {
        // Website
        case "google":
            return {
                url: image.url,
                property_type: image.property_type || '',
                date_found: moment(image.date_found).format('YYYY-MM-DD') || '',
                date_presented_to_client: moment(image.date_presented_to_client).format('YYYY-MM-DD') || '',
                dupe_value: image.dupe_value || '',
                dupe_value_report: image.model_score_report === null ? 0 : image.model_score_report || '',
                seller_location: image.seller_location || '',
                domain: image.domain || '',
                action_recomended: image.action_recomended === null ? 0 : image.action_recomended,
                cause_of_action: image.cause_of_action || '',
                additional_information: image.additional_information || '',
                currency_and_price: image.currency_and_price || '',
                sterling_price: image.sterling_price === null ? 0 : image.sterling_price,
                dk_seller_rating: image.dk_seller_rating === null ? 3 : image.dk_seller_rating,
                product_description: image.product_description || '',
                enforcement_status: image.enforcement_status === null ? 3 : image.enforcement_status,
                takedown_status: image.takedown_status === null ? 1 : image.takedown_status,
                test_purchase: image.test_purchase === null ? 0 : image.test_purchase,
                image_source_capture: image.image_source_capture || '',
                estimated_monthly_visits: image.estimated_monthly_visits || '',
                global_domain_rank: image.global_domain_rank || '',
                domain_authority: image.domain_authority || '',
                page_authority: image.page_authority || '',
                audience: image.audience || '',
                ships: image.shipping || '', // countries served
                notes: image.notes || ''
            }
        // Marketplace
        case "ebay_com":
        case "ebay":
            return {
                ebay_item_id: image.ebay_item_id,
                url: image.url,
                property_type: image.property_type || '',
                date_found: moment(image.date_found).format('YYYY-MM-DD') || '',
                date_presented_to_client: moment(image.date_presented_to_client).format('YYYY-MM-DD') || '',
                dupe_value: image.dupe_value || '',
                dupe_value_report: image.model_score_report === null ? 0 : image.model_score_report || '',
                seller_location: image.seller_location || '',
                action_recomended: image.action_recomended === null ? 0 : image.action_recomended,
                cause_of_action: image.cause_of_action || '',
                additional_information: image.additional_information || '',
                currency_and_price: image.currency_and_price || '',
                sterling_price: image.sterling_price === null ? 0 : image.sterling_price,
                dk_seller_rating: image.dk_seller_rating === null ? 3 : image.dk_seller_rating,
                product_description: image.product_description || '',
                enforcement_status: image.enforcement_status === null ? 3 : image.enforcement_status,
                takedown_status: image.takedown_status === null ? 1 : image.takedown_status,
                test_purchase: image.test_purchase === null ? 0 : image.test_purchase,
                image_source_capture: image.image_source_capture || '',
                seller: image.seller || '',
                seller_rating: image.seller_rating || '',
                seller_url: image.seller_url || '',
                ebay_seller_feedback_score: image.ebay_seller_feedback_score || '',
                notes: image.notes || ''
            }
        case "amazon":
            return {
                url: image.url,
                property_type: image.property_type || '',
                date_found: moment(image.date_found).format('YYYY-MM-DD') || '',
                date_presented_to_client: moment(image.date_presented_to_client).format('YYYY-MM-DD') || '',
                dupe_value: image.dupe_value || '',
                dupe_value_report: image.model_score_report === null ? 0 : image.model_score_report || '',
                seller_location: image.seller_location || '',
                action_recomended: image.action_recomended === null ? 0 : image.action_recomended,
                cause_of_action: image.cause_of_action || '',
                additional_information: image.additional_information || '',
                currency_and_price: image.currency_and_price || '',
                sterling_price: image.sterling_price === null ? 0 : image.sterling_price,
                dk_seller_rating: image.dk_seller_rating === null ? 3 : image.dk_seller_rating,
                product_description: image.product_description || '',
                enforcement_status: image.enforcement_status === null ? 3 : image.enforcement_status,
                takedown_status: image.takedown_status === null ? 1 : image.takedown_status,
                test_purchase: image.test_purchase === null ? 0 : image.test_purchase,
                image_source_capture: image.image_source_capture || '',
                seller: image.seller || '',
                seller_rating: image.seller_rating || '',
                seller_url: image.seller_url || '',
                amazon_customer_reviews: image.amazon_customer_reviews || '',
                notes: image.notes || ''
            }
        // Social
        case "instagram":
            return {
                url: image.url,
                property_type: image.property_type || '',
                date_found: moment(image.date_found).format('YYYY-MM-DD') || '',
                date_presented_to_client: moment(image.date_presented_to_client).format('YYYY-MM-DD') || '',
                dupe_value: image.dupe_value || '',
                dupe_value_report: image.model_score_report === null ? 0 : image.model_score_report || '',
                seller_location: image.seller_location || '',
                action_recomended: image.action_recomended === null ? 0 : image.action_recomended,
                cause_of_action: image.cause_of_action || '',
                additional_information: image.additional_information || '',
                currency_and_price: image.currency_and_price || '',
                sterling_price: image.sterling_price === null ? 0 : image.sterling_price,
                dk_seller_rating: image.dk_seller_rating === null ? 3 : image.dk_seller_rating,
                product_description: image.product_description || '',
                enforcement_status: image.enforcement_status === null ? 3 : image.enforcement_status,
                takedown_status: image.takedown_status === null ? 1 : image.takedown_status,
                test_purchase: image.test_purchase === null ? 0 : image.test_purchase,
                image_source_capture: image.image_source_capture || '',
                seller: image.seller || '',
                seller_url: image.seller_url || '',
                instagram_posts: image.instagram_posts || '',
                instagram_likes: image.instagram_likes || '',
                instagram_seller_method: image.instagram_seller_method || '',
                instagram_contact_details: image.instagram_contact_details || '',
                instagram_following: image.instagram_following || '',
                instagram_followers: image.instagram_followers || '',
                notes: image.notes || ''
            }
        default:
            return null;
    }
}