import React, {Component} from 'react';
import {connect} from 'react-redux';

import {compose} from "redux";
import {withRouter} from "react-router-dom";
import Topbar from "./Topbar";
import ProjectListItem from "./project/ProjectListItem";
import {
    fetchClientAndProjectList, fetchProjectStats, fetchProjectTemplate,
    openClientProjects, setProjectStats, setSelectedProject,
} from "../actions/clientAndProjectList/clientAndProjectListAction";

class ClientsAndProjectsList extends Component {

    componentDidMount() {
        this.props.setSelectedProject(null);
        this.props.setProjectStats(null);
        this.props.fetchClientAndProjectList()
    }

    render() {
        return (
            <>
                <Topbar />
                <div className="container project-list">

                    {this.props.clientAndProjectList.clientAndProjectList.length > 0 &&
                    <>
                        <h1 className="h3 mb-4 text-white-800">Clients</h1>
                        <div className="accordion">
                            {this.props.clientAndProjectList.clientAndProjectList.length > 0 &&
                            this.props.clientAndProjectList.clientAndProjectList.map(client => {
                                return <div className="card" key={client.id}>
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link" type="button" aria-expanded="true" aria-controls="collapseOne" onClick={() => this.props.handleOpenClientProjects(client.id)}>
                                                {client.name}
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className={client.id === this.props.clientAndProjectList.openedClientId ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            {client.projects.map(project => {
                                                return <ProjectListItem
                                                    setSelectedProject={this.props.setSelectedProject}
                                                    setProjectTemplate={this.props.fetchProjectTemplate}
                                                    fetchProjectStats={this.props.fetchProjectStats}
                                                    key={project.id}
                                                    project={project}
                                                    router={this.props.history}
                                                />
                                            })}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </>
                    }


                    <>
                        <h1 className="h3 mt-4 mb-0 text-white-800">Miscellaneous Projects</h1>
                        <hr/>
                    </>

                    {this.props.clientAndProjectList.miscellaneousProjectList.length > 0 &&
                    this.props.clientAndProjectList.miscellaneousProjectList.map(project => {
                        return <ProjectListItem
                            setSelectedProject={this.props.setSelectedProject}
                            setProjectTemplate={this.props.fetchProjectTemplate}
                            fetchProjectStats={this.props.fetchProjectStats}
                            key={project.id}
                            project={project}
                            router={this.props.history}
                        />
                    })}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        clientAndProjectList: state.clientAndProjectList
    }
};

const mapActionsToProps = (dispatch) => {
    return {
        fetchClientAndProjectList: () => {
            dispatch(fetchClientAndProjectList())
        },
        handleOpenClientProjects: (clientId) => {
            dispatch(openClientProjects(clientId))
        },
        setProjectStats: (stats) => {
            dispatch(setProjectStats(stats))
        },
        setSelectedProject: (project) => {
            dispatch(setSelectedProject(project))
        },
        fetchProjectTemplate: (projectId) => {
            dispatch(fetchProjectTemplate(projectId))
        },
        fetchProjectStats: projectId => {
            dispatch(fetchProjectStats(projectId))
        }
    }
}


export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(ClientsAndProjectsList)