import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'redux';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
          <Route {...rest} render={ (props) => {
              return <Component {...props} />
          }}
      />
    )
};

const mapStateToProps = state => {
    return state
};

export default compose(
  connect(mapStateToProps),
)(ProtectedRoute);
