import React, {Component} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";
import {ToastContainer} from 'react-toastify';

class AppRouter extends Component {
    render() {
        return (
          <>
              <ToastContainer/>
              <Router>
                  <App/>
              </Router>
          </>
        )
    }
}

export default AppRouter
