import prepHeaders from '../../utils/prepHeaders';
import prepQuery from '../../utils/query/prepQuery';
import {toggleSearchTermModal} from "../searchTerm/searchTermActions";
import axios from "axios";

export const FETCHING_QUERY_LIST = 'query:fetchingList';
export const SET_QUERY_LIST = 'query:setList';
export const PUSH_SINGLE_QUERY_TO_LIST = 'query:pushToList';
export const UPDATE_QUERY_IN_LIST = 'query:updateInList';

export const doQuery = (query, projectId) => {
    return dispatch => {

        let payload = {
            search: prepQuery(query),
            project: projectId,
            name: query.name,
            automated: query.automated === false ? 0 : 1,
            score_threshold: query.scoreThreshold,
            model_id: query.model_id,
            per_feature_score_threshold: query.perFeatureScoreThreshold,
            feature_score_thresholds: query.featureScoreThresholds
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(toggleSearchTermModal(false));
            dispatch(pushSingleQueryToList(response.data))
        }).catch(error => {
            dispatch(toggleSearchTermModal(false));
            fetchQueryList(projectId)
            console.log(error)
        })
    }
}

export const doQueryEdit = (query, searchTermId) => {
    return dispatch => {

        let payload = {
            search: prepQuery(query),
            search_id: searchTermId,
            name: query.name,
            automated: query.automated === false ? 0 : 1,
            score_threshold: query.scoreThreshold,
            model_id: query.model_id,
            per_feature_score_threshold: query.perFeatureScoreThreshold,
            feature_score_thresholds: query.featureScoreThresholds
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search/update",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(toggleSearchTermModal(false));
            dispatch(updateQueryInList(response.data))
        }).catch(error => {
            console.log(error)
        })
    }
}

export const doSearchTermIsAutomated = (searchTermId, searchTermIsAutomatedStatus) => {
    return dispatch => {

        let payload = {
            search_term_id: searchTermId,
            automated: searchTermIsAutomatedStatus === true ? 1 : 0
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search/update/automated",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(updateQueryInList(response.data))
        }).catch(error => {
            let message;
            switch (error.message) {
                case "401":
                    message = 'Your session has expired. Please login to resume.'
                    break
                default:
                    message = 'Something went wrong'

            }
        })
    }
}

export function rerunQuery(searchTermId) {
    return dispatch => {

        let payload = {
            search_id: searchTermId,
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search/rerun",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(() => {
        }).catch(error => {
            console.log(error)
        })
    }
}

export function fetchingQueryList(status) {
    return {
        type: FETCHING_QUERY_LIST,
        payload: {
            status
        }
    }
}


export function setQueryList(list) {
    return {
        type: SET_QUERY_LIST,
        payload: {
            list
        }
    }
}

export function pushSingleQueryToList(newQuery) {
    return {
        type: PUSH_SINGLE_QUERY_TO_LIST,
        payload: {
            newQuery
        }
    }
}

export function updateQueryInList(newQuery) {
    return {
        type: UPDATE_QUERY_IN_LIST,
        payload: {
            newQuery
        }
    }
}

export const fetchQueryList = (projectId) => {
    return dispatch => {

        dispatch(fetchingQueryList(true));

        let payload = {
            project: projectId
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search/all",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(setQueryList(response.data))
            dispatch(fetchingQueryList(false));
        }).catch(error => {
            console.log(error)
        });
    }
};
