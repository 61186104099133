import {SET_FETCHING_USERS_STATUS, SET_USERS_LIST, SET_USER_EDIT_DATA} from "../actions/userManagement/userManagementAction";

const initialState = {
    fetchingUsers: false,
    users: [],
    userEdit: null,
}

export default function userManagementReducer(state = initialState, action)
{
    switch (action.type) {
        case SET_FETCHING_USERS_STATUS:
            return {
                ...state,
                fetchingUsers: action.payload.status
            };
        case SET_USERS_LIST:
            return {
                ...state,
                users: action.payload
            }
        case SET_USER_EDIT_DATA:
            return {
                ...state,
                userEdit: action.payload
            }
        default: return state
    }
}