import React, {Component } from 'react';
import {compose} from "redux";
import {Redirect, withRouter} from "react-router-dom";
import Topbar from "../Topbar";
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import {setSearcherReportEndDate, setSearcherReportStartDate, setButtonDownloadingStatus} from "../../actions/searcherReport/searcherRreportAction";

import prepHeaders from "../../utils/prepHeaders";
import moment from "moment";
import download from "downloadjs";
import SuperAdminSidebar from "../Sidebar/SuperAdminSidebar";
import getUserRoles from "../../utils/auth/getUserRoles";
import hasAccessLevel from "../../utils/auth/hasAccessLevel";
import {ROLES} from "../../utils/enums/Roles";
import axios from "axios";


class SearcherReportData extends Component {

    handleSearcherReportDataSubmit = () => {

        let requestPayload = {
            startDate: moment(this.props.searcherReport.searcherReportStartDate).format('YYYY-MM-DD'),
            endDate: moment(this.props.searcherReport.searcherReportEndDate).format('YYYY-MM-DD')
        }

        this.props.setButtonDownloadingStatus(true)

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/system/searcher-stats-download",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: requestPayload,
            responseType: 'blob'
        }).then(response => {
            let fileName = `searcher_stats_${moment().format('YYYY-MM-DD_H:m')}.csv`
            download(response.data, fileName, "text/plain")
            this.props.setButtonDownloadingStatus(false)
        }).catch(error => {
            console.log(error)
            this.props.setButtonDownloadingStatus(false)
        })
    }

    render() {
        if (hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) === false) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <div id="page-top">
                <div id="wrapper">
                    <SuperAdminSidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs">
                            <Topbar withBrandingHomeButton={true} withoutProjectStats={true}/>

                            <div className="container">
                                <h1 className="h3 mb-0 text-white-800">Searcher Report Data</h1>
                                <hr/>
                                <div className="row">
                                    <div className="col-6">
                                        <label>Start</label>
                                        <DatePicker
                                            selected={this.props.searcherReport.searcherReportStartDate}
                                            onChange={date => this.props.setSearcherReportStartDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Select a from date"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label>End</label>
                                        <DatePicker
                                            selected={this.props.searcherReport.searcherReportEndDate}
                                            onChange={date => this.props.setSearcherReportEndDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Select a to date"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 mt-3 d-flex justify-content-end">
                                        <button onClick={() => this.handleSearcherReportDataSubmit()} className="btn btn-primary" disabled={!this.props.searcherReport.downloadButtonValidationState}>
                                            {this.props.searcherReport.downloadingStatus &&
                                                <i className="fa fa-spinner fa-spin mr-2"></i>
                                            }
                                            <span>Download</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        searcherReport: state.searcherReport,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSearcherReportStartDate: (startDate) => {
            dispatch(setSearcherReportStartDate(startDate))
        },
        setSearcherReportEndDate: (endDate) => {
            dispatch(setSearcherReportEndDate(endDate))
        },
        setButtonDownloadingStatus: (downloadingStatus) => {
            dispatch(setButtonDownloadingStatus(downloadingStatus))
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(SearcherReportData);