import prepHeaders from "../../utils/prepHeaders";
import axios from "axios";

export const SET_MONITORING_DATA = 'monitoring.setMonitoringData'

export function setMonitoringStatus(monitoringData) {
    return {
        type: SET_MONITORING_DATA,
        payload: {
            monitoringData
        }
    }
}

export const getMonitoringData = () => {
    return dispatch => {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/monitoring/status`,
            withCredentials: true,
            method: 'GET',
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setMonitoringStatus(response.data));
        }).catch((error) => {
            console.log(error)
        })
    }
}