import prepHeaders from '../../utils/prepHeaders';
import download from 'downloadjs';
import moment from 'moment';
import axios from "axios";

export const SET_FF_REPORTS_LIST = "ffReports:fetchList";
export const SET_FF_DOWNLOADING = "ffReports:downloading";
export const SET_FF_FETCHING_STATUS = "ffReports:fetchingStatus";
export const TOGGLE_FF_MODAL = "ffReports:toggleModal";
export const APPEND_TO_FF_REPORT_LIST = "ffReports:appendToList";

export function setFFReportsList(ffReportsList) {
    return {
        type: SET_FF_REPORTS_LIST,
        payload: {
            ffReportsList
        }
    }
}

export default function setDownloading(status) {
    return {
        type: SET_FF_DOWNLOADING,
        payload: {
            status
        }
    }
}

export function setFetchingStatus(status) {
    return {
        type: SET_FF_FETCHING_STATUS,
        payload: {
            status
        }
    }
}

export function toggleFFModal(status) {
    return {
        type: TOGGLE_FF_MODAL,
        payload: {
            status
        }
    }
}

export function appendToFFReportList(ffReport) {
    return {
        type: APPEND_TO_FF_REPORT_LIST,
        payload: {
            ffReport
        }
    }
}

export const downloadFFReport = payload => {

    return dispatch => {

        dispatch(setDownloading(true));

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/report/ff/download",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload,
            responseType: 'blob'
        }).then(response => {
            let fileName = `report_ff_${moment().format('YYYY-MM-DD_H:m')}.csv`;
            download(response.data, fileName, "text/plain");
            dispatch(setDownloading(false));
        }).catch(error => {
            dispatch(setDownloading(false));
            console.log(error)
        })
    }
}

export function generateFFReport(data, projectId) {
    return dispatch => {

        let payload = {
            name: data.name,
            status: data.category,
            project_id: projectId,
            date_interval_start: data.start_date,
            date_interval_end: data.end_date
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/report/ff/generate`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(appendToFFReportList(response.data))
        }).catch(error => {
            console.log(error)
        })
    }
}

export function doFetchFFReportsList(projectId) {
    return dispatch => {
        dispatch(setFetchingStatus(true))
        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/report/ff/${projectId}`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setFFReportsList(response.data))
            dispatch(setFetchingStatus(false))
        }).catch(error => {
            dispatch(setFetchingStatus(false))
            console.log(error)
        })
    }
}