import React from "react";
import { useMsal } from "@azure/msal-react";

export const SsoSignOutButton = (props) => {

    const { instance, accounts } = useMsal();

    const handleLogout = async () => {

        await props.handleLogout()
    }

    return (
          <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={() => handleLogout()}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
          </a>
    )
}