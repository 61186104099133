import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import DataTable, {memoize} from 'react-data-table-component';
import ListItemTitle from './ffReportsList/ListItemTitle';
import ProcessStatus from './ffReportsList/ProcessStatus';
import DownloadLink from './ffReportsList/DownloadLink';
import Status from './ffReportsList/Status';
import HeaderMenu from './ffReportsList/HeaderMenu';
import {doFetchFFReportsList, downloadFFReport} from "../../actions/ffReports/ffReportsAction";
import { isWithinInterval } from 'date-fns'

const columns = memoize((projectId, downloadFFReport) => [
    {
        name: 'Name',
        selector: 'name',
        cell: (row) => <ListItemTitle row={row}/>,
        maxWidth: '300px',
    },
    {
        name: 'Created',
        selector: 'created',
        cell: (row) => row.created,
    },
    {
        name: 'For category',
        selector: 'marked_sr_category',
        cell: (row) => <Status row={row}/>,
    },
    {
        name: 'Process status',
        selector: 'process_status',
        cell: (row) => <ProcessStatus row={row}/>,
        center: true,
        maxWidth: '150px',
    },
    {
        name: 'Download',
        selector: '',
        cell: (row) => <DownloadLink key={row.id} projectId={projectId} download={downloadFFReport} row={row}/>,
        center: true,
        maxWidth: '50px',
    },
]);

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filteredText: '',
            startDate: null,
            endDate: null
        }
    }

    componentDidMount() {
        this.props.doFetchFFReportsList(this.props.projectId)
        this.interval = setInterval(() => this.props.doFetchFFReportsList(this.props.projectId), 5000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleOnFilterTextChange = (value) => {
        this.setState({
            filteredText: value
        })
    };

    render() {
        let filtered = {
            items: [...this.props.ffReports.ffReportsList]
        }

        if (this.state.filteredText !== '') {
            filtered.items = filtered.items.filter(item => item.name && item.name.toLowerCase().includes(this.state.filteredText.toLowerCase()))
        }

        if (this.state.startDate !== null && this.state.endDate === null) {
            filtered.items = filtered.items.filter(item => new Date(item.created).getTime() >= new Date(this.state.startDate).getTime())
        }

        if (this.state.endDate !== null && this.state.start === null) {
            filtered.items = filtered.items.filter(item => new Date(item.created).getTime() <= new Date(this.state.endDate).setHours(23, 59, 59))
        }

        if (this.state.startDate !== null && this.state.endDate !== null) {
            filtered.items = filtered.items.filter(item => (new Date(item.created).getTime() >= new Date(this.state.startDate).getTime()) && (new Date(item.created).getTime() <= new Date(this.state.endDate).setHours(23, 59, 59)))
        }

        return (
          <Fragment>
              {this.props.ffReports.downloading &&
                  <div className="overlay text-center">
                          <i className="fas fa-circle-notch fa-spin fetching-spinner mt-5"></i>
                  </div>
              }
              <div className="card shadow">
                  <DataTable
                    columns={columns(this.props.projectId, this.props.downloadFFReport)}
                    data={filtered.items}
                    keyField={'id'}
                    pagination
                    paginationPerPage={30}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 75, 100]}
                    // defaultSortField={'created'}
                    defaultSortAsc={false}
                    noDataComponent={"No reports"}
                    subHeader
                    subHeaderComponent={
                        <HeaderMenu
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            handleStartDateChange={date => this.setState({startDate: date})}
                            handleEndDateChange={date => this.setState({endDate: date})}
                            value={this.state.filteredText}
                            handleOnFilterTextChange={this.handleOnFilterTextChange}
                            toggleModal={this.props.toggleModal}
                        />}
                  />
              </div>
          </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ffReports: state.ffReports,
    }
};

const mapActionToProps = (dispatch) => {
    return {
        doFetchFFReportsList: (projectId) => {dispatch(doFetchFFReportsList(projectId))},
        downloadFFReport: (payload) => {dispatch(downloadFFReport((payload)))}
    }
};

export default connect(mapStateToProps, mapActionToProps)(List);
