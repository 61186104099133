import prepHeaders from "../../utils/prepHeaders";
import { toast } from 'react-toastify';
import axios from "axios";

export const FETCHING = "domainMeta:fetching";
export const POSTING = "domianMeta:posting";
export const SET_DOMAIN_LIST = "domainMeta:setList";
export const SET_DOMAIN_DATA = "domainMeta:setDomainData";
export const SET_SELECTED_DOMAIN = "domain:setSelectedDomain";
export const UPDATE_CSV_BUTTON_STATUS = 'domain:updateCsvButtonStatus'

export const setFetching = (status) => {
    return {
        type: FETCHING,
        payload: {
            status
        }
    }
}

export const setDomainList = (list) => {
    return {
        type: SET_DOMAIN_LIST,
        payload: {
            list
        }
    }
}

export const setDomainData = (domainData) => {
    return {
        type: SET_DOMAIN_DATA,
        payload: {
            domainData
        }
    }
}

export const setSelectedDomain = (value) => {
    return {
        type: SET_SELECTED_DOMAIN,
        payload: {
            value
        }
    }
}

export const setPosting = (status) => {
    return {
        type: POSTING,
        payload: {
            status
        }
    }
}

export const updateCsvButtonStatus = (buttonStatus) => {
    return {
        type: UPDATE_CSV_BUTTON_STATUS,
        payload: {
            buttonStatus
        }
    }
}

export const fetchMetadataForDomain = (domainId) => {
    return dispatch => {

        dispatch(setFetching(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/domain-meta/${domainId}`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setFetching(false))
            dispatch(setDomainData(response.data))
        }).catch(error => {
            dispatch(setFetching(true))
            console.log(error)
        })
    }
}

export const fetchDomainList = () => {
    return dispatch => {

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/domain-meta/list",
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setDomainList(response.data.domain_metas))
        }).catch(error => {
            console.log(error)
        })
    }
}

export const saveDomainMeta = (domainId, updateData) => {

    let payload = {
        domain: updateData.domain,
        domain_only: updateData.domainOnly,
        region: updateData.region,
        countries: updateData.countries,
        property_type: updateData.propertyType.value,
        estimated_monthly_visits: updateData.emv,
        audience: updateData.audience
    }

    return dispatch => {

        dispatch(setPosting(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/domain-meta/${domainId}`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(() => {
            dispatch(setPosting(false))
            toast.success('Updated domain')
        }).catch(error => {
            toast.error('Failed to update. Something went wrong.')
            console.log(error)
            dispatch(setPosting(false))
        })
    }
}

export const addNewDomainMeta = (domainData) => {

    let payload = {
        domain: domainData.domain,
        domain_only: domainData.domainOnly,
        region: domainData.region,
        countries: domainData.countries,
        property_type: domainData.propertyType.value,
        estimated_monthly_visits: domainData.emv,
        audience: domainData.audience
    }

    return dispatch => {

        dispatch(setPosting(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/domain-meta`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(() => {
            dispatch(setPosting(false))
            toast.success('New domain added.')
        }).catch(error => {
            toast.error('Failed to add new domain. Something went wrong.')
            console.log(error)
            dispatch(setPosting(false))
        })
    }
}