import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getStatusFilterOptions} from "../../utils/status/statusFilterOptions";
import getAnalysisFilterOptions from "../../utils/filters/analysisFilterOptions";
import {
    // setProviderFilter,
    setStatusFilter,
    setAnalyzedFilter,
    setDkSellerRating,
    setFeatures,
    setHost,
    setPropertyTypeFilter,
    setTextSearch,
    setNotesSearch,
    setAdditionalInformationSearch,
    fetchSearchers,
    selectedSearcher,
    setSearcherStartDate, setSearcherEndDate,
    setCreatedStartDate, setCreatedEndDate,
    setSort
} from "../../actions/filter/filterAction";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
// import getDkSellerRatingFilterOptions from "../../utils/filters/dkSellerRatingFilterOptions";
// import getFeatureOptions from "../../utils/filters/featuresOptions";
import getPropertyTypeFilterOptions from "../../utils/filters/propertyTypeFilterOptions";
// import getUserRoles from "../../utils/auth/getUserRoles";

class FilterPanel extends Component {

    componentDidMount() {
        this.props.fetchSearchers()
    }

    handleStatusChange = (selectedOption) => {
        this.props.setStatusFilter(selectedOption)
        this.props.fetchSearchers()
    }

    render() {
        return (
          <div className="row">
              <div className="col-12">
                  <div className="row">
                      <div className="col-12">
                          <h4 className="text-center mb-3">Search result status</h4>
                          <Select
                            value={this.props.filter.status}
                            onChange={(selectedOption) => this.handleStatusChange(selectedOption)}
                            options={getStatusFilterOptions()}
                            placeholder={"Status"}
                          />
                          <small>Note: when changing status, all other filter values reset to their default value.</small>
                      </div>
                      <div className="col-12">
                          <hr />
                      </div>
                      <div className="col-12">
                          <h4 className="text-center mb-3">Date Added</h4>
                      </div>
                      <div className="col-12 mb-5">
                          <div className="row">
                              <div className="col-6">
                                  <label>Start</label>
                                  <DatePicker
                                      selected={this.props.filter.createdStartDate}
                                      onChange={date => this.props.setCreatedStartDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="Select a from date"
                                  />
                              </div>
                              <div className="col-6">
                                  <label>End</label>
                                  <DatePicker
                                      selected={this.props.filter.createdEndDate}
                                      onChange={date => this.props.setCreatedEndDate(date)}
                                      dateFormat="dd/MM/yyyy "
                                      placeholderText="Select a to date"
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="col-12">
                          <hr />
                      </div>
                      <div className="col-12">
                          <div className="row">
                              <div className="col-12 mb-3">
                                  <h4 className="text-center mb-3">Searcher</h4>
                                  <label>Email address</label>
                                  <Select
                                      value={this.props.filter.searcher}
                                      onChange={(selectedOption) => this.props.selectedSearcher(selectedOption)}
                                      options={[{value: null, label: 'All'}].concat(this.props.filter.searchers.map(searcher => {
                                          return {value: searcher.id, label: searcher.email}
                                      }))}
                                      placeholder={"Searcher email"}
                                  />
                              </div>
                              <div className="col-6">
                                  <label>Start</label>
                                  <DatePicker
                                      selected={this.props.filter.searcherStartDate}
                                      onChange={date => this.props.setSearcherStartDate(date)}
                                      dateFormat="dd/MM/yyyy HH:mm"
                                      placeholderText="Select a from date"
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                  />
                              </div>
                              <div className="col-6">
                                  <label>End</label>
                                  <DatePicker
                                      selected={this.props.filter.searcherEndDate}
                                      onChange={date => this.props.setSearcherEndDate(date)}
                                      dateFormat="dd/MM/yyyy HH:mm"
                                      placeholderText="Select a to date"
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="col-12">
                          <hr />
                      </div>
                      <div className="col-12">
                          <div className="row">
                              <div className="col-12">
                                  <h4 className="text-center mb-3">Text filter</h4>
                              </div>
                              <div className="col-6 mb-3">
                                  <label>Domain</label>
                                  <input type="text" onChange={(e) => {
                                      this.props.setHost(e.target.value)
                                  }} className="form-control form-control-user" value={this.props.filter.host}
                                         disabled={false}/>
                              </div>
                              <div className="col-6 mb-3">
                                  <label>Text search</label>
                                  <input type="text" onChange={(e) => {
                                      this.props.setTextSearch(e.target.value)
                                  }} className="form-control form-control-user"
                                         value={this.props.filter.textSearch}
                                         disabled={false}/>
                              </div>
                              <div className="col-6">
                                  <label>Notes</label>
                                  <input type="text" onChange={(e) => {
                                      this.props.setNotesSearch(e.target.value)
                                  }} className="form-control form-control-user"
                                         value={this.props.filter.notesSearch}
                                         disabled={false}/>
                              </div>
                              <div className="col-6">
                                  <label>Additional Information</label>
                                  <input type="text" onChange={(e) => {
                                      this.props.setAdditionalInformationSearch(e.target.value)
                                  }} className="form-control form-control-user"
                                         value={this.props.filter.additionalInformationSearch}
                                         disabled={false}/>
                              </div>
                          </div>
                      </div>
                      <div className="col-12">
                          <hr />
                      </div>
                      <div className="col-12">
                          <div className="row">
                              <div className="col-12">
                                  <h4 className="text-center mb-3">Search result properties</h4>
                              </div>
                              <div className="col-6 mb-3">
                                  <label>Property type</label>
                                  <Select
                                    value={this.props.filter.propertyType}
                                    onChange={(selectedOption) => this.props.setPropertyTypeFilter(selectedOption)}
                                    options={getPropertyTypeFilterOptions()}
                                    placeholder={"Property type"}
                                    isMulti
                                  />
                              </div>
                              <div className="col-6 mb-3">
                                  <label>Scraped</label>
                                  <Select
                                    value={this.props.filter.analyzedFilterValue}
                                    onChange={(selectedOption) => this.props.setAnalyzedFilter(selectedOption)}
                                    options={getAnalysisFilterOptions()}
                                    placeholder={"Scraped"}
                                    isMulti
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="col-12">
                          <hr />
                      </div>
                      <div className="col-12">
                          <div className="row">
                              <div className="col-12">
                                  <h4 className="text-center mb-3">Sorting</h4>
                              </div>


                              <div className="col-6 mb-3">
                                  <label>Sort</label>
                                  <Select
                                      value={this.props.filter.sortKey}
                                      menuPlacement={"top"}
                                      onChange={(selectedOption) => this.props.setSort(selectedOption)}
                                      options={[
                                          {value: 'no-sort', label: 'None'},
                                          {value: 'date_found_asc', label: 'Date Found Asc'},
                                          {value: 'date_found_desc', label: 'Date Found Desc'},
                                          {value: 'domain_az', label: 'Domain AZ'},
                                          {value: 'domain_za', label: 'Domain ZA'},
                                          {value: 'monthly_visits_lh', label: 'Monthly Visits Low to High'},
                                          {value: 'monthly_visits_hl', label: 'Monthly Visits High to Low'},
                                      ]}
                                      placeholder={"None"}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filter: state.filter,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setStatusFilter: (value) => {
            dispatch(setStatusFilter(value))
        },
        setHost: (value) => {
            dispatch(setHost(value))
        },
        setTextSearch: (value) => {
            dispatch(setTextSearch(value))
        },
        setNotesSearch: (value) => {
            dispatch(setNotesSearch(value))
        },
        setAdditionalInformationSearch: (value) => {
            dispatch(setAdditionalInformationSearch(value))
        },
        setPropertyTypeFilter: (filterValue) => {
            dispatch(setPropertyTypeFilter(filterValue))
        },
        setAnalyzedFilter: (filterValue) => {
            dispatch(setAnalyzedFilter(filterValue))
        },
        setDkSellerRating: (filterValue) => {
            dispatch(setDkSellerRating(filterValue))
        },
        setFeatures: (filterValue) => {
            dispatch(setFeatures(filterValue))
        },
        fetchSearchers: () => {
            dispatch(fetchSearchers())
        },
        selectedSearcher: (searcher) => {
            dispatch(selectedSearcher(searcher))
        },

        setCreatedStartDate: (startDate) => {
            dispatch(setCreatedStartDate(startDate))
        },
        setCreatedEndDate: (endDate) => {
            dispatch(setCreatedEndDate(endDate))
        },

        setSearcherStartDate: (startDate) => {
            dispatch(setSearcherStartDate(startDate))
        },
        setSearcherEndDate: (endDate) => {
            dispatch(setSearcherEndDate(endDate))
        },
        setSort: (sortValue) => {
            dispatch(setSort(sortValue))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
