import prepHeaders from '../../utils/prepHeaders';
import axios from "axios";

export const FETCHINING = 'shortlist:fetchingImages';
export const SET_SHORTLIST_IMAGES = 'shortlist:setImages';
export const UPDATE_SEARCH_RESULT_FEATURES = 'shortlist:updateFeatures';

export function fetchingImages(status) {
    return {
        type: FETCHINING,
        payload: {
            status
        }
    }
}

export function setShortlistImages(images) {
    return {
        type: SET_SHORTLIST_IMAGES,
        payload: {
            images
        }
    }
}

export function updateSearchResultFeatures(searchHash, features) {
    return {
        type: UPDATE_SEARCH_RESULT_FEATURES,
        payload: {
            searchHash,
            features
        }
    }
}

export function fetchUpdatedFeatures(e, featureId, searchHash) {

    return dispatch => {

        let payload = {
            id: featureId,
            has_feature: e.target.checked
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/classify/feature/mark",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(updateSearchResultFeatures(searchHash, response.data))
        }).catch(error => {
            console.log(error)
        })
    }
}
