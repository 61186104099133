import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import AnalysisInput from "./AnalysisInput"
import AnalysisSelectInput from "./AnalysisSelectInput"
import {doAnalysisFormUpdated} from "../../actions/analyze/analyzeAction";
import determineAnalysisTemplateAndPopulateStateWithData from './../../utils/analysis/analysisTemplate';
import getPropertyTypeFilterOptions from "../../utils/filters/propertyTypeFilterOptions";

class SearchResultAnalysis extends Component {

    constructor(props) {
        super(props)
        this.state = determineAnalysisTemplateAndPopulateStateWithData(this.props.image)
    }

    onFormInputChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleSave = (e) => {
        e.preventDefault()
        this.props.doAnalysisFormUpdated(this.props.image.id, this.state, this.props.image)
    };

    render() {

        const {search_provider} = this.props.image || '';
        return (
          <Fragment>
              <div className="row">
                  <div className="col-12"><h4>Analysis</h4></div>
              </div>
              {this.props.hasImage &&
              <Fragment>
                  <AnalysisInput
                    label="Notes"
                    id="notes"
                    type="text"
                    value={this.state.notes}
                    handleOnChange={this.onFormInputChange}
                    disabled={false}
                  />
                  <AnalysisInput
                    label="URL"
                    id="url"
                    type="text"
                    value={this.state.url}
                    handleOnChange={this.onFormInputChange}
                    disabled={true}
                  />
                  <AnalysisSelectInput
                    label="Property type"
                    id="property_type"
                    options={getPropertyTypeFilterOptions()}
                    value={this.state.property_type}
                    handleOnChange={this.onFormInputChange}
                  />
                  <AnalysisInput
                    label="Date found"
                    id="date_found"
                    type="date"
                    value={this.state.date_found}
                    handleOnChange={this.onFormInputChange}
                    disabled={true}
                  />
                  <AnalysisInput
                    label="Date presented"
                    id="date_presented"
                    type="date"
                    value={this.state.date_presented_to_client}
                    handleOnChange={this.onFormInputChange}
                    disabled={true}
                  />
                  <AnalysisInput
                    label="Dupe value (%)"
                    id="dupe_value"
                    type="number"
                    value={this.state.dupe_value}
                    handleOnChange={this.onFormInputChange}
                    disabled={true}
                  />
                  <AnalysisInput
                    label="Dupe value for report"
                    id="dupe_value_report"
                    type="number"
                    value={this.state.dupe_value_report}
                    handleOnChange={this.onFormInputChange}
                    disabled={false}
                  />
                  <AnalysisInput
                    label="Seller location"
                    id="seller_location"
                    type="text"
                    value={this.state.seller_location}
                    handleOnChange={this.onFormInputChange}
                    disabled={false}
                  />
                  <AnalysisSelectInput
                    label="Action recommended"
                    id="action_recomended"
                    options={[
                        {label: "No", value: 0},
                        {label: "Yes", value: 1},
                    ]}
                    value={this.state.action_recomended}
                    handleOnChange={this.onFormInputChange}
                  />
                  <AnalysisInput
                    label="Cause of action"
                    id="cause_of_action"
                    type="text"
                    value={this.state.cause_of_action}
                    handleOnChange={this.onFormInputChange}
                    disabled={false}
                  />
                  <AnalysisInput
                    label="Additional information"
                    id="additional_information"
                    type="text"
                    value={this.state.additional_information}
                    handleOnChange={this.onFormInputChange}
                    disabled={false}
                  />
                  <AnalysisInput
                    label="Currency and price"
                    id="currency_and_price"
                    type="text"
                    value={this.state.currency_and_price}
                    handleOnChange={this.onFormInputChange}
                    disabled={false}
                  />
                  <AnalysisInput
                    label="Sterling price"
                    id="sterling_price"
                    type="number"
                    value={this.state.sterling_price}
                    handleOnChange={this.onFormInputChange}
                    disabled={false}
                  />
                  <AnalysisSelectInput
                    label="DK seller rating"
                    id="dk_seller_rating"
                    options={[
                        {label: "Low volume", value: 1},
                        {label: "Medium volume", value: 2},
                        {label: "High volume", value: 3}
                    ]}
                    value={this.state.dk_seller_rating}
                    handleOnChange={this.onFormInputChange}
                  />
                  <AnalysisInput
                    label="Product description"
                    id="product_description"
                    type="text"
                    value={this.state.product_description}
                    handleOnChange={this.onFormInputChange}
                    disabled={false}
                  />
                  <AnalysisSelectInput
                    label="Enforcement status"
                    id="enforcement_status"
                    options={[
                        {label: "Not actioned", value: 1},
                        {label: "Identified", value: 2},
                        {label: "Awaiting instructions", value: 3},
                        {label: "No further action", value: 4},
                    ]}
                    value={this.state.enforcement_status}
                    handleOnChange={this.onFormInputChange}
                  />

                  <AnalysisSelectInput
                    label="Takedown status"
                    id="takedown_status"
                    options={[
                        {label: "Not required", value: 1},
                        {label: "Not sent", value: 2},
                        {label: "Sent", value: 3},
                        {label: "Completed (listing removed)", value: 4},
                        {label: "Queried/Under review", value: 5},
                    ]}
                    value={this.state.takedown_status}
                    handleOnChange={this.onFormInputChange}
                  />
                  <AnalysisSelectInput
                    label="Test purchase"
                    id="test_purchase"
                    options={[
                        {label: "No", value: 0},
                        {label: "Yes", value: 1},
                    ]}
                    value={this.state.test_purchase}
                    handleOnChange={this.onFormInputChange}
                  />

                  <div className="form-group row">
                      <label htmlFor={this.props.id} className="col-sm-3 col-form-label">Image capture</label>
                      <div className="col-sm-9">
                          <div className="input-group mb-2">
                              {this.state.image_source_capture ?
                                <a rel="nopener noreferre" target="_blank" href={this.state.image_source_capture}>See image</a> :
                                <p>No image available. Please analyze the search result to capture a image.</p>
                              }
                          </div>
                      </div>
                  </div>

                  {search_provider === 'google' &&
                  <Fragment>
                      <AnalysisInput
                        label="Domain"
                        id="domain"
                        type="text"
                        value={this.state.domain}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Estimated monthly visits"
                        id="estimated_monthly_visits"
                        type="text"
                        value={this.state.estimated_monthly_visits}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Global domain rank"
                        id="global_domain_rank"
                        type="text"
                        value={this.state.global_domain_rank}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Domain authority"
                        id="domain_authority"
                        type="text"
                        value={this.state.domain_authority}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Page authority"
                        id="page_authority"
                        type="text"
                        value={this.state.page_authority}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Audience"
                        id="audience"
                        type="text"
                        value={this.state.audience}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Ships to"
                        id="ships"
                        type="text"
                        value={this.state.ships}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                  </Fragment>
                  }
                  {(search_provider === 'ebay' || search_provider === 'ebay_com') &&
                  <Fragment>
                      <AnalysisInput
                        label="Ebay Item ID"
                        id="ebay_item_id"
                        type="text"
                        value={this.state.ebay_item_id}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Ebay seller feedback score"
                        id="ebay_seller_feedback_score"
                        type="text"
                        value={this.state.ebay_seller_feedback_score}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                  </Fragment>
                  }
                  {search_provider === 'amazon' &&
                  <Fragment>
                      <AnalysisInput
                        label="Customer reviews"
                        id="amazon_customer_reviews"
                        type="text"
                        value={this.state.amazon_customer_reviews}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                  </Fragment>
                  }
                  {(search_provider === 'amazon' || search_provider === 'ebay' || search_provider === 'ebay_com') &&
                  <Fragment>
                      <AnalysisInput
                        label="Seller"
                        id="seller"
                        type="text"
                        value={this.state.seller}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Seller Rating"
                        id="seller_rating"
                        type="text"
                        value={this.state.seller_rating}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Seller URL"
                        id="seller_url"
                        type="text"
                        value={this.state.seller_url}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                  </Fragment>
                  }
                  {search_provider === 'instagram' &&
                  <Fragment>
                      <AnalysisInput
                        label="Seller"
                        id="seller"
                        type="text"
                        value={this.state.seller}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Seller URL"
                        id="seller_url"
                        type="text"
                        value={this.state.seller_url}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Instagram posts"
                        id="instagram_posts"
                        type="text"
                        value={this.state.instagram_posts}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Instagram likes"
                        id="instagram_likes"
                        type="text"
                        value={this.state.instagram_likes}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Instagram seller method"
                        id="instagram_seller_method"
                        type="text"
                        value={this.state.instagram_seller_method}
                        handleOnChange={this.onFormInputChange}
                        disabled={false}
                      />
                      <AnalysisInput
                        label="Instagram contact details"
                        id="instagram_contact_details"
                        type="text"
                        value={this.state.instagram_contact_details}
                        handleOnChange={this.onFormInputChange}
                        disabled={false}
                      />
                      <AnalysisInput
                        label="Instagram following"
                        id="instagram_following"
                        type="text"
                        value={this.state.instagram_following}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />
                      <AnalysisInput
                        label="Instagram followers"
                        id="instagram_followers"
                        type="text"
                        value={this.state.instagram_followers}
                        handleOnChange={this.onFormInputChange}
                        disabled={true}
                      />

                  </Fragment>
                  }
                  <button onClick={(e) => {this.handleSave(e)}} className="btn btn-primary" type="submit">Update</button>
                  {this.props.sidepanel.updateStatus &&
                      <div className={`alert mt-2 alert-${this.props.sidepanel.updateStatus === 'error' ? 'danger' : 'success'}`} role="alert">
                          {this.props.sidepanel.updateMessage}
                      </div>
                  }
              </Fragment>
              }
          </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sidepanel: state.sidepanel
    }
}

const mapActionToProps = (dispatch) => {
    return {
        doAnalysisFormUpdated: (id, payload) => {dispatch(doAnalysisFormUpdated(id, payload))},
    }
};

export default connect(mapStateToProps, mapActionToProps)(SearchResultAnalysis);