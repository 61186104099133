import React, { Component, Fragment } from 'react';
import Sidebar from "./Sidebar/Sidebar"
import Topbar from "./Topbar"

import SearchList from './search/List';
import {setImages} from "../actions/images/imagesAction";
import { connect } from 'react-redux';
import { doFetchModels, setSelectedMenuModel } from "../actions/models/modelsAction";
import {doQuery, doQueryEdit, rerunQuery} from "../actions/query/queryAction";
import {toggleSearchTermModal, setSearchTerm} from "../actions/searchTerm/searchTermActions";
import SearchTermModal from './search/SearchTermModal';

class Search extends Component {

    componentDidMount() {
        this.props.setImages([]);
        this.props.fetchModels(this.props.match.params.projectId)
    }

    render() {

        const project = JSON.parse(localStorage.getItem('project'))

        return (
            <Fragment>
            <div id="page-top">
                <div id="wrapper">
                    <Sidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="search-content">

                            <Topbar />
                            <div className="container-fluid">
                                <SearchTermModal
                                  modalToggleStatus={this.props.searchTerm.showSearchTermModal}
                                  toggleSearchTermModal={this.props.toggleSearchTermModal}
                                  searchTerm={this.props.searchTerm.searchTerm}
                                  setSearchTerm={this.props.setSearchTerm}
                                  modelList={this.props.models.list}
                                  doQuery={this.props.doQuery}
                                  doQueryEdit={this.props.doQueryEdit}
                                  projectId={this.props.match.params.projectId}
                                  hasFeatures={project.has_features}
                                  features={project.features}
                                />
                                <SearchList
                                    projectId={this.props.match.params.projectId}
                                    toggleSearchTermModal={this.props.toggleSearchTermModal}
                                    setSearchTerm={this.props.setSearchTerm}
                                    rerunQuery={this.props.rerunQuery}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        images: state.images,
        filter: state.filter,
        models: state.models,
        status: state.status,
        sidepanel: state.sidepanel,
        menu: state.menu,
        searchTerm: state.searchTerm
    }
};

const mapActionToProps = (dispatch) => {
    return {
        doQuery: (queryStringAndLimit, projectId) => {dispatch(doQuery(queryStringAndLimit, projectId))},
        doQueryEdit: (searchTerm, searchTermId) => {dispatch(doQueryEdit(searchTerm, searchTermId))},
        rerunQuery: (searchTermId) => {dispatch(rerunQuery(searchTermId))},
        fetchModels: (projectId) => {dispatch(doFetchModels(projectId))},
        setSelectedMenuModel: (selectedMenuModelValue) => {dispatch(setSelectedMenuModel(selectedMenuModelValue))},
        toggleSearchTermModal: (status) => {dispatch(toggleSearchTermModal(status))},
        setSearchTerm: (searchTerm) => {dispatch(setSearchTerm(searchTerm))},
        setImages: (images) => {dispatch(setImages(images))}
    }
};

export default connect(mapStateToProps, mapActionToProps)(Search);
