import { OPEN, CLOSE, OPEN_SHORTLIST, CLOSE_SHORTLIST } from "../actions/sidepanel/sidepanelAction";
import { UPDATE_SEARCH_RESULT_ANALYSIS_FROM_STATUS, } from "../actions/analyze/analyzeAction";

const initialState = {
    open: false,
    openShortlist: false,
    updateStatus: null,
    updateMessage: ''
};

export default function sidepanelReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN:
            return {
                ...state,
                open: action.payload.status
            };
        case CLOSE:
            return {
                ...state,
                open: action.payload.status
            };
        case OPEN_SHORTLIST:
            return {
                ...state,
                openShortlist: action.payload.status
            };
        case CLOSE_SHORTLIST:
            return {
                ...state,
                openShortlist: action.payload.status
            };
        case UPDATE_SEARCH_RESULT_ANALYSIS_FROM_STATUS:
            return {
                ...state,
                updateStatus: action.payload.type,
                updateMessage: action.payload.message
            };
        default:
            return state

    }
}