import * as filterActions from "../actions/filter/filterAction";
import {getStatusFilterOptions} from "../utils/status/statusFilterOptions";
import {SET_ADDITIONAL_INFORMATION_SEARCH} from "../actions/singleViewMenu/singleViewMenuActions";

const initialState = {
    selectedTab: filterActions.TAB_FILTER_ALL,
    status: getStatusFilterOptions()[4] || [],
    remoteStatusValue: -1,
    host: '',
    textSearch: '',
    notesSearch: '',
    additionalInformationSearch: '',
    statusLabel: '',
    propertyType: [],
    analyzedFilterValue: [],
    dkSellerRatingFilterValue: [],
    features: [],
    // actionRecommended: "",
    searchers: [],
    searcher: {value: null, label: 'All'},
    createdStartDate: null,
    createdEndDate: null,
    isDatePresentedToClient: false,
    datePresentedToClientStart: null,
    datePresentedToClientEnd: null,
    searcherStartDate: null,
    searcherEndDate: null,
    currentPageValue: 1,
    totalItems: 0,
    itemsCountPerPage: 40,

    sortKey: {value: 'no-sort', label: 'None'},

    sortDkSellerRating: {value: 'no-sort', label: 'None'},
    sortDateFound: {value: 'desc', label: 'Descending'},
    sortEstimatedMonthlyVisits: {value: 'no-sort', label: 'None'},
    sortDomain: {value: 'no-sort', label: 'None'},
    sortNumberOfFeatures: {value: 'no-sort', label: 'None'},
    sortPropertyType: {value: 'no-sort', label: 'None'}
};

const initialFilterValues = {
    selectedTab: filterActions.TAB_FILTER_ALL,
    status: getStatusFilterOptions()[4] || [],
    remoteStatusValue: -1,
    host: '',
    textSearch: '',
    notesSearch: '',
    additionalInformationSearch: '',
    propertyType: [],
    analyzedFilterValue: [],
    searcher: {value: null, label: 'All'},
    createdStartDate: null,
    createdEndDate: null,
    isDatePresentedToClient: false,
    datePresentedToClientStart: null,
    datePresentedToClientEnd: null,
    searcherStartDate: null,
    searcherEndDate: null,

    sortKey: {value: 'no-sort', label: 'None'},
}

export default function filterReducer(state = initialState, action) {
    switch (action.type) {
        case filterActions.RESET_ALL:
            return {
                ...initialState,
                currentPageValue: state.currentPageValue,
                totalItems: state.totalItems,
                itemsCountPerPage: state.itemsCountPerPage,
            };
        case filterActions.SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.payload.tab
            };
        case filterActions.SET_FILTER_STATUS:
            return {
                ...state,
                ...initialFilterValues,
                status: action.payload.value,
            }
        case filterActions.SET_REMOTE_FILTER_STATUS:
            return {
                ...state,
                remoteStatusValue: action.payload.remoteStatusValue
            }
        case filterActions.SET_HOST:
            return {
                ...state,
                host: action.payload.value
            }
        case filterActions.SET_TEXT_SEARCH:
            return {
                ...state,
                textSearch: action.payload.value
            }
        case filterActions.SET_NOTES_SEARCH:
            return {
                ...state,
                notesSearch: action.payload.value
            }
        case SET_ADDITIONAL_INFORMATION_SEARCH:
            return {
                ...state,
                additionalInformationSearch: action.payload.value
            }
        case filterActions.SET_FILTER_PROPERTY_TYPE:
            return {
                ...state,
                propertyType: action.payload.value
            }
        case filterActions.SET_FILTER_ANALYZED:
            return {
                ...state,
                analyzedFilterValue: action.payload.value
            }
        case filterActions.SET_FILTER_DK_SELLER_RATING:
            return {
                ...state,
                dkSellerRatingFilterValue: action.payload.value
            }
        case filterActions.SET_FILTER_FEATURES:
            return {
                ...state,
                features: action.payload.value
            }
        case filterActions.SET_FILTER_CURRENT_PAGE:
            return {
                ...state,
                currentPageValue: action.payload.currentPageValue
            }
        case filterActions.SET_FILTER_TOTAL_ITEMS:
            return {
                ...state,
                totalItems: action.payload.totalItems
            }
        case filterActions.SET_FILTER_ITEMS_COUNT_PER_PAGE:
            return {
                ...state,
                itemsCountPerPage: parseInt(action.payload.itemsCountPerPage)
            }
        case filterActions.SET_FILTER_SEARCHERS:
            return {
                ...state,
                searchers: action.payload.searchers
            }
        case filterActions.SET_FILTER_SEARCHER:
            return {
                ...state,
                searcher: action.payload.searcher
            }
        case filterActions.SET_CREATED_FROM_DATE:
            return {
                ...state,
                createdStartDate: action.payload.startDate
            }
        case filterActions.SET_CREATED_TO_DATE:
            return {
                ...state,
                createdEndDate: action.payload.endDate
            }
        case filterActions.SET_SEARCHER_FROM_DATE:
            return {
                ...state,
                searcherStartDate: action.payload.startDate
            }
        case filterActions.SET_SEARCHER_TO_DATE:
            return {
                ...state,
                searcherEndDate: action.payload.endDate
            }
        case filterActions.SET_IS_DATE_PRESENTED_TO_CLIENT:
            return {
                ...state,
                isDatePresentedToClient: action.payload.status
            }
        case filterActions.SET_DATE_PRESENTED_TO_CLIENT_START:
            return {
                ...state,
                datePresentedToClientStart: action.payload.startDate
            }
        case filterActions.SET_DATE_PRESENTED_TO_CLIENT_END:
            return {
                ...state,
                datePresentedToClientEnd: action.payload.endDate
            }
        // SORTING
        case filterActions.SET_SORT:
            return {
                ...state,
                sortKey: action.payload.sortOrder
            }
        default:
            return state
    }
}
