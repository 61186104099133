import { SELECT_STATUS } from "../actions/status/statusAction";

const initialState = {
    selectedStatus: 1,
    statusOptions: [
        {value: 1, label: "Shortlisted"},
        {value: 10, label: "Query"},
        {value: 11, label: "Incorrect"},
        {value: 12, label: "Counterfeit"},
        {value: 4, label: "Confirmed"},
        {value: 8, label: "Staging"},
        {value: 7, label: "Current Month"},
        {value: 5, label: "Enforced"},
        {value: 9, label: "Previous Months"}
    ]
};

export default function statusReducer(state = initialState, action) {
    switch (action.type) {
        case SELECT_STATUS:
            return {
                ...state,
                selectedStatus: action.payload.selectedStatus
            }
        default:
            return state
    }
}
