import {
    SET_STATUS,
    SET_HOST,
    SET_PROPERTY_TYPE,
    SET_DK_SELLER_RATING,
    SET_FEATURE,
    SET_ACTION_RECOMMENDED,
    SET_TEXT_SEARCH, SET_NOTES_SEARCH, SET_ADDITIONAL_INFORMATION_SEARCH,
    SET_SORT
} from "../actions/singleViewMenu/singleViewMenuActions";
import {getStatusFilterOptions} from "../utils/status/statusFilterOptions";

const initialState = {
    status: getStatusFilterOptions()[3],
    host: '',
    textSearch: '',
    notesSearch: '',
    additionalInformationSearch: '',
    propertyType: [],
    dkSellerRating: [],
    features: [],
    actionRecommended: [],
    sortKey: {value: 'no-sort', label: 'None'},
};

export default function singleViewMenuReducer(state = initialState, action) {
    switch (action.type) {
        case SET_STATUS:
            return {
                ...state,
                status: action.payload.statusValue
            }
        case SET_HOST:
            return {
                ...state,
                host: action.payload.hostValue
            }
        case SET_TEXT_SEARCH:
            return {
                ...state,
                textSearch: action.payload.value
            }
        case SET_NOTES_SEARCH:
            return {
                ...state,
                notesSearch: action.payload.value
            }
        case SET_ADDITIONAL_INFORMATION_SEARCH:
            return {
                ...state,
                additionalInformationSearch: action.payload.value
            }
        case SET_PROPERTY_TYPE:
            return {
                ...state,
                propertyType: action.payload.propertyType
            }
        case SET_DK_SELLER_RATING:
            return {
                ...state,
                dkSellerRating: action.payload.dkSellerRating
            }
        case SET_FEATURE:
            return {
                ...state,
                features: action.payload.feature
            }
        case SET_ACTION_RECOMMENDED:
            return {
                ...state,
                actionRecommended: action.payload.actionRecommended
            }
        case SET_SORT:
            return {
                ...state,
                sortKey: action.payload.sortOrder
            }
        default:
            return state
    }
}