import {
    SET_SEARCHER_REPORT_START_DATE,
    SET_SEARCHER_REPORT_END_DATE,
    SET_BUTTON_DOWNLOADING_STATUS
} from "../actions/searcherReport/searcherRreportAction";

const initialState = {
    searcherReportStartDate: null,
    searcherReportEndDate: null,
    downloadButtonValidationState: false,
    downloadingStatus: false,
}

const searcherReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCHER_REPORT_START_DATE:
            return {
                ...state,
                downloadButtonValidationState: state.searcherReportEndDate !== null,
                searcherReportStartDate: action.payload.startDate
            }
        case SET_SEARCHER_REPORT_END_DATE:
            return {
                ...state,
                downloadButtonValidationState: state.searcherReportStartDate !== null,
                searcherReportEndDate: action.payload.endDate
            }
        case SET_BUTTON_DOWNLOADING_STATUS:
            return {
                ...state,
                downloadingStatus: action.payload.downloadingStatus
            }
        default:
            return state
    }
}

export default searcherReportReducer