import React, {Component} from "react";
import {formatNumberAsIntl} from "../../../utils/format";
import {getStatusFilterOptions} from "../../../utils/status/statusFilterOptions";

class TopWebsitesFeaturingDupesByEmv extends Component {

    handleCountClick = (e, host) => {
        e.preventDefault()
        this.props.setRemoteStatus(getStatusFilterOptions()[0])
        this.props.setIsDatePresentedToClient(true)
        this.props.setDatePresentedToClientStart(new Date(this.props.summary.from))
        this.props.setDatePresentedToClientEnd(new Date(this.props.summary.to))
        this.props.setHost(host)
        this.props.router.push(`/project/${JSON.parse(localStorage.getItem('project')).id}/shortlist`)
    }

    render() {
        return (
          <div className="card shadow">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Top websites featuring dupes by estimated monthly
                      visits</h6>
              </div>
              <div className="card-body">
                  <div className="table-responsive table-hover">
                      <table className="table">
                          <thead>
                          <tr>
                              <th scope="col">Rank</th>
                              <th scope="col">Website</th>
                              <th scope="col">Monthly Visits (Est.)</th>
                              <th scope="col">Dupe Count</th>
                          </tr>
                          </thead>

                          <tbody>
                          {this.props.data.slice(0, 10).map((item, index) => {
                              let estMonthlyVisits = item.estimated_monthly_visits == undefined ? '' : formatNumberAsIntl(item.estimated_monthly_visits)
                              estMonthlyVisits = estMonthlyVisits === '0' ? 'Not available' : estMonthlyVisits
                              let dupeCount =  item.dupe_count == undefined ? '' : formatNumberAsIntl(item.dupe_count)
                              dupeCount = dupeCount === '0' ? 'Not available' : dupeCount

                              return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><a target="_blank" href={'//' + item.domain}>{item.domain}</a></td>
                                    <td>{estMonthlyVisits}</td>
                                    <td><a onClick={e => this.handleCountClick(e, item.domain)} href="#">{dupeCount}</a></td>
                                </tr>
                              )
                          })}
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
        )
    }
}

export default TopWebsitesFeaturingDupesByEmv;