import React, {Component, Fragment} from 'react';
import Modal from 'react-bootstrap4-modal';
import {getStatusFilterOptions} from "../../utils/status/statusFilterOptions";

class ConfirmationModal extends Component {

    getStatusLabel = () => {
        return getStatusFilterOptions().filter(option => {
            if(parseInt(option.value) === parseInt(this.props.statusValue)) {
                return option.label
            }
        })
    };

    handleConfirm = () => {
        this.props.doMark(this.props.selectedImages, this.props.statusValue, this.props.images, this.props.searchTermId, this.props.projectId)
        this.props.setConfirmationModalModalStatus(false)
    };

    render() {
        let statusLabel = this.getStatusLabel();
        return (
          <div className="query-modal">
              <Modal dialogClassName="modal-md modal-dialog-centered" visible={this.props.confirmationModalStatus} onClickBackdrop={() => {
                  this.props.setConfirmationModalModalStatus(false)
              }}>
                  <div className="modal-header">
                      <h5 className="modal-title">Confirmation required</h5>
                  </div>
                  <div className="modal-body">
                      <p>{`You are about to mark ${this.props.selectedImages.length} search results as ${statusLabel[0].label}`}</p>
                  </div>
                  <div className="modal-footer">
                      <button onClick={() => {
                          this.props.setConfirmationModalModalStatus(false)
                      }} type="button" className="btn btn-danger">
                          Cancel
                      </button>

                      <button onClick={(e) => this.handleConfirm(e)}
                              className="btn btn-success btn-icon-split">
                                          <span className="icon text-white-50">
                                            <i className="fas fa-search"></i>
                                          </span>
                          <span className="text">Confirm</span>
                      </button>
                  </div>
              </Modal>
          </div>
        )
    }
}

export default ConfirmationModal;