import React, {Component} from 'react';

class DownloadLink extends Component {

    onClick = (e) => {
        e.preventDefault()

        let payload = {
            id: this.props.row.id,
            project_id: this.props.projectId
        };

        this.props.download(payload)
    }

    render() {
        return (
          <a
            onClick={(e) => this.onClick(e)}
            className={`btn btn-primary text-white w-100 ${this.props.row.s3_url? '' : 'disabled'}`}
            href={this.props.row.s3_url}>
              <i className="fas fa-file-download"></i>
          </a>
        )
    }
}

export default DownloadLink;