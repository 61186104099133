import React, {Component} from "react";
import Toggle from 'react-toggle';

class Publish extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toggleStatus: this.props.row.published
        }
    }

    onToggleChange = (e) => {
        this.setState(prevState => ({toggleStatus: !prevState.toggleStatus}))
        this.props.doPublishStatus(e.target.checked, this.props.row.id)
    }

    render() {
        return (
          <Toggle
            id="list-query-automated"
            onChange={(e) => this.onToggleChange(e)}
            icons={false}
            checked={this.state.toggleStatus}
          />
        )
    }
}

export default Publish