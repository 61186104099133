import React, {Component, Fragment} from 'react';
import Topbar from "./Topbar";
import Sidebar from "./Sidebar/Sidebar";
import {connect} from 'react-redux';
import SvsFilterPanel from "./panel/SvsFilterPanel";
import SingleSearchResultCardViewer from "./singleViewShortlist/SingleSearchResultCardViewer";
import {
    fetchIdsListForSingleViewShortlist, fetchSingleViewImageData, setIndexIdsForNavigation,
} from "../actions/shortlist/singleViewShortlist";
import {fetchUpdatedFeatures} from "../actions/shortlist/shortlist";
import {saveNotes, setNotes} from "../actions/notes/notes";

class SingleViewShortlist extends Component {

    constructor(props) {
        super(props)

        this.state = {
            slidingFilterPanelStatus: false
        }
    }

    componentDidMount() {

        let filters = {
            status: this.props.singleViewMenu.status.value,
            host: this.props.singleViewMenu.host,
            textSearch: this.props.singleViewMenu.textSearch || '',
            notesSearch: this.props.singleViewMenu.notesSearch || '',
            propertyType: this.props.singleViewMenu.propertyType,
            dkSellerRating: this.props.singleViewMenu.dkSellerRating,
            features: this.props.singleViewMenu.features,
            actionRecommended: this.props.singleViewMenu.actionRecommended.value || '',
            sortKey: this.props.singleViewMenu.sortKey.value,
        }

        this.props.fetchIdsListForSingleViewShortlist(filters)
    }

    cycleNext = (e, currentIndex) => {

        e.preventDefault()

        let toBeCurrentIndex = currentIndex + 1;
        let toBeNextIndex = toBeCurrentIndex + 1;
        let toBePrevIndex = toBeCurrentIndex - 1;

        if (toBeCurrentIndex > this.props.singleViewShortlist.ids.length - 1) {
            toBeCurrentIndex = currentIndex;
        }

        this.props.setIndexIdsForNavigation(toBeCurrentIndex, toBeNextIndex, toBePrevIndex);
        this.props.fetchSingleViewImageData(this.props.singleViewShortlist.ids[toBeCurrentIndex])
    }

    cyclePrev = (e, currentIndex) => {

        e.preventDefault()

        let toBeCurrentIndex = currentIndex - 1;
        let toBeNextIndex = toBeCurrentIndex + 1;
        let toBePrevIndex = toBeCurrentIndex - 1;

        if (toBePrevIndex < 0) {
            toBePrevIndex = null;
        }

        this.props.setIndexIdsForNavigation(toBeCurrentIndex, toBeNextIndex, toBePrevIndex);
        this.props.fetchSingleViewImageData(this.props.singleViewShortlist.ids[toBeCurrentIndex])
    }

    render() {
        return (
          <div id="page-top">
              <div id="wrapper">
                  <Sidebar/>
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content" className="svs">
                          <Topbar/>
                          <div className="container-fluid">
                              <div className="row">
                              <div className="col-12 mb-3">
                                  <div className="row">
                                      <div className="col d-flex justify-content-between">
                                          <button
                                            disabled={this.props.singleViewShortlist.previousIdIndex === null ? true : false}
                                            className="btn btn-primary mt-3"
                                            onClick={(e) => this.cyclePrev(e, this.props.singleViewShortlist.currentIdIndex)}>
                                              Prev
                                          </button>
                                          <button className="btn btn-primary mt-3" onClick={() => this.setState({slidingFilterPanelStatus: true})}>Filters</button>
                                          <button
                                            disabled={this.props.singleViewShortlist.currentIdIndex === this.props.singleViewShortlist.ids.length - 1 ? true : false}
                                            className="btn btn-primary mt-3"
                                            onClick={(e) => this.cycleNext(e, this.props.singleViewShortlist.currentIdIndex)}>
                                              Next
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              </div>
                              {this.props.singleViewShortlist.fetchingIds ?
                                <div className="row h-100">
                                    <div className="col-12 h-100 d-flex align-items-center justify-content-center">
                                        <i className="fas fa-circle-notch fa-spin fetching-spinner"></i>
                                    </div>
                                </div>
                                :
                                <Fragment>
                                    {this.props.singleViewShortlist.ids.length > 0 ?
                                      <div className="row">
                                          <div className="col-12">
                                              {this.props.singleViewShortlist.imageData &&
                                              <SingleSearchResultCardViewer
                                                projectTemplate={this.props.projectList.projectTemplate}
                                                imageData={this.props.singleViewShortlist.imageData}
                                                fetching={this.props.singleViewShortlist.fetching}
                                                fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
                                                currentIndex={this.props.singleViewShortlist.currentIdIndex}
                                                listLength={this.props.singleViewShortlist.ids.length}
                                                setNotes={this.props.setNotes}
                                                saveNotes={this.props.saveNotes}
                                                notesValue={this.props.singleViewShortlist.notes}
                                              />
                                              }
                                          </div>
                                      </div>
                                      :
                                      <div className="row">
                                          <div className="col-6 offset-3 text-center">
                                              <div className="alert alert-info">No search results matching your
                                                  filters.
                                              </div>
                                          </div>
                                      </div>
                                    }
                                </Fragment>
                              }
                          </div>
                      </div>
                  </div>
              </div>
          <SvsFilterPanel
            isOpen={this.state.slidingFilterPanelStatus}
            onBackdropClick={() => this.setState({slidingFilterPanelStatus: false})}
            handleInOpenChange={(status) => this.setState({slidingFilterPanelStatus: status})}
          />
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        singleViewShortlist: state.singleViewShortlist,
        singleViewMenu: state.singleViewMenu,
        projectList: state.projectList
    }
};

const mapActionToProps = (dispatch) => {
    return {
        fetchIdsListForSingleViewShortlist: (srStatus) => {
            dispatch(fetchIdsListForSingleViewShortlist(srStatus))
        },
        fetchUpdatedFeatures: (e, featureId, searchHash) => {
            dispatch(fetchUpdatedFeatures(e, featureId, searchHash))
        },
        // Notes
        setNotes: (notes) => {
            dispatch(setNotes(notes))
        },
        saveNotes: (searchResultId, notes) => {
            dispatch(saveNotes(searchResultId, notes))
        },
        setIndexIdsForNavigation: (toBeCurrentIndex, toBeNextIndex, toBePrevIndex) => {
            dispatch(setIndexIdsForNavigation(toBeCurrentIndex, toBeNextIndex, toBePrevIndex))
        },
        fetchSingleViewImageData: (searchResultId) => {
            dispatch(fetchSingleViewImageData(searchResultId))
        }
    }
};

export default connect(mapStateToProps, mapActionToProps)(SingleViewShortlist)
