import prepHeaders from "../../utils/prepHeaders";
import axios from "axios";

export const SET_FETCHING_USERS_STATUS = 'userManagement:fetchingUsersStatus';
export const SET_USERS_LIST = 'userManagement:setUsersList'
export const SET_USER_EDIT_DATA = 'userManagement:setUserEditData'

export function setFetchingUsersStatus(status)
{
    return {
        type: SET_FETCHING_USERS_STATUS,
        payload: {
            status
        }
    }
}

export function setUsersList(usersList) {
    return {
        type: SET_USERS_LIST,
        payload: usersList
    }
}

export function setUserEdit(userData) {
    return {
        type: SET_USER_EDIT_DATA,
        payload: userData
    }
}



export const getAllUsers = () => {
  return dispatch => {

      dispatch(setFetchingUsersStatus(true))

      axios({
          url: process.env.REACT_APP_API_BASE_URL + `/user/all`,
          method: 'GET',
          withCredentials: true,
          headers: prepHeaders(),
      }).then(response => {
          dispatch(setUsersList(response.data))
          dispatch(setFetchingUsersStatus(false))
      }).catch(error => {
          dispatch(setFetchingUsersStatus(false))
          console.log(error)
      })
  }
}

export const getSingleUser = (userId) => {
    return dispatch => {

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/user/` + userId,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setUserEdit(response.data))
        }).catch(error => {
            console.log(error)
        })
    }
}
