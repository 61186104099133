import prepHeaders from '../../utils/prepHeaders';
import download from 'downloadjs';
import moment from 'moment';
import axios from "axios";

export const SET_REPORTS_LIST = "reports:fetchList";
export const SET_DOWNLOADING = "reports:downloading";
export const SET_FETCHING_STATUS = "reports:fetchingStatus";
export const TOGGLE_MODAL = "reports:toggleModal";
export const APPEND_TO_REPORT_LIST = "reports:appendToList";

export function setReportsList(reportsList) {
    return {
        type: SET_REPORTS_LIST,
        payload: {
            reportsList
        }
    }
}

export default function setDownloading(status) {
    return {
        type: SET_DOWNLOADING,
        payload: {
            status
        }
    }
}

export function setFetchingStatus(status) {
    return {
        type: SET_FETCHING_STATUS,
        payload: {
            status
        }
    }
}

export function toggleModal(status) {
    return {
        type: TOGGLE_MODAL,
        payload: {
            status
        }
    }
}

export function appendToReportList(report) {
    return {
        type: APPEND_TO_REPORT_LIST,
        payload: {
            report
        }
    }
}

export const downloadReport = payload => {
    return dispatch => {

        dispatch(setDownloading(true));

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/report/download",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload,
            responseType: 'blob'
        }).then(response => {
            let fileName = payload.filename ? payload.filename : `report_${moment().format('YYYY-MM-DD_H:m')}.zip`;
            download(response.data, fileName, "text/plain");
            dispatch(setDownloading(false));
        }).catch(error => {
            dispatch(setDownloading(false));
            console.log(error)
        })
    }
}

export function generateReport(data, projectId) {
    return dispatch => {

        let payload = {
            name: data.name,
            status: data.category,
            project_id: projectId
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/report/generate`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(appendToReportList(response.data))
        }).catch(error => {
            console.log(error)
        })
    }
}

export function doFetchReportsList(projectId) {
    return dispatch => {

        dispatch(setFetchingStatus(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/report/${projectId}`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setReportsList(response.data))
            dispatch(setFetchingStatus(false))
        }).catch(error => {
            dispatch(setFetchingStatus(false))
            console.log(error)
        })
    }
}