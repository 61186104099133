import React, {Component} from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import SearchResultAnalysis from '../panel/SearchResultAnalysis';

class Panel extends Component {

    render() {
        const {hasImage} = this.props;
        return (
          <SlidingPanel
            type={'right'}
            isOpen={this.props.isOpen}
            size={48}
            backdropClicked={() => this.props.closeSidePanel()}
          >
              <div className="panel-container">
                  <div className="panel-header">
                      <span className="title">Search result: {hasImage && this.props.image.search_hash}</span>
                      <i className="far fa-times-circle" onClick={() => this.props.closeSidePanel()}></i>
                  </div>
                  <div className="img-container text-center">
                      <img className="img-fluid" src={hasImage ? this.props.image.image_url : ""}/>
                  </div>
                  <div className="panel-body">
                      <div className="row">
                          <div className="col-12">
                              <div className="row d-flex align-items-center justify-content-between">
                                  <div className="col-4">
                                      <h3 id="score" className="mb-0">
                                          Score: {hasImage && ((this.props.image.model_score && this.props.image.model_score.toFixed(0)))}
                                          %</h3>
                                  </div>
                                  <div className="col-auto">
                                      <a className="btn btn-primary mr-2" target="_blank" rel="noopener noreferrer"
                                         href={hasImage ? this.props.image.image_url : "#"}>Image Link</a>
                                      <a className="btn btn-primary mr-2" target="_blank" rel="noopener noreferrer"
                                         href={hasImage ? this.props.image.image_source : "#"}>Image source</a>
                                      <a className="btn btn-primary" target="_blank" rel="noopener noreferrer"
                                         href={hasImage ? `http://${this.props.image.image_host}`: "#"}>Image host</a>
                                  </div>
                              </div>
                              <hr/>
                          </div>
                          <div className="col-12 mb-3">
                              <div className="row">
                                  <div className="col-12"><h4>General</h4></div>
                                  <div className="col-4 mb-2">
                                      Description:
                                  </div>
                                  <div className="col-8 mb-2">
                                      {hasImage && (this.props.image.description || "N/A")}
                                  </div>
                                  <div className="col-4 mb-2">
                                      Image provider:
                                  </div>
                                  <div className="col-8 mb-2">
                                      {hasImage && (this.props.image.search_provider || "N/A")}
                                  </div>
                              </div>
                          </div>
                          {hasImage && this.props.image.model &&
                          <div className="col-12 mb-3">
                              <div className="row">
                                  <div className="col-12"><h4>Model</h4></div>
                                  <div className="col-4 mb-2">
                                      ID:
                                  </div>
                                  <div className="col-8 mb-2">
                                      {hasImage && (this.props.image.model.auto_ml_model_id || "N/A")}
                                  </div>
                                  <div className="col-4 mb-2">
                                      Name:
                                  </div>
                                  <div className="col-8 mb-2">
                                      {hasImage && (this.props.image.model.display_name || "N/A")}
                                  </div>
                                  <div className="col-4 mb-2">
                                      Label:
                                  </div>
                                  <div className="col-8 mb-2">
                                      {hasImage && (this.props.image.model.label_key || "N/A")}
                                  </div>
                              </div>
                          </div>
                          }
                          {this.props.hasAnalysisFields &&
                          <div className="col-12 mb-3">
                              <SearchResultAnalysis
                                image={this.props.image}
                                hasImage={hasImage}
                              />
                          </div>
                          }
                      </div>
                  </div>
                  {/*<div className="panel-footer">*/}

                  {/*</div>*/}
              </div>
          </SlidingPanel>
        )
    }
}

export default Panel