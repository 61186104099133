import React, {Component} from 'react';

class ListItemTitle extends Component {

    render() {
        return (
          <p className="mb-0">{this.props.row.name || ''}</p>
        )
    }
}

export default ListItemTitle;