import {SET_FETCHING_STATUS, SET_REPORTS_LIST, TOGGLE_MODAL, APPEND_TO_REPORT_LIST, SET_DOWNLOADING} from "../actions/reports/reportsAction";

const initialState = {
    fetching: false,
    modalStatus: false,
    downloading: false,
    reportsList: [],
};

export default function reportsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FETCHING_STATUS:
            return {
                ...state,
                fetching: action.payload.status
            };
        case SET_DOWNLOADING:
            return {
                ...state,
                downloading: action.payload.status
            };
        case SET_REPORTS_LIST:
            return {
                ...state,
                reportsList: action.payload.reportsList
            };
        case APPEND_TO_REPORT_LIST:

            let copyOfReportList = [...state.reportsList]

            copyOfReportList.push(action.payload.report)

            return {
                ...state,
                reportsList: copyOfReportList
            }
        case TOGGLE_MODAL:
            return {
              ...state,
                modalStatus: action.payload.status
            };
        default:
            return state
    }
}
