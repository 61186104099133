import React, {Component} from 'react';
import Toggle from 'react-toggle';


class AutomatedLabel extends Component {

    onToggleChange = (e) => {
        this.props.doSearchTermIsAutomated(this.props.row.id, !!e.target.checked)
    }

    render() {
        return (
              <div className="row">
                  <div className="col-12">
                    <label>
                      <Toggle
                        id="list-query-automated"
                        onChange={this.onToggleChange}
                        // icons={false}
                        checked={!!this.props.row.automated}
                        disabled={!this.props.row.model_id}
                      />
                    </label>
                  </div>
          </div>
        )
    }
}

export default AutomatedLabel;