import {
    FETCHING_QUERY_LIST,
    SET_QUERY_LIST,
    PUSH_SINGLE_QUERY_TO_LIST, UPDATE_QUERY_IN_LIST
} from "../actions/query/queryAction";

const initialState = {
    fetching: false,
    list: [],
};

export default function queryReducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_QUERY_LIST:
            return {
                ...state,
                fetching: action.payload.status
            };
        case SET_QUERY_LIST:
            return {
                ...state,
                list: action.payload.list
            };

        case PUSH_SINGLE_QUERY_TO_LIST:
            let newList = [...state.list]
            newList.push(action.payload.newQuery);

            return {
                ...state,
                list: newList
            }

        case UPDATE_QUERY_IN_LIST:

            return {
                ...state,
                list: state.list.map(listItem => {
                    if (action.payload.newQuery.id === listItem.id) {
                        listItem = action.payload.newQuery
                    }
                    return listItem
                })
            }

        default:
            return state
    }
}
