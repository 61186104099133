import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {formatNumberAsIntl} from "../../../utils/format";

import {
    XYPlot,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    HorizontalBarSeries,
    LabelSeries,
} from 'react-vis';
import getPropertyTypeFilterOptions from "../../../utils/filters/propertyTypeFilterOptions";
import {getStatusFilterOptions} from "../../../utils/status/statusFilterOptions";

class BarChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            useCanvas: false,
        };
    }

    parseColorRange = () => {
        if (this.props.data.length !== 3) {
            return ['#2179ff', '#267dff', '#2c80ff', '#3284ff', '#3887ff', '#3d8bff', '#438eff', '#4991ff', '#4e95ff', '#5498ff']
        } else {
            return ["#2179ff", "#4991ff", "#71a9ff"]
        }
    }

    parseColorDomain = () => {
        if (this.props.data.length !== 3) {
            return [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
        } else {
            return this.colorDomain = [2, 1, 0]
        }
    }

    onBarValueClick = value => {
        const { y } = value

        let route = `/project/${JSON.parse(localStorage.getItem('project')).id}/shortlist`

        this.props.resetFiltersToDefaul()
        this.props.setIsDatePresentedToClient(true)
        this.props.setDatePresentedToClientStart(new Date(this.props.summary.from))
        this.props.setDatePresentedToClientEnd(new Date(this.props.summary.to))
        this.props.setRemoteStatus(getStatusFilterOptions()[0])

        if (y === 'Web') {
            let option = getPropertyTypeFilterOptions().find(option => option.label === y)
            this.props.setPropertyType([option])
            this.props.router.push(route)

            return
        }

        this.props.setHost(y)
        this.props.router.push(route)
    }

    render() {

        let data = [...this.props.data];

        if (this.props.showWeb === false) {
            data = data.filter(item => item.platform !== 'Web')
        }

        return (
          <div className="card shadow platform-split">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">{this.props.cardTitle}</h6>
              </div>
              <div className="card-body d-block">
                  <div className="auto-resizer d-block" style={{height: '100%', width: '100%'}}>
                      <AutoSizer>
                          {({ height, width }) => (
                            <XYPlot

                              margin={{bottom: 40, left: 100, right: 100}}
                              yType="ordinal" width={width} height={300}
                              colorType="linear"
                              colorRange={this.parseColorRange()}
                              colorDomain={this.parseColorDomain()}
                            >
                                {/*<VerticalGridLines/>*/}
                                {/*<HorizontalGridLines/>*/}
                                <YAxis/>
                                  <HorizontalBarSeries
                                      className="bar-property-type-split"
                                      barWidth={0.5}
                                      data={data.reverse().map((i, index) => {return {y: i.platform, x: i.count, label: i.count, color: [index]}})}
                                      onValueClick={value => this.onBarValueClick(value)}
                                  />

                                <LabelSeries
                                  labelAnchorY="middle"
                                  yOffset={200}
                                  labelAnchorX='middle-alignment'
                                  data={data.reverse().map(i => {
                                      return {
                                          y: i.platform,
                                          x: i.count,
                                          label: `${formatNumberAsIntl(i.count)}`, // Needs to be a string
                                          style: {fontSize: '14px'},
                                          xOffset: 5,
                                          yOffset: 2,
                                      }
                                  })}
                                />
                            </XYPlot>
                          )}
                      </AutoSizer>
                  </div>

              </div>
          </div>
        );
    }
}

export default BarChart;