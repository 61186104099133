import React, { Fragment } from 'react';

export default function FeatureBox(props) {

    return (
      <Fragment>
          <div className="col-6">
              <div className="form-group">
                  <div className="custom-control custom-checkbox small">
                      <input type="checkbox" checked={props.feature.has_feature} onChange={(e) => props.handleBoxChange(e, props.feature.id)} className="mr-2 custom-control-input" id={props.feature.id} />
                      <label className="custom-control-label" htmlFor={props.feature.id}>{props.feature.short_code}</label>
                      <p>({props.feature.scores !== null && props.feature.scores[0].toFixed(2) + " %" || "N/A"})</p>
                  </div>
              </div>
          </div>
      </Fragment>
    )
}

