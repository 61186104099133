import React from 'react';

const SearchListHeaderMenu = (props) => {

    return (
        <>
            <div className="col-8">
                <div className="d-flex align-items-center">
                    <div className="custom-control custom-control-inline custom-radio">
                        <input onChange={props.onSearchTypeChange} checked={props.searchType === 'title'} type="radio"
                               className="custom-control-input" value="title" name="radio-stacked" id="title"/>
                        <label className="custom-control-label" htmlFor="title">Title</label>
                    </div>
                    <div className="custom-control custom-radio mr-3">
                        <input onChange={props.onSearchTypeChange} checked={props.searchType === 'keywords'}
                               type="radio" className="custom-control-input" value="keywords" name="radio-stacked"
                               id="keywords"/>
                        <label className="custom-control-label" htmlFor="keywords">Keywords</label>
                    </div>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="">Find in list</span>
                        </div>
                        <input onChange={e => props.handleOnFilterTextChange(e.target.value)} value={props.value}
                               type="text" className="form-control form-control-user"/>
                    </div>
                </div>
            </div>
            <div className="col-4 text-right">
                <button onClick={() => props.toggleSearchTermModal(true)}
                        className="btn btn-success btn-icon-split">
                                                  <span className="icon text-white-50">
                                                    <i className="fas fa-search"></i>
                                                  </span>
                    <span className="text">New search</span>
                </button>
            </div>
        </>
    )
};

export default SearchListHeaderMenu;