import prepHeaders from '../../utils/prepHeaders';
import { setImageForSidePanel, unsetImageForSidePanel, setImageForSidePanelInShortlist, unsetImageForSidePanelInShortlist } from "../images/imagesAction";
import axios from "axios";

export const OPEN = "sidepanel:open";
export const CLOSE = "sidepanel:close";
export const OPEN_SHORTLIST = 'sidepanel:openShortlist';
export const CLOSE_SHORTLIST ='sidepanel:closeShortlist';

export function doOpenAndSetImage(image) {
    return dispatch => {
        dispatch(setImageForSidePanel(image))
        dispatch(open())
    }
}

export function doCloseAndUnsetImage() {
    return dispatch => {
        dispatch(close())
        dispatch(unsetImageForSidePanel())
    }
}

export function doOpenAndSetImageForShortlist(image) {
    return dispatch => {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/search-result/${image.search_result.id}`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setImageForSidePanelInShortlist(response.data))
            dispatch(openForShortlist())
        }).catch(error => {
            console.log(error)
        })
    }
}

export function doCloseAndUnsetImageForShortlist() {
    return dispatch => {
        dispatch(closeForShortlist())
        dispatch(unsetImageForSidePanelInShortlist())
    }
}

export function open() {
    return {
        type: OPEN,
        payload: {
            status: true
        }
    }
}

export function close() {
    return {
        type: CLOSE,
        payload: {
            status: false
        }
    }
}

export function openForShortlist() {
    return {
        type: OPEN_SHORTLIST,
        payload: {
            status: true
        }
    }
}

export function closeForShortlist() {
    return {
        type: CLOSE_SHORTLIST,
        payload: {
            status: false
        }
    }
}


