import React, { Component, Fragment } from 'react';
import FeatureBox from "./FeatureBox";
import AmazonLogo from '../assets/images/amazon.png';
import GoogleLogo from '../assets/images/google.png';
import EbayLogo from '../assets/images/ebay.png';
import InstagramLogo from '../assets/images/instagram.png';

class Image extends Component {

    // setIsVisibility = () => {
    //     if(this.props.canBeFilteredByPercentMatch) {
    //         if (this.props.image.search_result.model_score === null) {
    //             return ""
    //         }
    //
    //         let score = this.props.image.search_result.model_score.toFixed(0)
    //         if (parseInt(score) >= parseInt(this.props.selectedPercentMatch)) return ""
    //
    //         return "d-none"
    //     }
    // };

    handleImageSelect = () => {
        let result = this.props.selectedImages.filter(image =>{
            return image.search_result.search_hash === this.props.image.search_result.search_hash
        });
        if (result.length === 0 ) {
            this.props.select(this.props.image);
        } else {
            let index = this.props.selectedImages.findIndex(image => {
                return image.search_result.search_hash === this.props.image.search_result.search_hash
            });
            this.props.deselect(index);
        }
    };

    handleSelectedCue = () => {
        let result = this.props.selectedImages.filter(image =>{
            return image.search_result.search_hash === this.props.image.search_result.search_hash
        });
        if (result.length > 0 ) {
            return "selected"
        }
        return ""
    };

    getLogoBasedOnProvider = () => {
        if (this.props.image.search_result.search_provider === 'google') {
            return GoogleLogo;
        }

        if (this.props.image.search_result.search_provider === 'amazon') {
            return AmazonLogo
        }

        if (this.props.image.search_result.search_provider === 'ebay' || this.props.image.search_result.search_provider === 'ebay_com' ) {
            return EbayLogo
        }

        if (this.props.image.search_result.search_provider === 'instagram') {
            return InstagramLogo
        }

        return ''
    }

    setLabelBasedOnAnalysisStatus = () => {

        switch (this.props.image.search_result.isAnalyzed) {
            case 0:
                return <div className="scrape-badge badge badge-warning">Not scraped</div>
            case 1:
                return <div className="scrape-badge badge badge-success">Scraped</div>
            case 2:
                return <div className="scrape-badge badge badge-info">In queue</div>
            case 3:
                return <div className=" scrape-badge badge badge-danger">Processing</div>
            default:
                return null

        }
    };

    handleBoxChange = (e, featureId) => {
        this.props.fetchUpdatedFeatures(e, featureId, this.props.image.search_result.search_hash)
    }


    handleInfoButtonClick = (e) => {
        e.stopPropagation()
        this.props.togglePanel(this.props.image)
    }

    render() {
        return (
            <Fragment>
                <div className={`col-sm-4 col-md-4 col-lg-3 col-xl-2 `}>
                    <div className={`card search-card mb-4 ${this.handleSelectedCue()}`}>
                            {/*<img onError={e => this.onImageError(e)} className="d-none" src={this.props.image.search_result.image_url}/>*/}
                            <div onClick={() => {this.handleImageSelect()}} className="image-wrapper" style ={ { backgroundImage: "url("+this.props.image.search_result.image_url+")" } }>
                            {this.setLabelBasedOnAnalysisStatus()}
                                {this.props.hasInfoPanel &&
                                <button onClick={(e) => this.handleInfoButtonClick(e)}
                                        className="btn btn-primary image-info-btn" type="button">Info</button>
                                }
                                {this.props.image.isBeingClassified &&
                                    <Fragment>
                                        <i className="fas fa-circle-notch fa-spin image-analyze-icon"></i>
                                        <div className="image-overlay"></div>
                                    </Fragment>
                                }
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    {this.props.project.has_dupe_value &&
                                    <div className="row">
                                        <div className="col-7">
                                            <img className="img-fluid img-provider-logo" width="15px" height="auto" src={this.getLogoBasedOnProvider()} alt="Provider logo" />
                                            <span className="image-label">Match</span>
                                        </div>
                                        <div className="col-5 text-right">
                                            <p className="image-score">{this.props.image.search_result.model_score !== null ? this.props.image.search_result.model_score.toFixed(0) : "N/A"} %</p>
                                        </div>
                                    </div>
                                    }
                                    <div className="row">
                                        <div className="col-12">
                                            <small>ID: {this.props.image.search_result.id}</small>
                                            <br />
                                            <small><a target="_blank" rel="noopener" href={this.props.image.search_result.image_source}>{this.props.image.search_result.image_source.slice(0, 30)}</a></small>
                                                <br />
                                            {/*<small>Price: {this.props.image.search_result.currency_and_price || "N/A"}</small>*/}
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row">
                                        {typeof this.props.image.search_result.features !== "undefined" && this.props.image.search_result.features.length > 0 && // Don't show if project doesn't have feature
                                            this.props.image.search_result.features.map((feature) => {
                                                return <FeatureBox handleBoxChange={this.handleBoxChange} key={feature.id} feature={feature} />
                                            })
                                        }
                                    </div>
                                </li>
                            </ul>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Image;
