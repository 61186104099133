import React, { Component } from 'react';

class TabNav extends Component {

    handleShowNavTab = () => {
        if(this.props.selectedTab === this.props.tabNavId) {
            return 'active';
        }

        return ""
    }

    render() {
        return (
            <li className="nav-item">
                <a onClick={() => this.props.setSelectedTab(this.props.tabNavId)} className={`nav-link ${this.handleShowNavTab()}`}>{this.props.tabNavName}</a>
            </li>
        )
    }
}

export default TabNav;