import React, {Component} from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import SingleViewMenu from '../menu/SingleViewMenu';

class SvsFilterPanel extends Component {

    render() {
        return (
          <SlidingPanel
            type={'right'}
            isOpen={this.props.isOpen}
            size={48}
            backdropClicked={this.props.onBackdropClick}
          >
              <div className="panel-container">
                  <div className="panel-header">
                      <p>Filters</p>
                  </div>
                  <div className="panel-body">
                      <SingleViewMenu
                        handleInOpenChange={this.props.handleInOpenChange}
                      />
                  </div>
              </div>
          </SlidingPanel>
        )
    }
}

export default SvsFilterPanel