import prepHeaders from '../../utils/prepHeaders';
import { setShortlistImages, fetchingImages } from "../shortlist/shortlist";
import { setSelectedForShortlist } from "../images/imagesAction";
import {SET_ADDITIONAL_INFORMATION_SEARCH} from "../singleViewMenu/singleViewMenuActions";
import axios from "axios";

export const TAB_FILTER_ALL = 'filter:tab:all';
export const TAB_FILTER_GOOGLE = 'filter:tab:google';
export const TAB_FILTER_AMAZON = 'filter:tab:amazon';
export const TAB_FILTER_EBAY = 'filter:tab:ebay';
export const TAB_FILTER_INSTAGRAM = 'filter:tab:instagram';
export const SET_SELECTED_TAB = 'filter:selectedTab';
export const SET_FILTER_STATUS = 'filter:setStatus';
export const SET_REMOTE_FILTER_STATUS = 'filter:setRemoteStatus'
export const SET_HOST = 'filter:setHost';
export const SET_TEXT_SEARCH = 'filter:textSearch';
export const SET_NOTES_SEARCH = 'filter:notesSearch';
export const SET_FILTER_PROPERTY_TYPE = 'filter:setPropertyTypeFilter';
export const SET_FILTER_ANALYZED = 'filter:setAnalyzedFilter';
export const SET_FILTER_DK_SELLER_RATING = 'filter:setDkSellerRating';
export const SET_FILTER_FEATURES = 'filter:setSetFeatures';
export const SET_FILTER_CURRENT_PAGE = 'filter:setCurrentPage';
export const SET_FILTER_TOTAL_ITEMS = 'filter:setTotalItems';
export const SET_FILTER_ITEMS_COUNT_PER_PAGE = 'filter:setItemsCountPerPage';
export const SET_FILTER_SEARCHERS = 'filter:setSearchers';
export const SET_FILTER_SEARCHER = 'filter:setSearcher';
export const SET_CREATED_FROM_DATE = 'filter:setCreatedFromDate';
export const SET_CREATED_TO_DATE = 'filter:setCreatedToDate';
export const SET_SEARCHER_FROM_DATE = 'filter:setSearcherFromDate';
export const SET_SEARCHER_TO_DATE = 'filter:setSearcherToDate';
export const SET_IS_DATE_PRESENTED_TO_CLIENT = 'filter:setIsDatePresentedToClient'
export const SET_DATE_PRESENTED_TO_CLIENT_START = 'filter:setDatePresentedToClientStart'
export const SET_DATE_PRESENTED_TO_CLIENT_END = 'filter:setDatePresentedToClientEnd'

// SORTING
export const SET_SORT = 'filter:setSort'
export const RESET_ALL = 'filter:resetAll'

export function setSelectedTab(tab) {
    return {
        type: SET_SELECTED_TAB,
        payload: {
            tab
        }
    }
}

export function setStatusFilter(value) {
    return {
        type: SET_FILTER_STATUS,
        payload: {
            value
        }
    }
}

export const setRemoteStatus = (remoteStatusValue) => {
    return {
        type: SET_REMOTE_FILTER_STATUS,
        payload: {
            remoteStatusValue
        }
    }
}

export function setHost(value) {
    return {
        type: SET_HOST,
        payload: {
            value
        }
    }
}

export function setTextSearch(value) {
    return {
        type: SET_TEXT_SEARCH,
        payload: {
            value
        }
    }
}

export function setNotesSearch(value) {
    return {
        type: SET_NOTES_SEARCH,
        payload: {
            value
        }
    }
}
export function setAdditionalInformationSearch(value) {
    return {
        type: SET_ADDITIONAL_INFORMATION_SEARCH,
        payload: {
            value
        }
    }
}
export function setPropertyTypeFilter(value) {
    return {
        type: SET_FILTER_PROPERTY_TYPE,
        payload: {
            value
        }
    }
}

export function setAnalyzedFilter(value) {
    return {
        type: SET_FILTER_ANALYZED,
        payload: {
            value
        }
    }
}

export function setDkSellerRating(value) {
    return {
        type: SET_FILTER_DK_SELLER_RATING,
        payload: {
            value
        }
    }
}

export function setFeatures(value) {
    return {
        type: SET_FILTER_FEATURES,
        payload: {
            value
        }
    }
}

export function setCurrentPage(currentPageValue) {
    return {
        type: SET_FILTER_CURRENT_PAGE,
        payload: {
            currentPageValue
        }
    }
}

export function setTotalItems(totalItems) {
    return {
        type: SET_FILTER_TOTAL_ITEMS,
        payload: {
            totalItems
        }
    }
}

export function setItemsCountPerPage(itemsCountPerPage) {
    return {
        type: SET_FILTER_ITEMS_COUNT_PER_PAGE,
        payload: {
            itemsCountPerPage
        }
    }
}

export const setSearchers = (searchers) => {
    return {
        type: SET_FILTER_SEARCHERS,
        payload: {
            searchers
        }
    }
}

export const selectedSearcher = (searcher) => {
    return {
        type: SET_FILTER_SEARCHER,
        payload: {
            searcher
        }
    }
}

export function setCreatedStartDate(startDate) {
    return {
        type: SET_CREATED_FROM_DATE,
        payload: {
            startDate,
        }
    }
}

export function setCreatedEndDate(endDate) {
    return {
        type: SET_CREATED_TO_DATE,
        payload: {
            endDate,
        }
    }
}

export function setSearcherStartDate(startDate) {
    return {
        type: SET_SEARCHER_FROM_DATE,
        payload: {
            startDate,
        }
    }
}

export function setSearcherEndDate(endDate) {
    return {
        type: SET_SEARCHER_TO_DATE,
        payload: {
            endDate,
        }
    }
}

export const setIsDatePresentedToClient = status => {
    return {
        type: SET_IS_DATE_PRESENTED_TO_CLIENT,
        payload: {
            status
        }
    }
}

export const setDatePresentedToClientStart = startDate => {
    return {
        type: SET_DATE_PRESENTED_TO_CLIENT_START,
        payload: {
            startDate
        }
    }
}

export const setDatePresentedToClientEnd = endDate => {
    return {
        type: SET_DATE_PRESENTED_TO_CLIENT_END,
        payload: {
            endDate
        }
    }
}

// ------------------------------- SORTING ----------------------------------------

export function setSort(sortOrder) {
    return {
        type: SET_SORT,
        payload: {
            sortOrder
        }
    }
}

export function resetAll() {
    return {
        type: RESET_ALL,
    }
}

export function fetchShortlistBasedOnFilters(projectId, filters, selectedImages) {
    return dispatch => {
        dispatch(fetchingImages(true));

        let payload = {
            project: projectId,
            filters
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search-result/all",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            let imageData = response.data.search_results.map((image, index) => {
                return {
                    search_result: {...image},
                    temp_id: index,
                    isBeingClassified: false,
                    isBeingAnalyzed: false,
                }
            });
            dispatch(fetchingImages(false));
            dispatch(setTotalItems(response.data.total));
            dispatch(setShortlistImages(imageData));
            dispatch(setSelectedForShortlist([...selectedImages]));
        }).catch(error => {
            dispatch(fetchingImages(false));
            console.log(error)
        })
    }
}

export const fetchSearchers = () => {
    return dispatch => {

        let projectId = JSON.parse(localStorage.getItem('project')).id

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/user/project/${projectId}`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setSearchers(response.data))
        }).catch(error => {
            console.log(error)
        })

    }
}
