import React, {Component} from "react";
import Modal from "react-bootstrap4-modal";
import getPropertyTypeFilterOptions from "../../utils/filters/propertyTypeFilterOptions";
import Select from "react-select";
import {parse} from 'tldts';

class AddNewDomainModal extends Component {

    constructor(props) {
        super(props);

        this.state = this.getInitialState()
    }

    getInitialState = () => {
        return {
            domain: '',
            domainOnly: '',
            propertyType: getPropertyTypeFilterOptions()[0],
            emv: '',
            countries: '',
            region: '',
            audience: '',
            domainError: false
        }
    }

    handleOnBlur = (e) => {
        let result = parse(e.target.value)

        if (result.domain) {
            this.setState({
                domainOnly: result.domain,
                domainError: false
            })
        } else {
            this.setState({domainError: true})
        }
    }

    handleSubmit = () => {
        this.props.addNewDomainMeta(this.state)
        this.props.toggle()
        this.setState(this.getInitialState())
    }

    render() {
        return (
          <Modal dialogClassName="modal-lg" className="dk-modal" visible={this.props.showModal} onClickBackdrop={() => this.props.toggle()}>
              <div className="modal-header">
                  <h5 className="modal-title">Add new Domain</h5>
              </div>
              <div className="modal-body">
                  <div className="row">
                      <div className="col-6 mb-3">
                          <label>Domain</label>
                          <input
                            onChange={(e) => this.setState({domain: e.target.value})}
                            onBlur={e => this.handleOnBlur(e)} value={this.state.domain}
                            type="text"
                            className={this.state.domainError ? 'form-control is-invalid' : 'form-control'}
                            placeholder="www.dupekiller.ai"/>
                          {this.state.domainError && <small className="invalid-feedback">Not a valid domain</small> }
                      </div>
                      <div className="col-6 mb-3">
                          <label>Domain only</label>
                          <input onChange={(e) => this.setState({domain: e.target.value})} value={this.state.domainOnly} type="text" className="form-control" placeholder="dupekiller.ai" disabled/>
                      </div>
                      <div className="form-group col-6">
                          <label>Property type</label>
                          <Select
                            options={getPropertyTypeFilterOptions()}
                            value={this.state.propertyType}
                            placeholder="Property type"
                            onChange={(option) => this.setState({propertyType: option})}
                          />
                      </div>

                      <div className="form-group col-6">
                          <label>Monthly Visits (Est.)</label>
                          <input onChange={(e) => this.setState({emv: e.target.value})} value={this.state.emv} type="number" className="form-control" placeholder="3200125"/>
                      </div>

                      <div className="form-group col-12">
                              <label>Shipping</label>
                              <textarea
                                className="form-control"
                                rows={5}
                                cols={20}
                                value={this.state.countries}
                                name="countries"
                                onChange={(e) => this.setState({countries: e.target.value})}
                              />
                      </div>

                      <div className="form-group col-12">
                          <label>Shipping region</label>
                          <textarea
                            className="form-control"
                            rows={5}
                            cols={20}
                            value={this.state.region}
                            name="region"
                            onChange={(e) => this.setState({region: e.target.value})}
                          />
                      </div>

                      <div className="form-group col-12">
                          <label>Audience</label>
                          <textarea
                            className="form-control"
                            rows={5}
                            cols={20}
                            value={this.state.audience}
                            name="audience"
                            onChange={(e) => this.setState({audience: e.target.value})}
                          />
                      </div>
                  </div>
              </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.props.toggle()}>
                      Cancel
                  </button>
                  <button disabled={this.state.domainError} type="button" className="btn btn-primary btn-sm" onClick={() => {this.handleSubmit()}}>
                      {this.props.posting ? <i className="fas fa-circle-notch fa-spin text-white"></i> : 'Add'}
                  </button>
              </div>
          </Modal>
        )
    }
}

export default AddNewDomainModal;