import {
    SET_IMAGES,
    FETCHING_IMAGES,
    SET_IMAGE_FOR_SIDE_PANEL,
    UNSET_IMAGE_FOR_SIDE_PANEL,
    SELECTED,
    DESELECT,
    SET_SELECTED_IMAGES,
    SELECTED_SHORTLIST,
    DESELECT_SHORTLIST,
    SET_SELECTED_FOR_SHORTLIST,
    SET_IMAGE_FOR_SIDE_PANEL_IN_SHORTLIST,
    UNSET_IMAGE_FOR_SIDE_PANEL_IN_SHORTLIST
} from "../actions/images/imagesAction";

const initialState = {
    images: [],
    fetching: false,
    imageForSidePanel: null,
    imageForSidePanelInShortlist: null,
    selected: [],
    selectedShortlist: []
};

export default function imagesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_IMAGES:
            return {
                ...state,
                fetching: action.payload.fetching
            };
        case SET_IMAGES:
            return {
                ...state,
                images: action.payload.images
            };
        case SET_IMAGE_FOR_SIDE_PANEL:
            return {
                ...state,
                imageForSidePanel: action.payload.image
            };
        case UNSET_IMAGE_FOR_SIDE_PANEL:
            return {
                ...state,
                imageForSidePanel: action.payload.image
            };
        case SET_IMAGE_FOR_SIDE_PANEL_IN_SHORTLIST:
            return {
                ...state,
                imageForSidePanelInShortlist: action.payload.image
            };
        case UNSET_IMAGE_FOR_SIDE_PANEL_IN_SHORTLIST:
            return {
                ...state,
                imageForSidePanelInShortlist: action.payload.image
            };
        case SELECTED:
            let copyOfSelectedToPush = [...state.selected];

            copyOfSelectedToPush.push(action.payload.image);

            return {
                ...state,
                selected: copyOfSelectedToPush
            };
        case DESELECT:
            let copyOfSelectedToSlice = [...state.selected];

            copyOfSelectedToSlice.splice(action.payload.index, 1);

            return {
                ...state,
                selected: copyOfSelectedToSlice
            };
        case SELECTED_SHORTLIST:
            let copyOfSelectedToPushFromShortlist = [...state.selectedShortlist];

            copyOfSelectedToPushFromShortlist.push(action.payload.shortlistImage);

            return {
                ...state,
                selectedShortlist: copyOfSelectedToPushFromShortlist
            };
        case DESELECT_SHORTLIST:
            let copyOfSelectedToSliceFromShortlist = [...state.selectedShortlist];

            copyOfSelectedToSliceFromShortlist.splice(action.payload.shortlistIndex, 1);

            return {
                ...state,
                selectedShortlist: copyOfSelectedToSliceFromShortlist
            };
        case SET_SELECTED_IMAGES:
            return {
                ...state,
                selected: action.payload.images
            };
        case SET_SELECTED_FOR_SHORTLIST:
            return {
                ...state,
                selectedShortlist: action.payload.images
            };
        default:
            return state
    }
}
