import React from 'react';

const ListItemQuery = ({row}) => (
    <div style={{color: 'grey', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses'}}>
      <p style={{marginBottom: 0}}>Keywords: {row.query.keywords.join(' ') || 'N/A'}</p>
      <p style={{marginBottom: 0}}>Exact expression: {row.query.exact_expression.join(' ') || 'N/A'}</p>
      <p style={{marginBottom: 0}}>Alternative: {row.query.alternative.join(' ') || 'N/A'}</p>
      <p style={{marginBottom: 0}}>Excluded: {row.query.omit.join(' ') || 'N/A'}</p>
      <p style={{marginBottom: 0}}>Limit: {row.query.limit}</p>
    </div>
);

export default ListItemQuery
