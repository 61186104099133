import axios from "axios";
import prepHeaders from "../../utils/prepHeaders";
import moment from "moment/moment";
import download from "downloadjs";

export const FETCHING = "imgDownload:fetching";
export const DOWNLOADING = "imgDownload:downloading";
export const SET_DOWNLOAD_LIST = 'imgDownload:setDownloadList';

export const setFetching = (status) => {
    return {
        type: FETCHING,
        payload: {
            status
        }
    }
}

export const setDownloading = status => {
    return {
        type: DOWNLOADING,
        payload: {
            status
        }
    }
}

export const setDownloadList = list => {
    return {
        type: SET_DOWNLOAD_LIST,
        payload: {
            list
        }
    }
}

export const fetchDownloadList = () => {
    return dispatch => {

        dispatch(setFetching(true))

        let project_id = JSON.parse(localStorage.getItem('project')).id

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/images-download/${project_id}/download/list`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setDownloadList(response.data))
            dispatch(setFetching(false))
        }).catch(error => {
            dispatch(setFetching(false))
            console.log(error)
        })
    }
}

export const createDownloadEntry = (status, title) => {

    let payload = {
        project_id: JSON.parse(localStorage.getItem('project')).id,
        status,
        title
    }

    return dispatch => {

        dispatch(setDownloading(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/images-download/download/by-status/create`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            console.log(response)
            dispatch(setDownloading(false))
        }).catch(error => {
            dispatch(setDownloading(false))
            console.log(error)
        })
    }
}

export const downloadImages = payload => {

    return dispatch => {

        dispatch(setDownloading(true));

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/images-download/download`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload,
            responseType: 'blob'
        }).then(response => {
            let fileName = `images_${moment().format('YYYY-MM-DD_H:m')}.zip`;
            download(response.data, fileName, "text/plain");
            dispatch(setDownloading(false));
        }).catch(error => {
            dispatch(setDownloading(false));
            console.log(error)
        })
    }
}