import prepHeaders from '../../utils/prepHeaders';
import { setSelectedImages, setImages, setSelectedForShortlist } from "../images/imagesAction";
import { setShortlistImages } from "../shortlist/shortlist";
import axios from "axios";
import {fetchProjectStats} from "../clientAndProjectList/clientAndProjectListAction";

export const SELECT_STATUS = "status:select";

export function doSelectStatus(selectedStatus) {
    return {
        type: SELECT_STATUS,
        payload: {
            selectedStatus
        }
    }
}

export function doMark(selectedImages, status, initialSearchImages, searchTermId, projectId) {
    return dispatch => {

        let payload = {
            search_results: selectedImages,
            status,
            search: searchTermId
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search-result/status",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            let copyOfInitialSearchImages = [...initialSearchImages];

            let resultHashes = response.data.map(responseImage => {
                return responseImage.search_hash
            })

            let filteredImages = copyOfInitialSearchImages.filter((initialSearchImage) => {
                return (!resultHashes.includes(initialSearchImage.search_result.search_hash))
            });
            dispatch(setImages(filteredImages));
            dispatch(setSelectedImages())
            dispatch(fetchProjectStats(projectId));

        }).catch(error => {
            console.log(error)
        })
    }
}

export function doMarkForShortlist(selectedImages, status, initialShortlistImages, searchTermId = null, projectId) {
    return dispatch => {

        let payload = {
            search_results: selectedImages,
            status
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search-result/status",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            let copyOfInitialShortlistImages = [...initialShortlistImages];

            let resultHashes = response.data.map(responseImage => {
                return responseImage.search_hash
            })

            let filteredImages = copyOfInitialShortlistImages.filter((initialShortlistImage) => {
                return (!resultHashes.includes(initialShortlistImage.search_result.search_hash))
            });

            dispatch(setShortlistImages(filteredImages));
            dispatch(setSelectedForShortlist())
            dispatch(fetchProjectStats(projectId));

        }).catch(error => {
            console.log(error)
        })
    }
}
