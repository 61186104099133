import {
    SET_FETCHING_IMPORT_PROJECTS,
    SET_IMPORT_PROJECTS,
    SET_IMPORT_PROJECT,
    UPDATE_IMPORT_PROJECT_CSV_BUTTON_STATUS
} from '../actions/importProject/importProjectAction'
import {ACTION_BUTTON_STATE} from "../utils/enums/ActionButtonState";

const initialState = {
    fetchingImportProjects: false,
    importProjects: [],
    importProject: null,
    importProjectCsvButtonState: ACTION_BUTTON_STATE.DEFAULT,
}

export default function importProjectReducer(state = initialState, action)
{
    switch(action.type) {

        case SET_FETCHING_IMPORT_PROJECTS:
            return {
                ...state,
                fetchingImportProjects: action.payload.status
            };
        case SET_IMPORT_PROJECTS:
            return {
                ...state,
                importProjects: action.payload.importProjects
            }
        case SET_IMPORT_PROJECT:
            return {
                ...state,
                importProject: action.payload.importProject
            }
        case UPDATE_IMPORT_PROJECT_CSV_BUTTON_STATUS:
            return {
                ...state,
                importProjectCsvButtonState: action.payload.importProjectCsvButtonState
            }
        default: return state
    }
}