import {SET_CONFIRMATION_MODAL_STATUS, SET_CONFIRMATION_MODAL_STATUS_IN_SEARCH_VIEW} from "../actions/modal/modalActions";

export const initialState = {
    confirmationModalStatus: false,
    confirmationModalStatusSearchView: false
};

export default function shortlistReducer(state = initialState, action) {
    switch (action.type) {

        case SET_CONFIRMATION_MODAL_STATUS:
            return {
                ...state,
                confirmationModalStatus: action.payload.status
            };

        case SET_CONFIRMATION_MODAL_STATUS_IN_SEARCH_VIEW:
            return {
                ...state,
                confirmationModalStatusSearchView: action.payload.status
            };

        default:
            return state
    }
}
