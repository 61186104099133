import React, {Component} from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar";
import {doFetchSummaryReport} from "../../actions/summary/summary";
import {connect} from "react-redux";
import {compose} from "redux";

import BarChart from "./summaryComponents/BarChart"
import ExecutiveSummary from "./summaryComponents/ExecutiveSummary";
import WordCloud from "./summaryComponents/WordCloud";
import TopWebsitesFeaturingDupesByDupesCount from "./summaryComponents/TopWebsitesFeaturingDupesByDupesCount";
import SignificantGeographies from "./summaryComponents/SignificantGeographies";
import PropertyTypeSplit from "./summaryComponents/PropertyTypeSplit";
import TopWebsitesFeaturingDupesByEmv from "./summaryComponents/TopWebsitesFeaturingDupesByEmv";
import {Link, withRouter} from "react-router-dom";
import {
    resetAll,
    setDatePresentedToClientEnd,
    setDatePresentedToClientStart,
    setHost,
    setIsDatePresentedToClient,
    setPropertyTypeFilter,
    setRemoteStatus
} from "../../actions/filter/filterAction";

class SummaryView extends Component {

    componentDidMount() {
        this.props.doFetchSummaryReport(this.props.match.params.summaryId)
    }

    render() {

        let route = `/project/${JSON.parse(localStorage.getItem('project')).id}/shortlist`

        return (
          <div id="page-top">
              <div id="wrapper">
                  <Sidebar/>
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content" className="summary-content">
                          <Topbar/>
                          {this.props.summary.summary &&
                          <div className="container-fluid">
                              <div className="row d-flex">
                                  <div className="col-12 mb-3">
                                      <ExecutiveSummary
                                        dupeCount={this.props.summary.summary.summary_data.dupe_count}
                                        scannedPages={this.props.summary.summary.summary_data.pages_scanned}
                                        summary={this.props.summary.summary}
                                        topWebsitesByCount={this.props.summary.summary.summary_data.highest_dupe_count_website_domains}
                                        resetFiltersToDefaul={this.props.resetFiltersToDefault}
                                        setRemoteStatus={this.props.setRemoteStatus}
                                        setIsDatePresentedToClient={this.props.setIsDatePresentedToClient}
                                        setDatePresentedToClientStart={this.props.setDatePresentedToClientStart}
                                        setDatePresentedToClientEnd={this.props.setDatePresentedToClientEnd}
                                        router={this.props.history}
                                      />
                                  </div>
                                  <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-3">
                                      <TopWebsitesFeaturingDupesByDupesCount
                                          data={this.props.summary.summary.summary_data.highest_dupe_count_website_domains}
                                          resetFiltersToDefaul={this.props.resetFiltersToDefault}
                                          setRemoteStatus={this.props.setRemoteStatus}
                                          setIsDatePresentedToClient={this.props.setIsDatePresentedToClient}
                                          setDatePresentedToClientStart={this.props.setDatePresentedToClientStart}
                                          setDatePresentedToClientEnd={this.props.setDatePresentedToClientEnd}
                                          router={this.props.history}
                                          summary={this.props.summary.summary}
                                          setHost={this.props.setHost}
                                      />
                                  </div>
                                  <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-3">
                                      <TopWebsitesFeaturingDupesByEmv
                                          data={this.props.summary.summary.summary_data.highest_traffic_website_domains}
                                          resetFiltersToDefaul={this.props.resetFiltersToDefault}
                                          setRemoteStatus={this.props.setRemoteStatus}
                                          setIsDatePresentedToClient={this.props.setIsDatePresentedToClient}
                                          setDatePresentedToClientStart={this.props.setDatePresentedToClientStart}
                                          setDatePresentedToClientEnd={this.props.setDatePresentedToClientEnd}
                                          router={this.props.history}
                                          summary={this.props.summary.summary}
                                          setHost={this.props.setHost}
                                      />
                                  </div>
                                  <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-3">
                                      <PropertyTypeSplit
                                        dupeCount={this.props.summary.summary.summary_data.dupe_count}
                                        propertyTypeDistribution={this.props.summary.summary.summary_data.property_type_split}
                                        resetFiltersToDefaul={this.props.resetFiltersToDefault}
                                        setRemoteStatus={this.props.setRemoteStatus}
                                        setIsDatePresentedToClient={this.props.setIsDatePresentedToClient}
                                        setDatePresentedToClientStart={this.props.setDatePresentedToClientStart}
                                        setDatePresentedToClientEnd={this.props.setDatePresentedToClientEnd}
                                        router={this.props.history}
                                        summary={this.props.summary.summary}
                                        setPropertyType={this.props.setPropertyType}
                                      />
                                  </div>
                                  <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6 mb-3">
                                      <BarChart
                                        data={this.props.summary.summary.summary_data.platform_split}
                                        dupeCount={this.props.summary.summary.summary_data.dupe_count}
                                        scannedPages={this.props.summary.summary.summary_data.pages_scanned}
                                        showWeb={true}
                                        cardTitle="Dupes split by platform"
                                        resetFiltersToDefaul={this.props.resetFiltersToDefault}
                                        setRemoteStatus={this.props.setRemoteStatus}
                                        setIsDatePresentedToClient={this.props.setIsDatePresentedToClient}
                                        setDatePresentedToClientStart={this.props.setDatePresentedToClientStart}
                                        setDatePresentedToClientEnd={this.props.setDatePresentedToClientEnd}
                                        router={this.props.history}
                                        summary={this.props.summary.summary}
                                        setPropertyType={this.props.setPropertyType}
                                        setHost={this.props.setHost}
                                      />
                                  </div>
                                  <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-12 mb-3">
                                      <SignificantGeographies
                                        data={this.props.summary.summary.summary_data.significant_geographies}
                                        dupeCount={this.props.summary.summary.summary_data.dupe_count}
                                      />
                                  </div>
                                  <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 mb-3">
                                      <WordCloud img={this.props.summary.summary.summary_data.wordcloud}/>
                                  </div>
                                  <div className="col-sm-12 text-center p-5 mb-4">
                                      <Link to={route}>
                                          <button className="btn btn-secondary">Go to Shortlist</button>
                                      </Link>
                                  </div>
                              </div>
                          </div>
                          }
                      </div>
                  </div>
              </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        summary: state.summary,
    }
};

const mapActionToProps = (dispatch) => {
    return {
        doFetchSummaryReport: (summaryId) => {
            dispatch(doFetchSummaryReport(summaryId))
        },
        resetFiltersToDefault: () => {
            dispatch(resetAll())
        },
        setHost: (host) => {
            dispatch(setHost(host))
        },
        setRemoteStatus: (remoteStatusValue) => {
            dispatch(setRemoteStatus(remoteStatusValue))
        },
        setPropertyType: propertyType => {
            dispatch(setPropertyTypeFilter(propertyType))
        },
        setIsDatePresentedToClient: status => {
            dispatch(setIsDatePresentedToClient(status))
        },
        setDatePresentedToClientStart: startDate => {
            dispatch(setDatePresentedToClientStart(startDate))
        },
        setDatePresentedToClientEnd: endDate => {
            dispatch(setDatePresentedToClientEnd(endDate))
        }
    }
};

export default compose(
    connect(mapStateToProps, mapActionToProps),
    withRouter
)(SummaryView)
