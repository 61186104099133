import React, {Component} from "react";
import {WorldMap} from "react-svg-worldmap"
import {formatNumberAsIntl} from "../../../utils/format";
import {DiscreteColorLegend} from "react-vis";

const COLORS =  ['#0065fe', '#005ae2', '#004fc5', '#0044a9', '#00388d', '#002d71']

export class SignificantGeographies extends Component {

    colorMapper = (context) => {
        return {
            fill: (context => {
                switch (true)
                {
                    case context.countryValue > this.props.dupeCount * 0.2:
                        return COLORS[0];
                    case context.countryValue > this.props.dupeCount * 0.11:
                        return COLORS[1]
                    case context.countryValue > this.props.dupeCount * 0.07:
                        return COLORS[2]
                    case context.countryValue > this.props.dupeCount * 0.05:
                        return COLORS[3]
                    case context.countryValue > this.props.dupeCount * 0.03:
                        return COLORS[4]
                    default:
                        return COLORS[5]
                }
            })(context),
            fillOpacity: 1,
            strokeWidth: 1,
            strokeOpacity: 0.2,
            cursor: "pointer"
        }
    }

    render() {

        return (
          <div className="card shadow">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Significant geographies</h6>
              </div>
              <div className="card-body significant-geographies">
                  <div className="row">
                      <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6">
                            <WorldMap styleFunction={this.colorMapper} size="responsive" data={this.props.data.filter(item => item.country_code !== null).map((item) => {
                                return {value: item.count, country: item.country_code}
                            })}/>
                              <DiscreteColorLegend
                                  colors={COLORS}
                                  orientation="horizontal"
                                  items={[{title: '20%', strokeWidth: 13}, {title: '11%', strokeWidth: 13}, {title: '7%', strokeWidth: 13}, {title: '5%', strokeWidth: 13}, {title: '3%', strokeWidth: 13}, {title: '2%', strokeWidth: 13}]}
                                  className='map-legend text-center'
                              />

                      </div>
                      <div className="col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-6">
                          <div className="table-responsive table-hover">
                              <table className="table">
                                  <thead>
                                  <tr>
                                      <th scope="col">Rank</th>
                                      <th scope="col">Website</th>
                                      <th scope="col">Count</th>
                                  </tr>
                                  </thead>

                                  <tbody>
                                  {this.props.data.filter(item => item.country_code !== null).map((item, index) => {
                                      return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.country}</td>
                                            <td>{formatNumberAsIntl(item.count)}</td>
                                        </tr>
                                      )
                                  })}
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

export default SignificantGeographies;