import prepHeaders from '../../utils/prepHeaders';
import axios from "axios";

export const SET_IDS_LIST = "singleViewShortlist:setIdsList";
export const SET_FETCHING_IDS = "singleViewShortlist:setFetchingIds";
export const SET_INDEX_IDS_FOR_NAVIGATION = "singleViewShortlist:setIndexIdsForNavigation";
export const SET_IDS_FOR_NAVIGATION = "singleViewShortlist:setIdsForNavigation";
export const SET_SINGLE_VIEW_IMAGE_DATA = "singleViewShortlist:setImageData";
export const SET_FETCHING_SINGLE_VIEW_IMAGE_DATA = "singleViewShortlist:setFetchingImageData";

export function setIdsList(idsList) {
    return {
        type: SET_IDS_LIST,
        payload: {
            idsList
        }
    }
}

export function setFetchingIds(status) {
    return {
        type: SET_FETCHING_IDS,
        payload: {
            status
        }
    }
}

export function setIndexIdsForNavigation(currentIdIndex, nextIdIndex, prevIdIndex) {
    return {
        type: SET_INDEX_IDS_FOR_NAVIGATION,
        payload: {
            currentIdIndex,
            nextIdIndex,
            prevIdIndex
        }
    }
}

export function setIdsForNavigation(currentId, nextId, prevId) {
    return {
        type: SET_IDS_FOR_NAVIGATION,
        payload: {
            currentId,
            nextId,
            prevId
        }
    }
}

export function setSingleViewImageData(imageData) {
    return {
        type: SET_SINGLE_VIEW_IMAGE_DATA,
        payload: {
            imageData
        }
    }
}

export function setFetchingSingleViewImageData(status) {
    return {
        type: SET_FETCHING_SINGLE_VIEW_IMAGE_DATA,
        payload: {
            status
        }
    }
}

export function fetchIdsListForSingleViewShortlist(filters) {
    return dispatch => {
        dispatch(setFetchingIds(true))

        let payload = {
            filters: filters,
            project_id: JSON.parse(localStorage.getItem('project')).id
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search-result/single-view-shortlist",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {

            dispatch(setIdsList(response.data))

            let currentIndex = 0
            let currentId = response.data[currentIndex];

            let nextIndex = currentIndex === response.data.length -1 ? null : currentIndex + 1;
            let nextId = response.data[nextIndex];

            let prevIndex = null
            let prevId = null

            dispatch(setIndexIdsForNavigation(currentIndex, nextIndex, prevIndex));
            dispatch(setIdsForNavigation(currentId, nextId, prevId));

            if(response.data.length > 0) {
                dispatch(fetchSingleViewImageData(currentId))
            }
            dispatch(setFetchingIds(false))

        }).catch(error => {
            dispatch(setFetchingIds(false))
            console.log(error)
        })
    }
}

export function fetchSingleViewImageData(searchResultId) {
    return dispatch => {

        dispatch(setFetchingSingleViewImageData(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/search-result/${searchResultId}`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setSingleViewImageData(response.data))
            dispatch(setFetchingSingleViewImageData(false))
        }).catch(error => {
            console.log(error)
            dispatch(setFetchingSingleViewImageData(false))
        })
    }
}
