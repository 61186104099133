import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Sidebar from "./Sidebar/Sidebar"
import Topbar from "./Topbar"
import Tab from './search/Tab';
import TabNav from "./search/TabNav";
import Panel from "./panel/Panel";
import Menu from "./menu/Menu";
import {doFetchModels, setSelectedMenuModel} from "../actions/models/modelsAction";
import {
    setSelectedTab,
    TAB_FILTER_ALL,
    TAB_FILTER_GOOGLE,
    TAB_FILTER_AMAZON,
    TAB_FILTER_EBAY,
    TAB_FILTER_INSTAGRAM,
}
    from "../actions/filter/filterAction";
import { doCloseAndUnsetImage, doOpenAndSetImage } from "../actions/sidepanel/sidepanelAction";
import { doSelectStatus, doMark } from "../actions/status/statusAction";
import { selectIsClassifyDisabled } from "../reducers/selectors/selectIsClassifyDisabled";
import { setMenuAction} from "../actions/menu/menuActions";
import { setSelectedImages, doSelect, doDeselect, doFetchSearchViewImages} from "../actions/images/imagesAction";
import Sticky from 'react-sticky-el';
import ConfirmationModal from "./modal/ConfirmationModal";
import {setConfirmationModalStatusInSearchView} from "../actions/modal/modalActions";

class SearchView extends Component {

    componentDidMount() {
        let projectId = JSON.parse(localStorage.getItem('project')).id;
        this.props.fetchModels(projectId)
        this.props.doFetchSearchViewImages(this.props.match.params.searchId)
    }

    render() {

        let hasImageForSlidePanel = !!this.props.images.imageForSidePanel;

        return (
          <div id="page-top">
              <div id="wrapper">
                  <Sidebar />
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content">
                          <Topbar />
                          <div className="container-fluid">
                              <Sticky className="sticky-container">
                              <div className="card shadow h-100">
                                  <div className="card-body">
                                      <div className="form-group row mb-0">
                                          <div className="col">
                                                  <Menu
                                                      actionOptions={[
                                                          {label: "Mark as", value: 'mark'},
                                                      ]}
                                                      setMenuAction={this.props.setMenuAction}
                                                      actionValue={this.props.menu.actionType}
                                                      statusOptions={this.props.status.statusOptions}
                                                      statusValue={this.props.status.selectedStatus}
                                                      setSelectedStatus={this.props.setSelectedStatus}
                                                      selectAllStatus={this.props.menu.selectAll}
                                                      setSelectedImages={this.props.setSelectedImages}
                                                      doSelect={this.props.doSelect}
                                                      images={this.props.images.images}
                                                      selectedTab={this.props.filter.selectedTab}
                                                      selectedImages={this.props.images.selected}
                                                      doMark={this.props.doMark}
                                                      doClassify={this.props.doClassify}
                                                      doAnalyze={this.props.doAnalyze}
                                                      selectedMenuModelValue={this.props.models.selectedMenuModelValue}
                                                      modelList={this.props.models.list}
                                                      setSelectedMenuModel={this.props.setSelectedMenuModel}
                                                      searchTermId={this.props.match.params.searchId}
                                                      confirmationModalStatus={this.props.modal.confirmationModalStatusSearchView}
                                                      setConfirmationModalModalStatus={this.props.setConfirmationModalStatusInSearchView}
                                                      projectId={JSON.parse(localStorage.getItem('project')).id}
                                                  />
                                      {/*<div className="form-group row">*/}
                                          {/*<label htmlFor="percentMatched" className="col-auto col-form-label">Filter percent*/}
                                              {/*match:</label>*/}
                                          {/*<div className="col-sm-2">*/}
                                              {/*<InputRange*/}
                                                {/*maxValue={100}*/}
                                                {/*minValue={0}*/}
                                                {/*value={() => console.log('Not implemented')}*/}
                                                {/*onChange={value => this.props.setSelectedPercentMatch(value)}*/}
                                                {/*// disabled={this.props.images.images.length > 0}*/}
                                              {/*/>*/}
                                          {/*</div>*/}
                                      {/*</div>*/}
                                          </div>
                                          <div className="col-1 text-center my-auto">
                                              <h1>{this.props.images.selected.length}</h1>
                                              <button onClick={() => this.props.setSelectedImages()} className="btn btn-outline-info btn-sm ">Clear selected</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              </Sticky>

                              <section className="section search-preview-section mt-4">
                                          <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                              <TabNav
                                                setSelectedTab={this.props.setSelectedTab}
                                                tabNavId={TAB_FILTER_ALL}
                                                tabNavName="All"
                                                selectedTab={this.props.filter.selectedTab}
                                              />
                                              <TabNav
                                                setSelectedTab={this.props.setSelectedTab}
                                                tabNavId={TAB_FILTER_GOOGLE}
                                                tabNavName="Google"
                                                selectedTab={this.props.filter.selectedTab}
                                              />
                                              <TabNav
                                                setSelectedTab={this.props.setSelectedTab}
                                                tabNavId={TAB_FILTER_AMAZON}
                                                tabNavName="Amazon"
                                                selectedTab={this.props.filter.selectedTab}
                                              />
                                              <TabNav
                                                setSelectedTab={this.props.setSelectedTab}
                                                tabNavId={TAB_FILTER_EBAY}
                                                tabNavName="Ebay"
                                                selectedTab={this.props.filter.selectedTab}
                                              />
                                              <TabNav
                                                setSelectedTab={this.props.setSelectedTab}
                                                tabNavId={TAB_FILTER_INSTAGRAM}
                                                tabNavName="Instagram"
                                                selectedTab={this.props.filter.selectedTab}
                                              />
                                          </ul>

                                          <div className="tab-content" id="myTabContent">
                                              <Tab
                                                images={this.props.images.images}
                                                selectedTab={this.props.filter.selectedTab}
                                                tabId={TAB_FILTER_ALL}
                                                provider={['all']}
                                                selectedImages={this.props.images.selected}
                                                select={this.props.doSelect}
                                                deselect={this.props.doDeselect}
                                                selectedPercentMatch={() => console.log('Not implemented')}
                                                canBeFilteredByPercentMatch={false}
                                                togglePanel={this.props.togglePanel}

                                              />
                                              <Tab
                                                images={this.props.images.images}
                                                selectedTab={this.props.filter.selectedTab}
                                                tabId={TAB_FILTER_GOOGLE}
                                                provider={['google']}
                                                selectedImages={this.props.images.selected}
                                                select={this.props.doSelect}
                                                deselect={this.props.doDeselect}
                                                selectedPercentMatch={() => console.log('Not implemented')}
                                                canBeFilteredByPercentMatch={false}
                                                togglePanel={this.props.togglePanel}
                                              />
                                              <Tab
                                                images={this.props.images.images}
                                                selectedTab={this.props.filter.selectedTab}
                                                tabId={TAB_FILTER_AMAZON}
                                                provider={['amazon']}
                                                selectedImages={this.props.images.selected}
                                                select={this.props.doSelect}
                                                deselect={this.props.doDeselect}
                                                selectedPercentMatch={() => console.log('Not implemented')}
                                                canBeFilteredByPercentMatch={false}
                                                togglePanel={this.props.togglePanel}
                                              />
                                              <Tab
                                                images={this.props.images.images}
                                                selectedTab={this.props.filter.selectedTab}
                                                tabId={TAB_FILTER_EBAY}
                                                provider={['ebay', 'ebay_com']}
                                                selectedImages={this.props.images.selected}
                                                select={this.props.doSelect}
                                                deselect={this.props.doDeselect}
                                                selectedPercentMatch={() => console.log('Not implemented')}
                                                canBeFilteredByPercentMatch={false}
                                                togglePanel={this.props.togglePanel}
                                              />
                                              <Tab
                                                images={this.props.images.images}
                                                selectedTab={this.props.filter.selectedTab}
                                                tabId={TAB_FILTER_INSTAGRAM}
                                                provider={['instagram']}
                                                selectedImages={this.props.images.selected}
                                                select={this.props.doSelect}
                                                deselect={this.props.doDeselect}
                                                selectedPercentMatch={() => console.log('Not implemented')}
                                                canBeFilteredByPercentMatch={false}
                                                togglePanel={this.props.togglePanel}
                                              />
                                          </div>
                                      </section>
                          </div>
                                      <Panel
                                        isOpen={this.props.sidepanel.open}
                                        hasImage={hasImageForSlidePanel}
                                        closeSidePanel={this.props.closeSidePanel}
                                        image={this.props.images.imageForSidePanel ? this.props.images.imageForSidePanel.search_result : null}
                                        hasAnalysisFields={false}
                                      />
                                      <ConfirmationModal
                                        confirmationModalStatus={this.props.modal.confirmationModalStatusSearchView}
                                        setConfirmationModalModalStatus={this.props.setConfirmationModalStatusInSearchView}
                                        images={this.props.images.images}
                                        selectedImages={this.props.images.selected}
                                        doMark={this.props.doMark}
                                        statusValue={this.props.status.selectedStatus}
                                        projectId={JSON.parse(localStorage.getItem('project')).id}
                                        searchTermId={this.props.match.params.searchId}
                                      />
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        images: state.images,
        filter: state.filter,
        query: state.query,
        models: state.models,
        status: state.status,
        sidepanel: state.sidepanel,
        menu: state.menu,
        modal: state.modal,
        isClassifyDisabled: selectIsClassifyDisabled(state)
    }
};

const mapActionToProps = (dispatch) => {
    return {
        doFetchSearchViewImages: (searchTermId) => {dispatch(doFetchSearchViewImages(searchTermId))},
        fetchModels: (projectId) => {dispatch(doFetchModels(projectId))},
        setSelectedMenuModel: (selectedMenuModelValue) => {dispatch(setSelectedMenuModel(selectedMenuModelValue))},
        setSelectedTab: (tab) => {dispatch(setSelectedTab(tab))},
        closeSidePanel: () => {dispatch(doCloseAndUnsetImage())},
        setSelectedStatus: (value) => {dispatch(doSelectStatus(value))},
        doMark: (selectedImages, status, initialSearchImages, searchTermId, projectId) => {dispatch(doMark(selectedImages, status, initialSearchImages, searchTermId, projectId))},
        setMenuAction: (actionType) => {dispatch(setMenuAction(actionType))},
        setSelectedImages: (images) => {dispatch(setSelectedImages(images))},
        doSelect: (image) => {dispatch(doSelect(image))},
        doDeselect: (indexInSelectArray) => {dispatch(doDeselect(indexInSelectArray))},
        togglePanel: (image) => {dispatch(doOpenAndSetImage(image))},
        setConfirmationModalStatusInSearchView: (status) => {dispatch(setConfirmationModalStatusInSearchView(status))}
    }
};

export default connect(mapStateToProps, mapActionToProps)(SearchView)