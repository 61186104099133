export default function prepQuery(query) {

    let keywords = query.queryAllOFTheseWords;
    let exact_expression = query.queryTheseExactWords;
    let omit = query.queryNoneOfTheseWords;
    let alternative = query.queryAnyOfTheseWords;

    if (keywords) {
        keywords = query.queryAllOFTheseWords.split(',').map(element => element.trim())
    }

    if (exact_expression) {
        exact_expression = query.queryTheseExactWords.split(',').map(element => element.trim())
    }

    if (alternative) {
        alternative = query.queryAnyOfTheseWords.split(',').map(element => element.trim())
    }

    if (omit) {
        omit = query.queryNoneOfTheseWords.split(',').map(element => element.trim())
    }

    return {
        keywords: keywords || [],
        exact_expression: exact_expression || [],
        alternative: alternative || [],
        omit: omit || [],
        limit: query.limit
    }
}