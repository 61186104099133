import React, {Component, Fragment} from "react";
import SuperAdminSidebar from "../Sidebar/SuperAdminSidebar";
import Topbar from "../Topbar";
import {getImportProject, updateImportProjectCsvButtonStatus} from "../../actions/importProject/importProjectAction"
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {compose} from "redux";
import {ACTION_BUTTON_STATE} from "../../utils/enums/ActionButtonState";
import moment from "moment/moment";
import axios from "axios";

class ImportProject extends Component
{
    componentDidMount() {
        this.props.getImportProject(this.props.match.params.importProjectId)
        this.interval = setInterval(() => this.props.getImportProject(this.props.match.params.importProjectId), 5000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleFileUpload = (e) => {
        e.persist()

        let file = e.target.files[0]
        let formData = new FormData();
        formData.append("csv_file", file, file.name);

        this.props.updateCsvButtonStatus(ACTION_BUTTON_STATE.WORKING)

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/import-project/" + this.props.match.params.importProjectId +"/upload",
            method: 'POST',
            withCredentials: true,
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            data: formData
        }).then(() => {
            this.props.updateCsvButtonStatus(ACTION_BUTTON_STATE.DONE);

            setTimeout(() => {
                this.props.updateCsvButtonStatus(ACTION_BUTTON_STATE.DEFAULT)
            }, 2000)

            e.target.value = null
            // FETCH NEW DATA AFTER UPDATE, PASSING FETCH METHOD, CHECK DomainMeta.js
        }).catch(error => {
            console.log(error)
            this.props.updateCsvButtonStatus(ACTION_BUTTON_STATE.DEFAULT);
            e.target.value = null
        })
    }

    render() {
        return (
            <div id="page-top">
                <div id="wrapper">
                    <SuperAdminSidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs">
                            <Topbar withoutProjectStats={true} withoutBranding={true}/>
                            <div className="container">

                                {this.props.importProject &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-8">
                                            <h1 className="h3 mb-0 text-white-800">{this.props.importProject.display_name}</h1>
                                        </div>
                                        <div className="col-4 text-right">
                                            <label htmlFor="csvUploadButton" className="btn btn-info btn-icon-split mr-2">
                                                {this.props.importProjectCsvButtonState === ACTION_BUTTON_STATE.DEFAULT &&
                                                    <span className="icon text-white-50"><i className="fas fa-plus"></i></span>
                                                }
                                                {this.props.importProjectCsvButtonState === ACTION_BUTTON_STATE.DONE &&
                                                    <span className="icon text-white-50"><i className="fas fa-check"></i></span>
                                                }
                                                {this.props.importProjectCsvButtonState === ACTION_BUTTON_STATE.WORKING &&
                                                    <span className="icon text-white-50"><i className="fa fa-spinner fa-spin"></i></span>
                                                }
                                                <span className="text">Upload CSV Update <input className="d-none" type="file"/></span>
                                            </label>
                                            <input id="csvUploadButton" type="file" className="d-none"
                                                   onChange={(e) => this.handleFileUpload(e)}
                                            />
                                            <button onClick={(e) => this.props.getImportProject(this.props.match.params.importProjectId)} className="btn btn-outline-dark btn-icon-split mb-2">
                                                <span className="icon text-white-90"><i className="fas fa-sync"></i></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="h5 mb-4 text-gray-700">{this.props.importProject.description} </h5>
                                        </div>
                                    </div>

                                    {/*PROJECTS DETAILS*/}
                                    <div className="card p-3 mb-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="h4 font-weight-bold mt-2 mb-2">Projects</h4>
                                            </div>
                                        </div>
                                        {this.props.importProject.config.length > 0 &&
                                            this.props.importProject.config.map( config => {
                                                return (
                                                    <Fragment key={config.project.id}>
                                                        <hr/>
                                                        <p><span className="font-weight-bold text-monospace">Project: </span><NavLink to={"/project/" + config.project.id + "/dashboard"} className="btn btn-link mr-2">{config.project.display_name}</NavLink></p>
                                                        <p><span className="font-weight-bold text-monospace">Model used: </span> {config.model.display_name} ({config.model.auto_ml_model_id})</p>
                                                        <p>
                                                            <span className="font-weight-bold text-monospace">Thresholds: </span>
                                                            {((thresholds) => {
                                                                let formattedThresholds = ""
                                                                Object.keys(thresholds).forEach( (key) => {
                                                                    formattedThresholds = formattedThresholds + " " + key + ":" + thresholds[key].toString() + "  ";
                                                                })
                                                                return <span>{formattedThresholds}</span>
                                                            })(config.thresholds)}
                                                        </p>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        {this.props.importProject.config.length === 0 && <div className="row"><div className="col-12"><p>No target projects.</p></div></div>}
                                    </div>

                                    {/*PROJECTS PREV UPLOADS*/}
                                    <div className="card p-3 mb-4">
                                        <div className="row mt-4 mb-2">
                                            <div className="col-12">
                                                <h4 className="h4 mt-2 font-weight-bold">Uploads</h4>
                                            </div>
                                        </div>

                                        {this.props.importProject.uploads.length > 0 &&
                                            <Fragment>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <span className="font-weight-bold text-monospace">Created</span>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="font-weight-bold text-monospace">Process Start</span>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="font-weight-bold text-monospace">Process End</span>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="font-weight-bold text-monospace">Status</span>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        }
                                        {this.props.importProject.uploads.length > 0 &&
                                            this.props.importProject.uploads.map( upload => {
                                                return <Fragment key={upload.id}>
                                                    <hr/>
                                                    <div className="row">
                                                        <div className="col-12 font-weight-bold mb-2">{upload.display_name}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            {moment(upload.created).format('DD/MM/YYYY - LTS')}
                                                        </div>
                                                        <div className="col-3">
                                                            { upload.process_start !== null && (() => moment(upload.process_start).format('DD/MM/YYYY - LTS'))() }
                                                        </div>
                                                        <div className="col-3">
                                                            { upload.process_end !== null && (() => moment(upload.process_end).format('DD/MM/YYYY - LTS'))() }
                                                        </div>
                                                        <div className="col-3">
                                                            {((status) => {
                                                                switch (status) {
                                                                    case 0:
                                                                        return <span className="badge badge-success">Done</span>
                                                                    case 1:
                                                                        return <span className="badge badge-info">In queue</span>
                                                                    case 2:
                                                                        return <span className="badge badge-warning">Processing</span>
                                                                    case 3:
                                                                        return <span className="badge badge-danger">Timed Out</span>
                                                                    case 4:
                                                                        return <span className="badge badge-danger">Failed</span>
                                                                    default:
                                                                        return status
                                                                }
                                                            })(upload.status)}
                                                        </div>
                                                    </div>

                                                </Fragment>
                                            })
                                        }
                                        {this.props.importProject.uploads.length === 0 && <div className="row"><div className="col-12"><p>No uploads made.</p></div></div>}
                                    </div>
                                </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => {

    return {
        importProject: state.importProjects.importProject,
        fetchingImportProject: state.importProjects.fetchingImportProject,
        importProjectCsvButtonState: state.importProjects.importProjectCsvButtonState
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        getImportProject: (importProjectId) => {dispatch(getImportProject(importProjectId))},
        updateCsvButtonStatus: (value) => {dispatch(updateImportProjectCsvButtonStatus(value))}
    }
}

export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(ImportProject)