import React from 'react';

const ProcessStatus = ({row}) => {

    let label = '';

    switch (row.status) {
        case 3:
            label = <span className="badge badge-success">Compiled</span>
            break;
        case 1:
            label = <span className="badge badge-info">In queue</span>
            break;
        case 2:
            label = <span className="badge badge-warning">Processing</span>
            break;
        case 10:
            label = <span className="badge badge-danger">Error</span>
            break;
    }

    return label
};

export default ProcessStatus
