import React, {Component} from "react";
import DatePicker from "react-datepicker";

class SummaryListHeaderMenu extends Component {

    render() {
        return (
            <>
                <div className="col-8 subheader-filters">
                    <div className="row">
                        <div className="col-6 d-flex align-items-center">
                            <label id="fromDateLabel">Date from (Period)</label>
                            <DatePicker
                                selected={this.props.fromDate}
                                className="form-control form-control-user"
                                onChange={date => this.props.onFromDateChange(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <label id="toDateLabel" >Date to (Period)</label>
                            <DatePicker
                                selected={this.props.toDate}
                                className="form-control form-control-user"
                                onChange={date => this.props.onToDateChange(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-4 text-right">
                    <button onClick={() => this.props.handleModalVisibility(true)}
                            className="btn btn-primary">{this.props.fetching ?
                        <i className="fas fa-circle-notch fa-spin"></i> : "New report"}</button>
                </div>
            </>
        )
    }
}

export default SummaryListHeaderMenu