import React, {Component, Fragment} from 'react';
import Modal from 'react-bootstrap4-modal';
import DatePicker from "react-datepicker";

class ReportListModal extends Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        return {
            name: '',
            category: this.props.statusOptions[0].value,
            start_date: '',
            end_date: '',
        }
    };

    handleModalToggle = () => {
        this.props.toggleModal(false)
    }

    handleGenerate = () => {
        this.props.doGenerateReport(this.state, this.props.projectId)
        this.props.toggleModal(false)
    }

    render() {
        return (
          <div className="query-modal">
              <Modal dialogClassName="modal-md" visible={this.props.modalToggleStatus} onClickBackdrop={() => {
                  this.handleModalToggle()
              }}>
                  <div className="modal-header">
                      <h5 className="modal-title">New report</h5>
                  </div>
                  <div className="modal-body">
                      <Fragment>
                          <div className="form-group row justify-content-center">

                              <div className="col-md-12">
                                  <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                          <span className="input-group-text" id="">Name</span>
                                      </div>
                                      <input onChange={e => this.setState({name: e.target.value})} value={this.state.name}
                                             type="text" className="form-control form-control-user"
                                             placeholder="The report name"/>
                                  </div>
                              </div>



                              <div className="col-sm-12">
                                  <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                          <span className="input-group-text" id="">Category</span>
                                      </div>
                                      <select onChange={(e) => {
                                          this.setState({category: e.target.value})
                                      }} className="form-control form-control-user custom-control custom-select"
                                              value={this.state.category}>
                                          {
                                              this.props.statusOptions.map((option) => {
                                                  return <option key={option.value} value={option.value}>{option.label}</option>
                                              })
                                          }
                                      </select>
                                  </div>
                              </div>



                              <div className="col-sm-6">
                                  <div className="input-group">
                                      <div className="input-group">
                                          <DatePicker
                                              selected={this.state.start_date}
                                              onChange={date => this.setState( {start_date: date})}
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Start Date"
                                          />
                                      </div>

                                  </div>
                              </div>
                              <div className="col-sm-6">
                                  <div className="input-group">
                                      <div className="input-group">
                                          <DatePicker
                                              selected={this.state.end_date}
                                              onChange={date => this.setState( {end_date: date})}
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="End Date"
                                          />
                                      </div>

                                  </div>
                              </div>




                          </div>
                      </Fragment>
                  </div>
                  <div className="modal-footer">
                      <button onClick={() => {
                          this.handleModalToggle()
                      }} type="button" className="btn btn-danger">
                          Cancel
                      </button>

                        <button onClick={(e) => this.handleGenerate(e)}
                                className="btn btn-success btn-icon-split">
                                          <span className="icon text-white-50">
                                            <i className="fas fa-file-download"></i>
                                          </span>
                            <span className="text">Generate</span>
                        </button>
                  </div>
              </Modal>
          </div>
        )
    }
}

export default ReportListModal;