const determineProjectTemplate = (searchResult) => {
    let project = JSON.parse(localStorage.getItem('project'))

    let template = {
        sections: [
            {
                name: "Summary",
                fields: [
                    {dataKey: "image_url", label: ""},
                    {dataKey: "image_host", label: "Domain"},
                    {dataKey: "dk_seller_rating", label: "DK seller rating"},
                    {dataKey: "date_presented_to_client", label: "Date added"},
                    {dataKey: "id", label: "Dupe ID"},
                ]
            },
            {
                name: "Product data",
                fields: [
                    {dataKey: "product_description", label: "Product description"},
                    {dataKey: "image_source", label: "URL"},
                    {dataKey: "additional_information", label: "Additional information"},
                    {dataKey: "notes", label: "Notes"}
                ]
            },
            {
                name: "Seller data",
                fields: [
                    {dataKey: "property_type", label: "Property type"},
                    {dataKey: "estimated_monthly_visits", label: "Monthly visits (Est.)"}
                ]
            },
            {
                name: "Enforcement data",
                fields: []
            },
            {
                name: "Additional information",
                fields: []
            },
        ]
    }

    if (project.has_enforcement_recommendations) { // 1. Do we provide enforcement recommendation?
        template.sections[0].fields.splice(4, 0, {dataKey: "action_recomended", label: "Action recommended"}) // Insert action recommended into summary section
        template.sections[3].fields.splice(0, 0, {dataKey: "action_recomended", label: "Action recommended"}) // Insert action recommended into enforcement data section

        if (searchResult.action_recomended === 1) {
            template.sections[3].fields.splice(1, 0, {dataKey: "cause_of_action", label: "Cause of action"}) // Insert cause of action into enforcement data section
        }
    }

    if (project.has_manage_takedowns) { // 2. Do we manage social/market takedowns?
        template.sections[3].fields.splice(2, 0, {dataKey: "enforcement_status", label: "Enforcement status"}) // Insert enforcement status into enforcement data section
        template.sections[3].fields.splice(3, 0, {dataKey: "takedown_status", label: "Takedown status"}) // Insert takedown status into enforcement data section
    }

    if (project.has_dupe_value) { //3. Do we provide Dupe Value?

        if (searchResult.model_score_report) {
            template.sections[0].fields.splice(3, 0, {dataKey: "model_score_report", label: "Dupe value"})
        } else {
            template.sections[0].fields.splice(3, 0, {dataKey: "dupe_value", label: "Dupe value"})
        }

    }

    if (project.has_manage_enforcement) { // 4. Do we manage enforcement?
        template.sections[3].fields.splice(4, 0, {dataKey: "test_purchase", label: "Test purchase"}) // Insert test purchase into enforcement data section
    }

    if (project.has_features) {
        template.sections[4].fields.splice(0, 0, {dataKey: "features", label: "Features"}) // Insert features into additional information section
    }

    // If property type == website
    if (searchResult.property_type === 3) {
        template.sections[2].fields.splice(3, 0, {dataKey: "global_domain_rank", label: "Global domain rank (EST.)"})
        template.sections[2].fields.splice(4, 0, {dataKey: "domain_authority", label: "Domain authority"})
        template.sections[2].fields.splice(5, 0, {dataKey: "page_authority", label: "Page authority"})
        template.sections[2].fields.splice(6, 0, {dataKey: "audience", label: "Main audience location"})

        if (searchResult.shipping !== null) {
            template.sections[2].fields.splice(7, 0, {dataKey: "shipping", label: "Ships to"})
        }
    }

    return template
}

export default determineProjectTemplate