import React from 'react';

const Status = ({row}) => {

    let label = '';

    switch (row.marked_sr_category) {
        case 1:
            label = "Shortlisted";
            break;
        case 4:
            label = "Confirmed";
            break;
        case 8:
            label = "Staging";
            break;
        case 7:
            label = "Current Month";
            break;
        case 5:
            label = "Enforced";
            break;
        case 9:
            label = "Previous Months";
            break;
        case 10:
            label = "Query";
            break;
        case 11:
            label = "Incorrect";
            break;
        case 12:
            label = "Counterfeit";
            break;
    }

    return label
};

export default Status
