import React, { Component } from 'react';
import Image from '../Image';

class Tab extends Component {

    handleShowTab = () => {
        if(this.props.selectedTab === this.props.tabId) {
            return 'active show';
        }

        return ""
    }

    render() {
        let filteredImages = this.props.images.filter((image, index) => {
            if (this.props.provider.includes('all') || (this.props.provider.includes(image.search_result.search_provider))) {

                return image
            }
            return null
        });

        let project = JSON.parse(localStorage.getItem('project'))

        return (
            <div className={`tab-pane fade ${this.handleShowTab()}`} id={this.props.tabId}>
                <div className="row mt-3">
                    {
                        filteredImages.map((image, index) => {
                        return (
                            <Image
                                key={index}
                                image={image}
                                project={project}
                                selectedImages={this.props.selectedImages}
                                select={this.props.select}
                                deselect={this.props.deselect}
                                selectedPercentMatch={this.props.selectedPercentMatch}
                                canBeFilteredByPercentMatch={this.props.canBeFilteredByPercentMatch}
                                togglePanel={this.props.togglePanel}
                            />
                        )
                        })
                    }

                </div>
            </div>
        )
    }
}

export default Tab