import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {getStatusFilterOptions} from '../../utils/status/statusFilterOptions';
import {
    setStatus, setHost, setPropertyType,
    setDkSellerRating, setFeature, setActionRecommended, setSort, setTextSearch,
    setNotesSearch, setAdditionalInformationSearch
} from "../../actions/singleViewMenu/singleViewMenuActions";
import {
    fetchIdsListForSingleViewShortlist,
} from "../../actions/shortlist/singleViewShortlist";
import getPropertyTypeFilterOptions from "../../utils/filters/propertyTypeFilterOptions";
import getDkSellerRatingFilterOptions from "../../utils/filters/dkSellerRatingFilterOptions";
import getFeatureOptions from "../../utils/filters/featuresOptions";
import getActionRecommendedOptions from "../../utils/filters/actionRecommendedOptions";

class SingleViewMenu extends Component {

    onApplyFilters = (e) => {

        e.preventDefault()

        let filters = {
            status: this.props.menu.status.value || [],
            host: this.props.menu.host || '',
            textSearch: this.props.menu.textSearch || '',
            notesSearch: this.props.menu.notesSearch || '',
            additionalInformationSearch: this.props.menu.additionalInformationSearch || '',
            propertyType: this.props.menu.propertyType || [],
            dkSellerRating: this.props.menu.dkSellerRating || [],
            features: this.props.menu.features || [],
            actionRecommended: this.props.menu.actionRecommended || [],
            sortKey: this.props.menu.sortKey.value,
        }
        this.props.handleInOpenChange(false)
        this.props.fetchIdsListForSingleViewShortlist(filters)
    }



    render() {
        return (
          <div className="row">
              <div className="col-12">
                  <div className="row">
                      <div className="col-12 mb-5">
                          <h4 className="text-center">Search result status</h4>
                          <Select
                            value={this.props.menu.status}
                            onChange={(selectedOption) => this.props.setStatus(selectedOption)}
                            options={getStatusFilterOptions()}
                            placeholder={"Status"}
                          />
                      </div>
                      <div className="col-12 mb-5">
                          <div className="row">
                              <div className="col-12">
                                  <h4 className="text-center">Text filter</h4>
                              </div>
                              <div className="col-6 mb-3">
                                  <label>Domain</label>
                                  <input type="text" onChange={(e) => {
                                      this.props.setHost(e.target.value)
                                  }} className="form-control form-control-user" value={this.props.menu.host}
                                         disabled={false}/>
                              </div>
                              <div className="col-6 mb-3">
                                  <label>Text search</label>
                                  <input type="text" onChange={(e) => {
                                      this.props.setTextSearch(e.target.value)
                                  }} className="form-control form-control-user"
                                         value={this.props.menu.textSearch}
                                         disabled={false}/>
                              </div>
                              <div className="col-6">
                                  <label>Notes</label>
                                  <input type="text" onChange={(e) => {
                                      this.props.setNotesSearch(e.target.value)
                                  }} className="form-control form-control-user"
                                         value={this.props.menu.notesSearch}
                                         disabled={false}/>
                              </div>
                              <div className="col-6">
                                  <label>Additional Information</label>
                                  <input type="text" onChange={(e) => {
                                      this.props.setAdditionalInformationSearch(e.target.value)
                                  }} className="form-control form-control-user"
                                         value={this.props.menu.additionalInformationSearch}
                                         disabled={false}/>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 mb-5">
                          <div className="row">
                              <div className="col-12">
                                  <h4 className="text-center">Search result properties</h4>
                              </div>
                              <div className="col-6 mb-3">
                                  <label>Property type</label>
                                  <Select
                                    value={this.props.menu.propertyType}
                                    onChange={(selectedOption) => this.props.setPropertyType(selectedOption)}
                                    options={getPropertyTypeFilterOptions()}
                                    placeholder={"Property type"}
                                    isMulti
                                  />
                              </div>
                              {/*<div className="col-6 mb-3">*/}
                              {/*    <label>DK Seller rating</label>*/}
                              {/*    <Select*/}
                              {/*      value={this.props.menu.dkSellerRatingFilterValue}*/}
                              {/*      onChange={(selectedOption) => this.props.setDkSellerRating(selectedOption)}*/}
                              {/*      options={getDkSellerRatingFilterOptions()}*/}
                              {/*      placeholder={"DK Seller Rating"}*/}
                              {/*      isMulti*/}
                              {/*    />*/}
                              {/*</div>*/}
                              <div className="col-6 mb-3">
                                  <label>Tags</label>
                                  <Select
                                    value={this.props.menu.features}
                                    onChange={(selectedOption) => this.props.setFeature(selectedOption)}
                                    options={getFeatureOptions()}
                                    isMulti
                                    placeholder={"Tags"}
                                  />
                              </div>
                              <div className="col-6">
                                  <label>Action recommended</label>
                                  <Select
                                    value={this.props.menu.actionRecommended}
                                    onChange={(selectedOption) => this.props.setActionRecommended(selectedOption)}
                                    options={getActionRecommendedOptions()}
                                    isMulti
                                    placeholder={"Action recommended"}
                                  />
                              </div>
                          </div>
                      </div>

                      <div className="col-12">
                          <div className="row">
                              <div className="col-12">
                                  <h4 className="text-center">Sorting</h4>
                              </div>
                              <div className="col-6 mb-3">
                                  <label>Sort</label>
                                  <Select
                                      value={this.props.menu.sortKey}
                                      onChange={(selectedOption) => this.props.setSort(selectedOption)}
                                      options={[
                                          {value: 'no-sort', label: 'None'},
                                          {value: 'date_found_asc', label: 'Date Found Asc'},
                                          {value: 'date_found_desc', label: 'Date Found Desc'},
                                          {value: 'domain_az', label: 'Domain AZ'},
                                          {value: 'domain_za', label: 'Domain ZA'},
                                          {value: 'monthly_visits_lh', label: 'Monthly Visits Low to High'},
                                          {value: 'monthly_visits_hl', label: 'Monthly Visits High to Low'},
                                      ]}
                                      placeholder={"None"}
                                  />
                              </div>

                              <div className="col-12 mt-3 d-flex justify-content-center">
                                  <button onClick={(e) => this.onApplyFilters(e)} className="btn btn-primary">
                                      Filter
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        menu: state.singleViewMenu,
        singleViewShortlist: state.singleViewShortlist,
    }
};

const mapActionToProps = (dispatch) => {
    return {
        setStatus: (statusValue) => {
            dispatch(setStatus(statusValue))
        },
        setHost: (hostValue) => {
            dispatch(setHost(hostValue))
        },
        setTextSearch: (value) => {
            dispatch(setTextSearch(value))
        },
        setNotesSearch: (value) => {
            dispatch(setNotesSearch(value))
        },
        setAdditionalInformationSearch: (value) => {
            dispatch(setAdditionalInformationSearch(value))
        },
        fetchIdsListForSingleViewShortlist: (filters) => {
            dispatch(fetchIdsListForSingleViewShortlist(filters))
        },
        setPropertyType: (propertyType) => {
            dispatch(setPropertyType(propertyType))
        },
        setDkSellerRating: (dkSellerRating) => {
            dispatch(setDkSellerRating(dkSellerRating))
        },
        setFeature: (selectedFeature) => {
            dispatch(setFeature(selectedFeature))
        },
        setActionRecommended: (selectedAction) => {
            dispatch(setActionRecommended(selectedAction))
        },
        // SORTING
        setSort: (sortOrder) => {
            dispatch(setSort(sortOrder))
        },
    }
};

export default connect(mapStateToProps, mapActionToProps)(SingleViewMenu);
