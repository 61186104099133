export const SET_SEARCHER_REPORT_START_DATE = 'searcherReport:setSearcherReportStartDate';
export const SET_SEARCHER_REPORT_END_DATE = 'searcherReport:setSearcherReportEndDate'
export const SET_BUTTON_DOWNLOADING_STATUS = 'searcherReport:setButtonDownloadingStatus'

export function setSearcherReportStartDate(startDate) {
    return {
        type: SET_SEARCHER_REPORT_START_DATE,
        payload: {
            startDate,
        }
    }
}

export function setSearcherReportEndDate(endDate) {
    return {
        type: SET_SEARCHER_REPORT_END_DATE,
        payload: {
            endDate,
        }
    }
}

export function setButtonDownloadingStatus(downloadingStatus) {
    return {
        type: SET_BUTTON_DOWNLOADING_STATUS,
        payload: {
            downloadingStatus,
        }
    }
}