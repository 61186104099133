import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import Login from "./Login";
import Search from "./Search";
import Shortlist from './Shortlist';
import SearchView from './SearchView';
import Reports from './Reports';
import SingleViewShortlist from './SingleViewShortlist';
import ProtectedRoute from "./authentication/ProtectedRoute";
import Summary from "./summary/Summary";
import SummaryView from "./summary/SummaryView";
import DomainMeta from "./domainMeta/DomainMeta";
import List from "./imagesDownload/List";
import UserManagement from "./userManagement/UserManagement";
import SearcherReportData from "./searcherReportData/SearcherReportData";
import UserNew from "./userManagement/UserNew";
import UserEdit from "./userManagement/UserEdit";
import UserProjects from "./userManagement/UserProjects";
import FFReports from "./FFReports";
import ListImportProjects from "./importProject/ListImportProjects";
import Monitoring from "./monitoring/Monitoring"
import ImportProject from "./importProject/ImportProject";
import ClientsAndProjectsList from "./ClientsAndProjectsList";


class App extends Component {

    render() {
        return (
            <Switch>
                <Route path="/login" exact render={(routeProps => (
                    <Login {...routeProps} />
                ))}/>
                <ProtectedRoute name="clientsAndProjectsList" path="/" exact component={ClientsAndProjectsList} />
                <ProtectedRoute name="projectDashboard" path="/project/:projectId/dashboard" component={Dashboard} />
                <ProtectedRoute name="projectManualSearch" path="/project/:projectId/search" exact component={Search} />
                <ProtectedRoute name="projectShortlist" path="/project/:projectId/shortlist" exact component={Shortlist} />
                <ProtectedRoute name="singleViewSearchResult" path="/project/:projectId/search-result/:id?" exact component={SingleViewShortlist} />
                <ProtectedRoute name="searchList" path="/search/:searchId" exact component={SearchView} />
                <ProtectedRoute name="reportsList" path="/reports/:projectId" exact component={Reports} />
                <ProtectedRoute name="ffReportsList" path="/reports/ff/:projectId" exact component={FFReports} />
                <ProtectedRoute name="summary" path="/summary" exact component={Summary} />
                <ProtectedRoute name="summaryView" path="/summary/:summaryId" exact component={SummaryView} />
                <ProtectedRoute name="domainMeta" path="/domains-meta" exact component={DomainMeta} />
                <ProtectedRoute name="modelEvalImageDownloadList" path="/images-download" exact component={List} />
                <ProtectedRoute name="searcherReportData" path="/searcher-report-data" exact component={SearcherReportData} />
                <ProtectedRoute name="users" path="/users" exact component={UserManagement} />
                <ProtectedRoute name="userNew" path="/users/new" exact component={UserNew} />
                <ProtectedRoute name="usersEdit" path="/users/edit/:userId" exact component={UserEdit} />
                <ProtectedRoute name="usersProjects" path="/users/projects/:userId" exact component={UserProjects} />
                <ProtectedRoute name="importProjects" path="/import-projects" exact component={ListImportProjects} />
                <ProtectedRoute name="monitoring" path="/monitoring" exact component={Monitoring} />
                <ProtectedRoute name="importProject" path="/import-project/:importProjectId" exact component={ImportProject} />
                <Route label="No match"/>
            </Switch>
        );
    }
}

export default App;
