import prepHeaders from '../../utils/prepHeaders';
import {setFetchingImages, analyzedShortlist} from "../images/imagesAction";
import {setSelectedTab} from "../filter/filterAction";
import download from 'downloadjs';
import moment from 'moment';
import axios from "axios";

export const UPDATE_SEARCH_RESULT_ANALYSIS = 'analysis:updateSearchResult';
export const UPDATE_SEARCH_RESULT_ANALYSIS_FROM_STATUS = 'analysis:updateSearchResultStatus';

export function updateSearchResultAnalysis(updatedSearchResult) {
    return {
        type: UPDATE_SEARCH_RESULT_ANALYSIS,
        payload: {
            updatedSearchResult
        }
    }
}

export function updateAnalyzeFormStatus(type = null, message = '') {
    return {
        type: UPDATE_SEARCH_RESULT_ANALYSIS_FROM_STATUS,
        payload: {
            type,
            message
        }
    }
}

export const doAnalyzeShortlist = (images, selectedTab) => {
    return dispatch => {

        if (images.length !== 0) {
            images.forEach((imageObject, index) => {

                dispatch(setSelectedTab(selectedTab))
                let currentImage = images[index];

                let payload = {
                    search_result: {...currentImage.search_result},
                };

                axios({
                    url: process.env.REACT_APP_API_BASE_URL + "/analyse",
                    method: 'POST',
                    withCredentials: true,
                    headers: prepHeaders(),
                    data: payload
                }).then(response => {

                    dispatch(analyzedShortlist(response.data, imageObject.search_result.search_hash));
                    dispatch(setSelectedTab(selectedTab))
                }).catch(error => {
                    dispatch(setSelectedTab(selectedTab))
                })
            })
        }
    }
};

export const doAnalysisFormUpdated = (searchResultId, payload, oldSearchResult) => {
    return dispatch => {
        dispatch(updateAnalyzeFormStatus());

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/search-result/" + searchResultId,
            method: 'PATCH',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            let updatedSearchResult = {...oldSearchResult, ...response.data}
            dispatch(updateSearchResultAnalysis(updatedSearchResult))
            dispatch(updateAnalyzeFormStatus('success', 'Search result updated'));
            setTimeout(() => {
                dispatch(updateAnalyzeFormStatus())
            }, 3000)
        }).catch(error => {
            dispatch(updateAnalyzeFormStatus('error', 'Form update could not be processed'));
            setTimeout(() => {
                dispatch(updateAnalyzeFormStatus())
            }, 3000)
            let message;
            switch (error.message) {
                case "401":
                    message = 'Your session has expired. Please login to resume.';
                    break;
                default:
                    message = 'Something went wrong';
            }
        })
    }
};

export const doReportShortlist = (images, projectId) => {
    return dispatch => {

        let ids = images.map((image => image.search_result.id));
        let payload = {
            ids,
            project_id: projectId
        };
        dispatch(setFetchingImages(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/report",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload,
            responseType: 'blob'
        }).then(responseData => {
            let fileName = `report_${moment().format('YYYY-MM-DD_H:m')}.zip`;
            download(responseData, fileName, "text/plain");
            dispatch(setFetchingImages(false))
        }).catch(error => {
            dispatch(setFetchingImages(false))
            console.log(error)
        })
    }
}
