import {SET_FF_FETCHING_STATUS, SET_FF_REPORTS_LIST, TOGGLE_FF_MODAL, APPEND_TO_FF_REPORT_LIST, SET_FF_DOWNLOADING} from "../actions/ffReports/ffReportsAction";

const initialState = {
    fetching: false,
    modalStatus: false,
    downloading: false,
    ffReportsList: [],
};

export default function ffReportsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FF_FETCHING_STATUS:
            return {
                ...state,
                fetching: action.payload.status
            };
        case SET_FF_DOWNLOADING:
            return {
                ...state,
                downloading: action.payload.status
            };
        case SET_FF_REPORTS_LIST:
            return {
                ...state,
                ffReportsList: action.payload.ffReportsList
            };
        case APPEND_TO_FF_REPORT_LIST:

            let copyOfReportList = [...state.ffReportsList]

            copyOfReportList.push(action.payload.ffReport)

            return {
                ...state,
                ffReportsList: copyOfReportList
            }
        case TOGGLE_FF_MODAL:
            return {
                ...state,
                modalStatus: action.payload.status
            };
        default:
            return state
    }
}
