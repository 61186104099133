import React, {Component, Fragment} from "react";
import {NavLink} from "react-router-dom";
import { withRouter } from "react-router-dom";

import getUserRoles from "../../utils/auth/getUserRoles";
import hasAccessLevel from "../../utils/auth/hasAccessLevel";
import {ROLES} from "../../utils/enums/Roles";
import TopBarAdminCogMenu from "../TopBarAdminCogMenu";

class SuperAdminSidebar extends Component {

    render() {

        return (
            <ul className="navbar-nav bg-gray-600 sidebar sidebar-dark accordion" id="accordionSidebar">

                <div className={'d-flex align-items-center justify-content-center'}>
                    <NavLink to="/" className={'sidebar-brand sidebar-brand-text mx-3'}>Dupe Killer</NavLink>
                </div>

                <hr className="sidebar-divider my-0"/>

                {hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) &&
                    <Fragment>
                        <li className={`nav-item`}>
                            <NavLink activeClassName={"active"} to={`/users`} className="nav-link">
                                <i className="fas fa-fw fa-users"></i>
                                <span>Users Management</span>
                            </NavLink>
                        </li>

                        <hr className="sidebar-divider my-0"/>

                        <li  className={`nav-item`}>
                            <NavLink activeClassName={"active"} to={`/searcher-report-data`} className="nav-link">
                                <i className="fas fa-fw fa-chart-line"></i>
                                <span>Searcher Performance</span>
                            </NavLink>
                        </li>

                        <hr className="sidebar-divider my-0"/>

                        <li className={`nav-item`}>
                            <NavLink activeClassName={"active"} to={`/domains-meta`} className="nav-link">
                                <i className="fas fa-table"></i>
                                <span>Domains Meta</span>
                            </NavLink>
                        </li>
                    </Fragment>
                }

                <hr className="sidebar-divider my-0"/>

                <li className={`nav-item`}>
                    <NavLink activeClassName={"active"} to={`/import-projects`} className="nav-link">
                        <i className="fas fa-list"></i>
                        <span>Import Projects</span>
                    </NavLink>
                </li>

                <hr className="sidebar-divider my-0"/>

                <li className={`nav-item`}>
                    <NavLink activeClassName={"active"} to={`/monitoring`} className="nav-link">
                        <i className="fas fa-heartbeat"></i>
                        <span>Monitoring</span>
                    </NavLink>
                </li>

            </ul>
        )
    }
}

export default withRouter(SuperAdminSidebar);
