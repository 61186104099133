import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Sidebar from './Sidebar/Sidebar';
import Topbar from './Topbar';
import ReportsList from './reports/List';
import ReportListModal from './reports/ReportListModal';
import {toggleModal, generateReport} from '../actions/reports/reportsAction'

class Reports extends Component {

    render() {
        return (
          <Fragment>
              <div id="page-top">
                  <div id="wrapper">
                      <Sidebar/>
                      <div id="content-wrapper" className="d-flex flex-column">
                          <div id="content" className="reports-content">

                              <Topbar />
                              <div className="container-fluid">
                                  <ReportListModal
                                    modalToggleStatus={this.props.reports.modalStatus}
                                    toggleModal={this.props.toggleModal}
                                    statusOptions={this.props.status.statusOptions}
                                    projectId={this.props.match.params.projectId}
                                    doGenerateReport={this.props.doGenerateReport}
                                  />
                                  <ReportsList
                                    projectId={this.props.match.params.projectId}
                                    toggleModal={this.props.toggleModal}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reports: state.reports,
        status: state.status
    }
};

const mapActionToProps = (dispatch) => {
    return {
        toggleModal: (status) => {dispatch(toggleModal(status))},
        doGenerateReport: (data, projectId) => {dispatch(generateReport(data, projectId))}
    }
};

export default connect(mapStateToProps, mapActionToProps)(Reports);
