import React from 'react';

const ProcessStatus = ({row}) => {

    let label = '';

    switch (row.process_status) {
        case 0:
            label = <span className="badge badge-success">Done</span>
            break;
        case 1:
            label = <span className="badge badge-info">In queue</span>
            break;
        case 2:
            label = <span className="badge badge-warning">Processing</span>
            break;
    }

    return label
};

export default ProcessStatus
