import React, {Component, Fragment} from 'react';
import Topbar from './Topbar';
import Sidebar from './Sidebar/Sidebar';
import Panel from './panel/Panel';
import Menu from './menu/Menu';
import Pagination from "react-js-pagination";
import Image from "./Image";
import ShortlistFilterPanel from "./panel/ShortlistFilterPanel"
import Sticky from 'react-sticky-el';
import ConfirmationModal from './modal/ConfirmationModal';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {doSelectStatus, doMarkForShortlist} from "../actions/status/statusAction";
import {
    fetchShortlistBasedOnFilters, setAnalyzedFilter,
    setSelectedTab, setCurrentPage, setItemsCountPerPage, resetAll, fetchSearchers
} from "../actions/filter/filterAction";
import {doCloseAndUnsetImageForShortlist, doOpenAndSetImageForShortlist} from "../actions/sidepanel/sidepanelAction"
import {selectIsMarkDisabled} from "../reducers/selectors/selectIsMarkDisabled";
import {doDeselectForShortlist, doSelectForShortlist, setSelectedForShortlist} from "../actions/images/imagesAction";
import {setMenuAction} from "../actions/menu/menuActions";
import {doClassifyShortlist} from "../actions/classify/classifyAction";
import {doFetchModels, setSelectedMenuModel} from "../actions/models/modelsAction";
import {doAnalyzeShortlist, doReportShortlist} from "../actions/analyze/analyzeAction";
import {setShortlistConfirmationModalStatus} from "../actions/modal/modalActions";
import {getPaginationItemsCountOptions} from "../utils/pagination/paginationItemsCountOptions";
import {fetchUpdatedFeatures} from "../actions/shortlist/shortlist";
import prepareFilters from "../utils/filters/prepareFilters";
import {getStatusFilterOptions} from "../utils/status/statusFilterOptions";

class Shortlist extends Component {

    constructor(props) {
        super(props)

        this.state = {
            slidingFilterPanelStatus: false
        }
    }

    componentDidMount() {
        let filters = prepareFilters(this.props.filter)
        this.props.fetchShortlistBasedOnFilters(this.props.match.params.projectId, filters, this.props.images.selectedShortlist);
        this.props.fetchAndSetModels(this.props.match.params.projectId)
        // this.interval = setInterval(() => {this.props.fetchShortlistBasedOnFilters(this.props.match.params.projectId, this.filters, this.props.images.selectedShortlist)}, 10000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleFilterChange = () => {
        this.props.setCurrentPage(1)
        let filters = prepareFilters(this.props.filter)
        filters.currentPageValue = 1
        this.props.fetchShortlistBasedOnFilters(this.props.match.params.projectId, filters, this.props.images.selectedShortlist);
        this.setState({slidingFilterPanelStatus: false})
    }

    handleFilterReset = () => {
        this.props.fetchSearchers()
        this.props.resetAll()
    }

    handlePageChange = (page) => {
        this.props.setCurrentPage(page)
        let filters = prepareFilters(this.props.filter)
        filters.currentPageValue = parseInt(page)
        this.props.fetchShortlistBasedOnFilters(this.props.match.params.projectId, filters, this.props.images.selectedShortlist);
    }

    handleItemsCountPerPage = (countPerPage) => {
        this.props.setCurrentPage(1)
        this.props.setItemsCountPerPage(parseInt(countPerPage))
        let filters = prepareFilters(this.props.filter)
        filters.itemsCountPerPage = parseInt(countPerPage)
        filters.currentPageValue = 1
        this.props.fetchShortlistBasedOnFilters(this.props.match.params.projectId, filters, this.props.images.selectedShortlist);
    }

    render() {
        let project = JSON.parse(localStorage.getItem('project'))

        let hasImageForSlidePanel = !!this.props.images.imageForSidePanelInShortlist;
        let hasImages = this.props.shortlist.list.length > 0;

        let images = this.props.shortlist.list.map((image, index) => {
            return (
              <Image
                key={index}
                image={image}
                project={project}
                selectedImages={this.props.images.selectedShortlist}
                select={this.props.doSelect}
                deselect={this.props.doDeselect}
                // selectedPercentMatch={this.props.selectedPercentMatch}
                // canBeFilteredByPercentMatch={false}
                hasInfoPanel={true}
                togglePanel={this.props.togglePanel}
                fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
              />
            )
        });

        return (
          <Fragment>
              <div id="page-top">
                  <div id="wrapper">
                      <Sidebar />
                      <div id="content-wrapper" className="d-flex flex-column">
                          <div id="content" className="search-content">
                              <Sticky className="sticky-container">
                                  <Topbar/>
                                  <div className="container-fluid sticky-max">
                                  <div className="card shadow h-100">
                                      <div className="card-body">
                                          <div className="row">
                                              <div className="col-12">
                                                  <div className="row">
                                                      <div className="col-12">
                                                          <Menu
                                                            actionOptions={[
                                                                {label: "Mark as", value: 'mark'},
                                                                {label: "Assess", value: 'classify'},
                                                                {label: "Scrape", value: 'analyze'}
                                                            ]}
                                                            setMenuAction={this.props.setMenuAction}
                                                            actionValue={this.props.menu.actionType}
                                                            statusOptions={getStatusFilterOptions()}
                                                            statusValue={this.props.status.selectedStatus}
                                                            setSelectedStatus={this.props.setSelectedStatus}
                                                            selectAllStatus={this.props.menu.selectAll}
                                                            setSelectedImages={this.props.setSelectedImages}
                                                            doSelect={this.props.doSelect}
                                                            images={this.props.shortlist.list}
                                                            selectedImages={this.props.images.selectedShortlist}
                                                            doMark={this.props.doMark}
                                                            doClassify={this.props.doClassify}
                                                            doAnalyze={this.props.doAnalyze}
                                                            doReport={this.props.doReport}
                                                            selectedTab={this.props.filter.selectedTab}
                                                            selectedMenuModelValue={this.props.models.selectedMenuModelValue}
                                                            modelList={this.props.models.list}
                                                            setSelectedMenuModel={this.props.setSelectedMenuModel}
                                                            projectId={this.props.match.params.projectId}
                                                            searchTermId={null}
                                                            confirmationModalStatus={this.props.modal.confirmationModalStatus}
                                                            setConfirmationModalModalStatus={this.props.setConfirmationModalModalStatus}
                                                            openFiltersPanel={() => this.setState({slidingFilterPanelStatus: true})}
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row mb-0" >
                                              <div className="col d-flex justify-content-center align-items-center">
                                                  <div className="mr-3">Offset: {this.props.filter.itemsCountPerPage * (this.props.filter.currentPageValue === 1 ? 0 : this.props.filter.currentPageValue)} / {this.props.filter.totalItems}</div>
                                                  <Pagination
                                                    activePage={this.props.filter.currentPageValue}
                                                    itemsCountPerPage={this.props.filter.itemsCountPerPage}
                                                    totalItemsCount={this.props.filter.totalItems}
                                                    onChange={(page) => this.handlePageChange(page)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                  />
                                                  <select onChange={(e) => {
                                                      this.handleItemsCountPerPage(e.target.value)
                                                  }} className="form-control form-control-user custom-control custom-select pagination-items-count-select"
                                                          value={this.props.filter.itemsCountPerPage}>
                                                      {
                                                          getPaginationItemsCountOptions().map((paginationOption) => {
                                                              return <option key={paginationOption.value} value={paginationOption.value}>{paginationOption.label}</option>
                                                          })
                                                      }
                                                  </select>
                                              </div>
                                          </div>
                                    </div>
                                  </div>
                                </div>
                              </Sticky>
                              </div>
                              <div className="container-fluid">
                                  <hr/>
                                  {this.props.shortlist.fetching ? (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col d-flex justify-content-center align-items-center">
                                                <i className="fas fa-circle-notch fa-spin fetching-spinner"></i>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                        {hasImages ? (
                                          <div className="row">
                                              {images}
                                          </div>
                                        ) : (
                                          <div className="row d-flex align-item-center justify-content-center">
                                              <div className="col-6 col-offset-3 text-center">
                                                  <div className="alert alert-info">No search results matching your filters.</div>
                                              </div>
                                          </div>
                                        )}
                                    </React.Fragment>
                                  )

                                  }

                              </div>

                          </div>
                      </div>
                  </div>
              <Panel
                isOpen={this.props.sidepanel.openShortlist}
                hasImage={hasImageForSlidePanel}
                closeSidePanel={this.props.closeSidePanel}
                image={this.props.images.imageForSidePanelInShortlist}
                hasAnalysisFields={true}
              />
              <ShortlistFilterPanel
                isOpen={this.state.slidingFilterPanelStatus}
                onBackdropClick={() => this.setState({slidingFilterPanelStatus: false})}
                projectId={this.props.match.params.projectId}
                handleFilterChange={this.handleFilterChange}
                handleFilterReset={this.handleFilterReset}
                handleClose={() => this.setState({slidingFilterPanelStatus: false})}
              />
              <ConfirmationModal
                confirmationModalStatus={this.props.modal.confirmationModalStatus}
                setConfirmationModalModalStatus={this.props.setConfirmationModalModalStatus}
                images={this.props.shortlist.list}
                selectedImages={this.props.images.selectedShortlist}
                doMark={this.props.doMark}
                statusValue={this.props.status.selectedStatus}
                projectId={this.props.match.params.projectId}
                searchTermId={null}
              />
          </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        shortlist: state.shortlist,
        status: state.status,
        filter: state.filter,
        images: state.images,
        sidepanel: state.sidepanel,
        menu: state.menu,
        models: state.models,
        modal: state.modal,
        isMarkDisabled: selectIsMarkDisabled(state.images.selectedShortlist),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedStatus: (value) => { // Used in Menu component
            dispatch(doSelectStatus(value))
        },
        doMark: (selectedImages, status, initialSearchImages, searchTermId, projectId) => {
            dispatch(doMarkForShortlist(selectedImages, status, initialSearchImages, searchTermId, projectId))
        },
        setAnalyzedFilter: (filterValue) => {
            dispatch(setAnalyzedFilter(filterValue))
        },
        setCurrentPage: (currentPageValue) => {dispatch(setCurrentPage(currentPageValue))},
        setItemsCountPerPage: (itemsCountPerPage) => {dispatch(setItemsCountPerPage(itemsCountPerPage))},
        fetchShortlistBasedOnFilters: (value, projectId, filters) => {
            dispatch(fetchShortlistBasedOnFilters(value, projectId, filters))
        },
        doClassify: (images, selectedModel, statusFilter) => {
            dispatch(doClassifyShortlist(images, selectedModel, statusFilter))
        },
        doAnalyze: (images, currentSelectedTab) => {
            dispatch(doAnalyzeShortlist(images, currentSelectedTab))
        },
        doReport: (images, projectId) => {
            dispatch(doReportShortlist(images, projectId))
        },
        closeSidePanel: () => {
            dispatch(doCloseAndUnsetImageForShortlist())
        },
        doSelect: (image) => {
            dispatch(doSelectForShortlist(image))
        },
        doDeselect: (index) => {
            dispatch(doDeselectForShortlist(index))
        },
        setSelectedImages: () => (dispatch(setSelectedForShortlist())),
        setMenuAction: (actionType) => {
            dispatch(setMenuAction(actionType))
        },
        setSelectedTab: (tab) => {
            dispatch(setSelectedTab(tab))
        },
        fetchAndSetModels: (projectId) => {
            dispatch(doFetchModels(projectId))
        },
        setSelectedMenuModel: (selectedMenuModelValue) => {
            dispatch(setSelectedMenuModel(selectedMenuModelValue))
        },
        togglePanel: (image) => {
            dispatch(doOpenAndSetImageForShortlist(image))
        },
        setConfirmationModalModalStatus: (status) => {
            dispatch(setShortlistConfirmationModalStatus(status))
        },
        fetchUpdatedFeatures: (e, featureId, searchHash) => {
            dispatch(fetchUpdatedFeatures(e, featureId, searchHash))
        },
        fetchSearchers: () => {
            dispatch(fetchSearchers())
        },
        resetAll: () => {
            dispatch(resetAll())
        }
    }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Shortlist)