import React, {Component} from "react";
import {RadialChart} from "react-vis";
import {formatNumberAsIntl} from "../../../utils/format";
import getPropertyTypeFilterOptions from "../../../utils/filters/propertyTypeFilterOptions";
import {getStatusFilterOptions} from "../../../utils/status/statusFilterOptions";


class PropertyTypeSplit extends Component {

    generateColorWithAlpha = (max, value, rgb) => {
        let colorAlpha = ((value * 100 / max) / 90) + 0.1

        return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${colorAlpha})`
    }

    parseColor = (value, maxVal, minVal) => {
        if (value === maxVal) {
            return "#083f9f"
        }
        if (value === minVal) {
            return "#477ce1"
        }
        return "#4e73df"
    }



    prepDataForChart = () => {

        let propTypeDistributionArray = [
            {"key": "web", "count": this.props.propertyTypeDistribution['web']},
            {"key": "marketplace", "count": this.props.propertyTypeDistribution['marketplace']},
            {"key": "social", "count": this.props.propertyTypeDistribution['social']}
        ]

        let maxCount = Math.max(...propTypeDistributionArray.map(i => i.count))
        let minCount = Math.min(...propTypeDistributionArray.map(i => i.count))



        let data = [];

        for (const prop in this.props.propertyTypeDistribution) {
            if (this.props.propertyTypeDistribution[prop] > 0 ) {
                // data.push({angle: this.props.propertyTypeDistribution[prop], name: prop, color: this.generateColorWithAlpha(maxCount, this.props.propertyTypeDistribution[prop], [78, 114, 223]), initialValue: this.props.propertyTypeDistribution[prop]})
                data.push({angle: this.props.propertyTypeDistribution[prop], name: prop, color: this.parseColor(this.props.propertyTypeDistribution[prop], maxCount, minCount), initialValue: this.props.propertyTypeDistribution[prop]})
            }
        }

        return data;
    }

    getLabel = (d) => {
        return `${d.name} (${formatNumberAsIntl(d.initialValue)})`
    }

    handleOnValueClick = (value) => {

        this.props.resetFiltersToDefaul()
        this.props.setIsDatePresentedToClient(true)
        this.props.setRemoteStatus(getStatusFilterOptions()[0])

        let route = `/project/${JSON.parse(localStorage.getItem('project')).id}/shortlist`

        const { name } = value
        let option = getPropertyTypeFilterOptions().find(option => option.label.toLowerCase() === name)

        this.props.setDatePresentedToClientStart(new Date(this.props.summary.from))
        this.props.setDatePresentedToClientEnd(new Date(this.props.summary.to))
        this.props.setPropertyType([option])
        this.props.router.push(route)
    }

    render() {

        let chartData = this.prepDataForChart();

        return (
          <div className="card shadow property-type-split">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Dupes split by property type</h6>
              </div>
              <div className="card-body">
                  <div className="row">
                      <div className="col-8 offset-2 d-flex chart justify-content-center align-items-center pie-split">
                          <RadialChart
                            className="radial-chart-property-type-split"
                            colorType={'literal'}
                            colorDomain={[0, 100]}
                            colorRange={[0, 10]}
                            margin={{top: 100}}
                            getLabel={d => this.getLabel(d)}
                            data={chartData}
                            labelsRadiusMultiplier={1.4}
                            labelsStyle={{fontSize: 14, fill: 'black', fontWeight: 'bold', textTransform: 'capitalize'}}
                            showLabels
                            style={{stroke: '#fff', strokeWidth: 0.5}}
                            width={480}
                            height={480}
                            radius={170}
                            onValueClick={value => this.handleOnValueClick(value)}
                          />
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

export default PropertyTypeSplit;