import {SET_ACTION} from "../actions/menu/menuActions";

const initialState = {
    actionType: 'mark',
    selectAll: false
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACTION:
            return {
                ...state,
                actionType: action.payload.type
            };
        default:
            return state
    }
}
