import React, {Component, Fragment} from 'react';
import {handleDataMappings} from "../../utils/dataMappings";
import SvsFeatures from "./SvsFeatures";
import moment from "moment";
import SvsNotes from "./SvsNotes";

class TemplateSection extends Component {

    render() {

        let labelCol = '6 label-col';
        let valueCol = '6 value-col';
        return (
          <Fragment>
              {this.props.fields.length > 0 &&
              <Fragment>
                  <div className="row">
                      <div className="col-12 section-title-col">
                          <h4 className="mb-0 py-2">{this.props.sectionTitle}</h4>
                      </div>
                  </div>

                  {this.props.fields.map((data) => {
                      if (data.dataKey === 'image_url') {
                          return (
                            <div key={data.dataKey} className="row">
                                <div className="col-12 image-col">
                                    <div className="svs-product-image"
                                         style={{backgroundImage: "url(" + this.props.imageData[data.dataKey] + ")"}}></div>
                                </div>
                            </div>
                          )
                      }

                      if (data.dataKey === 'image_source') {
                          return (
                            <div key={data.dataKey} className="row">
                                <div className={`col-${labelCol}`}><p>{data.label}</p></div>
                                <div className={`col-${valueCol}`}>
                                    <p>{this.props.imageData[data.dataKey] === null ? '' :
                                      <a rel="noopener noreferrer" target="_blank"
                                         href={this.props.imageData[data.dataKey]}>{this.props.imageData[data.dataKey]}</a>}</p>
                                </div>
                            </div>
                          )
                      }

                      if (data.dataKey === 'notes') {
                          return (
                            <SvsNotes
                              id={this.props.imageData.id}
                              key={data.dataKey}
                              notesData={this.props.imageData[data.dataKey]}
                              labelCol={labelCol}
                              valueCol={valueCol}
                              setNotes={this.props.setNotes}
                              saveNotes={this.props.saveNotes}
                              notesValue={this.props.notesValue}
                            />
                          )
                      }

                      if (data.dataKey === 'date_presented_to_client') {
                          return (
                            <div key={data.dataKey} className="row">
                                <div className={`col-${labelCol}`}><p>{data.label}</p></div>
                                <div className={`col-${valueCol}`}>
                                    <p>{this.props.imageData[data.dataKey] === null ? '' : moment(handleDataMappings(data.dataKey, this.props.imageData[data.dataKey])).format('DD/M/YYYY')}</p>
                                </div>
                            </div>
                          )
                      }

                      if (data.dataKey === 'image_host') {
                          return (
                            <div key={data.dataKey} className="row">
                                <div className={`col-${labelCol}`}><p>{data.label}</p></div>
                                <div className={`col-${valueCol}`}><a rel="noopener noreferrer" target="_blank"
                                                                      href={'//' + this.props.imageData[data.dataKey]}>{this.props.imageData[data.dataKey] === null ? '' : handleDataMappings(data.dataKey, this.props.imageData[data.dataKey])}</a>
                                </div>
                            </div>
                          )
                      }

                      if (data.dataKey === 'features') {
                          return (
                            <SvsFeatures key={data.dataKey} imageData={this.props.imageData}
                                         fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}/>
                          )
                      }

                      if (data.dataKey !== 'features') {
                          return (
                            <div key={data.dataKey} className="row">
                                <div className={`col-${labelCol}`}><p>{data.label}</p></div>
                                <div className={`col-${valueCol}`}>
                                    <p>{this.props.imageData[data.dataKey] === null ? '' : handleDataMappings(data.dataKey, this.props.imageData[data.dataKey])}</p>
                                </div>
                            </div>
                          )
                      }
                  })}
              </Fragment>
              }
          </Fragment>
        )


    }
}

export default TemplateSection;