import React, {Component} from "react";
import Topbar from "../Topbar";
import Select from "react-select";
import {connect} from "react-redux";
import {
    addNewDomainMeta,
    fetchDomainList,
    fetchMetadataForDomain, saveDomainMeta,
    setSelectedDomain,
    updateCsvButtonStatus
} from "../../actions/domainMeta/domainMeta";
import EditForm from "./EditForm";
import AddNewDomainModal from "./AddNewDomainModal";
import SuperAdminSidebar from "../Sidebar/SuperAdminSidebar";
import {ACTION_BUTTON_STATE} from "../../utils/enums/ActionButtonState";
import prepHeaders from "../../utils/prepHeaders";
import download from "downloadjs";
import getUserRoles from "../../utils/auth/getUserRoles";
import hasAccessLevel from "../../utils/auth/hasAccessLevel";
import {ROLES} from "../../utils/enums/Roles";
import {Redirect} from "react-router-dom";
import axios from "axios";

class DomainMeta extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    handleSampleCsvDownload()
    {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/system/domain-meta-csv-sample",
            withCredentials: true,
            method: 'GET',
            headers: prepHeaders(),
            responseType: 'blob'
        }).then((file) => {
            download(file, 'domain_meta_upload.csv')
        }).catch(error => {
            console.log(error)
        })
    }

    handleFileUpload = (e) => {
        e.persist()

        let file = e.target.files[0]
        let formData = new FormData();
        formData.append("csvUpdate", file, file.name);

        this.props.updateCsvButtonStatus(ACTION_BUTTON_STATE.WORKING)

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/system/domain-meta-update",
            withCredentials: true,
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            data: formData
        }).then(responseDate => {
            this.props.updateCsvButtonStatus(ACTION_BUTTON_STATE.DONE);

            setTimeout(() => {
                this.props.updateCsvButtonStatus(ACTION_BUTTON_STATE.DEFAULT)
            }, 2000)

            e.target.value = null
            this.props.fetchDomainList()
        }).catch(error => {
            console.log(error)
            this.props.updateCsvButtonStatus(ACTION_BUTTON_STATE.DEFAULT);
            e.target.value = null
        })

    }

    componentWillMount() {
        this.props.fetchDomainList()
    }

    handleOnSelectChange = (value) => {
        this.props.setSelectedDomain(value)
        this.props.fetchMetadataForDomain(value.id)
    }

    render() {
        if (hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) === false) {
            return (
                <Redirect to="/" />
            )
        }

        return (
          <div id="page-top">
              <div id="wrapper">
                  <SuperAdminSidebar/>
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content" className="domain-content">
                          <Topbar withBrandingHomeButton={true} withoutProjectStats={true}/>
                          <div className="container-fluid">

                              <div className="form-row">
                                  <div className="col-4">
                                      <Select
                                        options={this.props.domainMeta.list}
                                        getOptionLabel={option => option.domain}
                                        getOptionValue={option => option.id}
                                        onChange={(value) => this.handleOnSelectChange(value)}
                                        value={this.props.domainMeta.domain}
                                        placeholder="Domain name"
                                      />
                                  </div>
                                  <div className="col-4">
                                      <button onClick={() => this.setState({showModal: true})} className="btn btn-success btn-icon-split"><span
                                        className="icon text-white-50"><i className="fas fa-plus"></i></span><span
                                        className="text">Add new Domain</span>
                                      </button>
                                  </div>

                                  <div className="col-4 text-right d-flex justify-content-end align-items-center">

                                      <div>
                                        <label htmlFor="csvUploadButton" className="btn btn-info btn-icon-split mr-2">

                                            {this.props.domainMeta.csvButtonState === ACTION_BUTTON_STATE.DEFAULT &&
                                                <span className="icon text-white-50"><i className="fas fa-plus"></i></span>
                                            }

                                            {this.props.domainMeta.csvButtonState === ACTION_BUTTON_STATE.DONE &&
                                                <span className="icon text-white-50"><i className="fas fa-check"></i></span>
                                            }

                                            {this.props.domainMeta.csvButtonState === ACTION_BUTTON_STATE.WORKING &&
                                                <span className="icon text-white-50"><i className="fa fa-spinner fa-spin"></i></span>
                                            }

                                            <span className="text">Upload CSV Update <input className="d-none" type="file"/></span>
                                        </label>
                                        <input id="csvUploadButton" type="file" className="d-none"
                                            onChange={(e) => this.handleFileUpload(e)}
                                        />
                                      </div>

                                      <button onClick={(e) => this.handleSampleCsvDownload(e)} className="btn btn-outline-dark btn-icon-split mb-2">
                                          <span className="text">Sample CSV</span>
                                      </button>
                                  </div>
                              </div>

                              <AddNewDomainModal
                                showModal={this.state.showModal}
                                toggle={() => this.setState(prevState => {return {showModal: !prevState.showModal}})}
                                addNewDomainMeta={this.props.addNewDomainMeta}
                                posting={this.props.domainMeta.posting}
                              />

                              <hr/>
                              {this.props.domainMeta.fetching === true ? (
                                <div className="row">
                                    <div className="col d-flex justify-content-center align-items-center">
                                        <i className="fas fa-circle-notch fa-spin fetching-spinner"></i>
                                    </div>
                                </div>
                              ) : (
                                <>
                                    {this.props.domainMeta.domain !== null &&
                                        <EditForm
                                          key={this.props.domainMeta.domain.id}
                                          data={this.props.domainMeta.domain}
                                          saveDomainMeta={this.props.saveDomainMeta}
                                          posting={this.props.domainMeta.posting}
                                        />
                                    }
                                </>
                              )
                              }
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        domainMeta: state.domainMeta
    }
};

const mapActionToProps = (dispatch) => {
    return {
        updateCsvButtonStatus: (value) => {
            dispatch(updateCsvButtonStatus(value))
        },
        fetchDomainList: () => {
            dispatch(fetchDomainList())
        },
        setSelectedDomain: (value) => {
            dispatch(setSelectedDomain(value))
        },
        fetchMetadataForDomain: (domainId) => {
            dispatch(fetchMetadataForDomain(domainId))
        },
        saveDomainMeta: (domainId, updateData) => {
            dispatch(saveDomainMeta(domainId, updateData))
        },
        addNewDomainMeta: (domainData) => {
            dispatch(addNewDomainMeta(domainData))
        }
    }
};

export default connect(mapStateToProps, mapActionToProps)(DomainMeta);