import React, {Component} from "react";
import {compose} from "redux";
import {Redirect, withRouter} from "react-router-dom";
import SuperAdminSidebar from "../Sidebar/SuperAdminSidebar";
import Topbar from "../Topbar";
import Select from "react-select";
import {ROLES} from "../../utils/enums/Roles";
import prepHeaders from "../../utils/prepHeaders";
import getUserRoles from "../../utils/auth/getUserRoles";
import hasAccessLevel from "../../utils/auth/hasAccessLevel";
import axios from "axios";

class UserNew extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            roles: [],
            saveButtonValidationState: false
        }
    }

    validateAndSetEmail(email) {
        let saveButtonValidationState = String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )

        this.setState({...this.state, email: email, saveButtonValidationState: saveButtonValidationState})
    }

    generateRoleOption(role) {
        switch (role) {
            case ROLES.ADMIN:
                return {value: 'admin', label: 'Admin'}
            case ROLES.CLIENT:
                return {value: 'client', label: 'Client'}
            case ROLES.SUPER_ADMIN:
                return {value: 'super-admin', label: 'Super-Admin'}
            default:
                console.error("Can't parse role " + role )
        }
    }


    handleSaveButton() {
        let requestPayload = {
            roles: this.state.roles,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/user`,
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: requestPayload
        }).then(response => {
            this.props.history.push('/users/projects/' + response.data.id)
        }).catch(error => {
            console.log(error)
        })
    }


    render() {
        if (hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) === false) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <div id="page-top">
                <div id="wrapper">
                    <SuperAdminSidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs">
                            <Topbar withoutProjectStats={true} withoutBranding={true}/>
                            <div className="container">
                                <h1 className="h3 mb-0 text-white-800">User New</h1>
                                <hr/>

                                {/* NAME */}
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <label>First Name</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-user"
                                            value={this.state.firstName}
                                            onChange={ e => this.setState({...this.state, firstName: e.target.value})}
                                            onfocusout={ e => console.log(e)}
                                        />
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-user"
                                            value={this.state.lastName}
                                            onChange={ e => this.setState({...this.state, lastName: e.target.value})}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    {/* EMAIL */}
                                    <div className="col-6 mb-3">
                                        <label>Email</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-user"
                                            value={this.state.email}
                                            onChange={ e => this.validateAndSetEmail(e.target.value)}
                                        />
                                    </div>

                                    {/* ROLES */}
                                    <div className="col-6 mb-3">
                                        <label>Roles</label>
                                        <Select
                                            value={this.state.roles.map(x => this.generateRoleOption(x))}
                                            onChange={(selectedOptions) => this.setState({...this.state, roles: selectedOptions ? selectedOptions.map(option => option.value) : []})}
                                            options={['admin', 'client', 'super-admin'].map(x => this.generateRoleOption(x))}
                                            placeholder={"Roles"}
                                            isMulti
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 mt-3 d-flex justify-content-center">
                                        <button
                                            onClick={() => this.handleSaveButton()}
                                            className="btn btn-primary"
                                            disabled={!this.state.saveButtonValidationState}
                                        >
                                            <span>Save</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(
    withRouter
)(UserNew);