import React, {Component} from "react";
import {compose} from "redux";
import {Redirect, withRouter} from "react-router-dom";
import SuperAdminSidebar from "../Sidebar/SuperAdminSidebar";
import Topbar from "../Topbar";
import Select from "react-select";

import {connect} from "react-redux";
import {getSingleUser, setUserEdit} from "../../actions/userManagement/userManagementAction";

import {ROLES} from "../../utils/enums/Roles";
import prepHeaders from "../../utils/prepHeaders";
import getUserRoles from "../../utils/auth/getUserRoles";
import hasAccessLevel from "../../utils/auth/hasAccessLevel";
import axios from "axios";


class UserEdit extends Component {

    constructor(props) {
        super(props)
        this.state = this.generateStateFromProps(props)
    }


    handleSaveButton() {

        let requestPayload = {
            user_id: this.state.id,
            roles: this.state.roles,
            first_name: this.state.firstName,
            last_name: this.state.lastName
        }

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/user`,
            method: 'PUT',
            withCredentials: true,
            headers: prepHeaders(),
            data: requestPayload
        }).then(response => {
            this.setState({
                ...this.state,
                firstName: (response.data.first_name) ? response.data.first_name : '',
                lastName: (response.data.last_name) ? response.data.last_name : '',
                roles: (response.data.roles) ? response.data.roles : []
            })
        }).catch(error => {
            console.log(error)
        })
    }


    generateRoleOption(role) {
        switch (role) {
            case ROLES.ADMIN:
                return {value: 'admin', label: 'Admin'}
            case ROLES.CLIENT:
                return {value: 'client', label: 'Client'}
            case ROLES.SUPER_ADMIN:
                return {value: 'super-admin', label: 'Super-Admin'}
            default:
                console.error("Can't parse role " + role )
        }
    }


    generateStateFromProps(props) {
        return {
            email: (props.userEdit) ? (props.userEdit.email) ? props.userEdit.email : null : null,
            id: (props.userEdit) ? (props.userEdit.id) ? props.userEdit.id : null : null,
            firstName: (props.userEdit) ? (props.userEdit.first_name) ? props.userEdit.first_name : '' : '',
            lastName: (props.userEdit) ? (props.userEdit.last_name) ? props.userEdit.last_name : '' : '',
            firstLogIn: (props.userEdit) ? (props.userEdit.first_log_in) ? props.userEdit.first_log_in : null : null,
            roles: (props.userEdit) ? (props.userEdit.roles) ? props.userEdit.roles : [] : [],
            createdAt: (props.userEdit) ? (props.userEdit.created_at) ? props.userEdit.created_at : null : null,
        }
    }

    componentWillMount() {
        this.props.getSingleUser(this.props.match.params.userId)
    }

    componentWillReceiveProps(props) {
        this.setState(this.generateStateFromProps(props))
    }

    render() {
        if (hasAccessLevel(getUserRoles(), ROLES.SUPER_ADMIN) === false) {
            return (
                <Redirect to="/" />
            )
        }

        return (
            <div id="page-top">
                <div id="wrapper">
                    <SuperAdminSidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs">
                            <Topbar withoutProjectStats={true} withoutBranding={true}/>
                            {this.props.userEdit &&
                                <div className="container">
                                    <h1 className="h3 mb-0 text-white-800">Edit: <span className={'font-weight-bold'}>{this.props.userEdit.email}</span> </h1>

                                    <div className="row">
                                        <div className="col-12">
                                            <p><span className={'font-weight-bold'}>Date created</span>: {this.state.createdAt}</p>
                                        </div>
                                    </div>

                                    <hr/>

                                    {/* NAME */}
                                    <div className="row">
                                        <div className="col-6 mb-3">
                                            <label>First Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-user"
                                                value={this.state.firstName}
                                                onChange={ e => this.setState({...this.state, firstName: e.target.value})}
                                            />
                                        </div>

                                        <div className="col-6 mb-3">
                                            <label>Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-user"
                                                value={this.state.lastName}
                                                onChange={ e => this.setState({...this.state, lastName: e.target.value})}
                                            />
                                        </div>
                                    </div>

                                    {/* ROLES */}
                                    <div className="row">
                                        <div className="col-6 mb-3">
                                            <label>Roles</label>
                                            <Select
                                                value={this.state.roles.map(role => this.generateRoleOption(role))}
                                                onChange={(selectedOptions) => this.setState({...this.state, roles: selectedOptions ? selectedOptions.map(option => option.value) : []})}
                                                options={['admin', 'client', 'super-admin'].map(x => this.generateRoleOption(x))}
                                                placeholder={"Roles"}
                                                isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 mt-3 d-flex justify-content-center">
                                            <button onClick={() => this.handleSaveButton()} className="btn btn-primary">
                                                <span>Save</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userEdit: state.userManagement.userEdit,
        formData: {...state.userManagement.userEdit}
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        getSingleUser: (userId) => {dispatch(getSingleUser(userId))}
    }
}

export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(UserEdit);