import React, {Component, Fragment} from 'react';
import moment from "moment"

class AutomatedStats extends Component {

    render() {
        if (this.props.row.stats === null) {
            return <div style={{
                    color: 'grey',
                    overflow: 'hidden',
                    whiteSpace: 'wrap',
                    textOverflow: 'ellipses',
                    textAlign: 'left',
                    padding: '15px 0'
            }}>
                Not Available
            </div>
        }
        return (
          <Fragment>
              {this.props.row.stats &&
              <div style={{
                  color: 'grey',
                  overflow: 'hidden',
                  whiteSpace: 'wrap',
                  textOverflow: 'ellipses',
                  textAlign: 'left',
                  padding: '15px 0'
              }}>
                    <Fragment>
                        <div className="row">
                            <div className="col-6">
                                <p> Last run: {this.props.row.last_auto_run_date ? moment(this.props.row.last_auto_run_date).format("DD-M-Y") : 'N/A'}</p>
                                <p style={{marginBottom: 0}}>
                                    Auto-shortlisted: {this.props.row.stats.last_run.auto_shortlisted ? this.props.row.stats.last_run.auto_shortlisted : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Auto-rejected: {this.props.row.stats.last_run.auto_rejected ? this.props.row.stats.last_run.auto_rejected : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Shortlisted: {this.props.row.stats.last_run.shortlisted ? this.props.row.stats.last_run.shortlisted : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Confirmed: {this.props.row.stats.last_run.confirmed ? this.props.row.stats.last_run.confirmed : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Enforced: {this.props.row.stats.last_run.enforced ? this.props.row.stats.last_run.enforced : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Rejected: {this.props.row.stats.last_run.rejected ? this.props.row.stats.last_run.rejected : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Query: {this.props.row.stats.last_run.query ? this.props.row.stats.last_run.query : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Incorrect: {this.props.row.stats.last_run.incorrect ? this.props.row.stats.last_run.incorrect : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Counterfeit: {this.props.row.stats.last_run.counterfeit ? this.props.row.stats.last_run.counterfeit : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Staging: {this.props.row.stats.last_run.staging ? this.props.row.stats.last_run.staging : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Current Month: {this.props.row.stats.last_run.current_month ? this.props.row.stats.last_run.current_month : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Previous Months: {this.props.row.stats.last_run.previous_months ? this.props.row.stats.last_run.previous_months : 0}</p>
                            </div>
                            <div className="col-6">
                                <p>Lifetime: {this.props.row.created ? moment(this.props.row.created).format("DD-M-Y") : 'N/A'}</p>
                                <p style={{marginBottom: 0}}>
                                    Auto-shortlisted: {this.props.row.stats.cumulative.auto_shortlisted ? this.props.row.stats.cumulative.auto_shortlisted : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Auto-rejected: {this.props.row.stats.cumulative.auto_rejected ? this.props.row.stats.cumulative.auto_rejected : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Shortlisted: {this.props.row.stats.cumulative.shortlisted ? this.props.row.stats.cumulative.shortlisted : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Confirmed: {this.props.row.stats.cumulative.confirmed ? this.props.row.stats.cumulative.confirmed : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Enforced: {this.props.row.stats.cumulative.enforced ? this.props.row.stats.cumulative.enforced : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Rejected: {this.props.row.stats.cumulative.rejected ? this.props.row.stats.cumulative.rejected : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Query: {this.props.row.stats.cumulative.query ? this.props.row.stats.cumulative.query : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Incorrect: {this.props.row.stats.cumulative.incorrect ? this.props.row.stats.cumulative.incorrect : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Counterfeit: {this.props.row.stats.cumulative.counterfeit ? this.props.row.stats.cumulative.counterfeit : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Staging: {this.props.row.stats.cumulative.staging ? this.props.row.stats.cumulative.staging : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Current Month: {this.props.row.stats.cumulative.current_month ? this.props.row.stats.cumulative.current_month : 0}</p>
                                <p style={{marginBottom: 0}}>
                                    Previous Months: {this.props.row.stats.cumulative.previous_months ? this.props.row.stats.cumulative.previous_months : 0}</p>
                            </div>
                        </div>
                    </Fragment>
              </div>
              }
          </Fragment>
        )
    }
}

export default AutomatedStats;