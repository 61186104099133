import React, {Component, Fragment} from 'react';
import DataTable, {memoize} from 'react-data-table-component';
import ListItemTitle from './searchList/ListItemTitle';
import ListItemQuery from './searchList/ListItemQuery';
import ProcessStatus from './searchList/ProcessStatus';
import EditButton from './searchList/EditButton';
import RerunButton from './searchList/RerunButton';
import SearchListHeaderMenu from './searchList/HeaderMenu';
import {doSearchTermIsAutomated, fetchQueryList} from "../../actions/query/queryAction";
import { connect } from 'react-redux';
import AutomatedLabel from "./searchList/AutomatedLabel";
import AutomatedStats from "./searchList/AutomatedStats";
import SearchListNoData from "./SearchListNoData";

const columns = memoize((projectId, handleModalToggle, handleRerun, doSearchTermIsAutomated) => [
    {
        name: 'Name',
        selector: 'name',
        cell: (row) => <ListItemTitle row={row}/>,
        maxWidth: '300px',
    },
    {
        name: 'Query details',
        selector: 'query',
        cell: (row) => <ListItemQuery row={row}/>,
        wrap: true,
    },
    {
        name: 'Automated Stats',
        selector: '',
        cell: (row) => <AutomatedStats row={row}/>,
        center: true,
        width:'600px'
    },
    {
        name: 'Process status',
        selector: 'process_status',
        cell: (row) => <ProcessStatus row={row}/>,
        center: true,
        maxWidth: '50px',
    },
    {
        name: 'Automated',
        selector: 'automated',
        cell: (row) => <AutomatedLabel row={row} doSearchTermIsAutomated={doSearchTermIsAutomated}/>,
        center: true,
        maxWidth: '150px',
    },
    {
        name: 'Edit',
        cell: (row) => <EditButton row={row} handleModalToggle={handleModalToggle}/>,
        maxWidth: '50px',
    },
    {
        name: 'Rerun',
        cell: (row) => <RerunButton row={row} handleRerun={handleRerun}/>,
        maxWidth: '50px',
        center: true,
    },
]);

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filteredText: '',
            searchType: 'title'
        }
    }

    componentDidMount() {
        this.props.fetchQueryList(this.props.projectId)
        this.interval = setInterval(() => this.props.fetchQueryList(this.props.projectId), 5000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleOnFilterTextChange = (value) => {
        this.setState({
            filteredText: value
        })
    };

    onSearchTypeChange = e => {
        this.setState({
            searchType: e.target.value
        })
    }

    handleModalToggle = (searchTermId) => {

        let searchTerm = this.props.query.list.filter(searchTerm => searchTerm.id === searchTermId)

        this.props.setSearchTerm(searchTerm[0]);
        this.props.toggleSearchTermModal(true)
    };

    render() {
        let filteredItems = this.props.query.list.filter(item => item.name && item.name.toLowerCase().includes(this.state.filteredText.toLowerCase()));

        if (this.state.searchType === 'keywords' && this.state.filteredText !== '') {
            filteredItems = this.props.query.list.filter(item => item.query && item.query.keywords.toString().toLowerCase().includes(this.state.filteredText.toLowerCase()));
        }

        return (
          <Fragment>
              <div className="card shadow">
                  <DataTable
                    columns={columns(this.props.projectId, this.handleModalToggle, this.props.rerunQuery, this.props.doSearchTermIsAutomated)}
                    data={filteredItems}
                    keyField={'id'}
                    pagination
                    paginationPerPage={30}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 75, 100]}
                    defaultSortField={'created'}
                    defaultSortAsc={false}
                    // noDataComponent={<SearchListNoData />}
                    noDataComponent={"No data"}
                    subHeader
                    subHeaderComponent={
                        <SearchListHeaderMenu
                          searchType={this.state.searchType}
                          onSearchTypeChange={this.onSearchTypeChange}
                          value={this.state.filteredText}
                          handleOnFilterTextChange={this.handleOnFilterTextChange}
                          toggleSearchTermModal={this.props.toggleSearchTermModal}
                        />}
                  />
              </div>
          </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        query: state.query,
    }
};

const mapActionToProps = (dispatch) => {
    return {
        fetchQueryList: (projectId) => {dispatch(fetchQueryList(projectId))},
        doSearchTermIsAutomated: (searchTermId, searchTermIsAutomatedStatus) => {dispatch(doSearchTermIsAutomated(searchTermId, searchTermIsAutomatedStatus))},
    }
};

export default connect(mapStateToProps, mapActionToProps)(List);
