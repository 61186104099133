export const TOGGLE_SEARCH_TERM_MODAL = 'query:toggleSearchTermModal';
export const SET_SEARCH_TERM_FOR_MODAL = 'query:setSearchTermForModal';

export function toggleSearchTermModal(status) {
    return {
        type: TOGGLE_SEARCH_TERM_MODAL,
        payload: {
            showSearchTermModal: status
        }
    }
}

export function setSearchTerm(searchTerm) {
    return {
        type: SET_SEARCH_TERM_FOR_MODAL,
        payload: {
            searchTerm
        }
    }
}