import prepHeaders from "../../utils/prepHeaders";
import axios from "axios";

export const SET_FETCHING_IMPORT_PROJECTS = 'importProject:fetchingImportProjects';
export const SET_IMPORT_PROJECTS = 'importProject:setImportProjects';
export const SET_IMPORT_PROJECT = 'importProject:setImportProject';
export const UPDATE_IMPORT_PROJECT_CSV_BUTTON_STATUS =  'importProject:updateCsvButtonStatus'

export function setFetchingImportProjects(status)
{
    return {
        type: SET_FETCHING_IMPORT_PROJECTS,
        payload: {
            status
        }
    }
}

export function setImportProjects(importProjects)
{
    return {
        type: SET_IMPORT_PROJECTS,
        payload: {
            importProjects
        }
    }
}

export function setImportProject(importProject)
{
    return {
        type: SET_IMPORT_PROJECT,
        payload: {
            importProject
        }
    }
}

export function updateImportProjectCsvButtonStatus(status)
{
    console.log("buttonStatus: ", status)
    return {
        type: UPDATE_IMPORT_PROJECT_CSV_BUTTON_STATUS,
        payload: {
            status
        }
    }
}

export const getImportProject = (importProjectId) => {
    return dispatch => {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/import-project/` + importProjectId,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setImportProject(response.data))
        }).catch(error => {
            console.log(error)
        })
    }
}

export const getAllImportProjects = () => {
    return dispatch => {
        dispatch(setFetchingImportProjects(true))

        axios({
            url: process.env.REACT_APP_API_BASE_URL + `/import-projects`,
            method: 'GET',
            withCredentials: true,
            headers: prepHeaders(),
        }).then(response => {
            dispatch(setImportProjects(response.data))
            dispatch(setFetchingImportProjects(false))
        }).catch(error => {
            console.log(error)
            dispatch(setFetchingImportProjects(false))
        })
    }
}
