import React, {Component, Fragment} from 'react';
import TemplateSection from "./TemplateSection"
import determineProjectTemplate from '../../utils/projectTemplates/determineProjectTemplate';

class SingleSearchResultCardViewer extends Component {

    render() {

        let template = determineProjectTemplate(this.props.imageData)

        return (
          <Fragment>
              {this.props.fetching ?
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <i className="fas fa-circle-notch fa-spin fetching-spinner"></i>
                    </div>
                </div>
                :
                <div className="card shadow mb-4 svs-card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col">
                                <span className="m-0 font-weight-bold text-primary">ID: {this.props.imageData.id}</span>
                            </div>
                            <div className="col m-0 font-weight-bold text-primary text-right">
                                <span className="mb-0 mr-3">{this.props.imageData.reviewed_by_client ? <span className="badge badge-success">Reviewed</span> : <span className="badge badge-warning">Not reviewed</span>}</span>
                                <span className="mb-0">{this.props.currentIndex + 1} / {this.props.listLength}</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4 left-svs-col">
                                {template.sections.map((templateSection) => {
                                    if (templateSection.name === "Summary") {
                                        return (
                                          <div className="col-12 summary-col" key={templateSection.name}>
                                              {templateSection.name === "Summary" &&
                                              <TemplateSection
                                                sectionTitle={templateSection.name}
                                                fields={templateSection.fields}
                                                imageData={this.props.imageData}
                                                fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
                                                setNotes={this.props.setNotes}
                                                saveNotes={this.props.saveNotes}
                                                notesValue={this.props.notesValue}
                                              />
                                              }
                                          </div>
                                        )
                                    }

                                })
                                }
                            </div>
                            <div className="col-8 right-svs-col">
                                {template.sections.map((templateSection) => {
                                    if (templateSection.name !== "Summary") {
                                        return (
                                          <div className="col-12 sections-col" key={templateSection.name}>
                                              <TemplateSection
                                                sectionTitle={templateSection.name}
                                                fields={templateSection.fields}
                                                imageData={this.props.imageData}
                                                fetchUpdatedFeatures={this.props.fetchUpdatedFeatures}
                                                setNotes={this.props.setNotes}
                                                saveNotes={this.props.saveNotes}
                                                notesValue={this.props.notesValue}
                                              />
                                          </div>
                                        )
                                    }
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
              }
          </Fragment>
        )
    }
}

export default SingleSearchResultCardViewer
