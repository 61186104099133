import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Sidebar from './Sidebar/Sidebar';
import Topbar from './Topbar';
import ReportsList from './ffReports/List';
import ReportListModal from './ffReports/ReportListModal';
import {toggleFFModal, generateFFReport} from '../actions/ffReports/ffReportsAction'

class FFReports extends Component {

    render() {
        return (
            <Fragment>
                <div id="page-top">
                    <div id="wrapper">
                        <Sidebar/>
                        <div id="content-wrapper" className="d-flex flex-column">
                            <div id="content" className="reports-content">

                                <Topbar />
                                <div className="container-fluid">
                                    <ReportListModal
                                        modalToggleStatus={this.props.ffReports.modalStatus}
                                        toggleModal={this.props.toggleModal}
                                        statusOptions={this.props.status.statusOptions}
                                        projectId={this.props.match.params.projectId}
                                        doGenerateReport={this.props.doGenerateFFReport}
                                    />
                                    <ReportsList
                                        projectId={this.props.match.params.projectId}
                                        toggleModal={this.props.toggleModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ffReports: state.ffReports,
        status: state.status
    }
};

const mapActionToProps = (dispatch) => {
    return {
        toggleModal: (status) => {dispatch(toggleFFModal(status))},
        doGenerateFFReport: (data, projectId) => {dispatch(generateFFReport(data, projectId))}
    }
};

export default connect(mapStateToProps, mapActionToProps)(FFReports);
