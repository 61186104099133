import React, {Component} from "react";
import {NavLink} from "react-router-dom";

class ImportProjectRow extends Component {
    render () {
        return (
            <div className="row">
                <div className="col-10">
                    <p className={'font-weight-bold text-dark'}>{this.props.display_name}</p>
                </div>
                <div className="col-2 text-right">
                    <NavLink to={"/import-project/" + this.props.id} className="btn btn-primary image-info-btn mr-2"><i className="fa fa-edit"/></NavLink>
                </div>
            </div>
        )
    }
}

export default ImportProjectRow