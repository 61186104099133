import React, {Component} from "react";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar";
import {connect} from "react-redux";
import {doFetchSummaryList, doGenerateSummary, doPublishStatus} from "../../actions/summary/summary";
import DataTable from "react-data-table-component";
import memoize from 'memoize-one';
import {Link} from "react-router-dom";
import moment from 'moment';
import SummaryListHeaderMenu from "./parts/SummaryListHeaderMenu";
import Publish from "./parts/Publish";
import NewSummaryModal from "./parts/NewSummaryModal";

const columns = memoize((doPublishStatus) => [
    {
        name: 'Title',
        selector: 'title',
        cell: row => <Link to={`/summary/${row.id}`}>{row.title}</Link>,
        // maxWidth: '',
    },
    {
        name: 'Period',
        selector: 'from_period',
        cell: row => row.from && `${moment(row.from).format('DD/MM/YYYY')} - ${moment(row.to).format('DD/MM/YYYY')}`,
        // maxWidth: '',
    },
    {
        name: 'Created',
        selector: 'created',
        cell: (row) => moment(row.created).format('DD/MM/YYYY'),
    },
    {
        name: 'Published',
        selector: 'published',
        cell: (row) => <Publish doPublishStatus={doPublishStatus} row={row}/>,
    }
]);

class Summary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filteredText: '',
            visibility: false,
            toDate: null,
            fromDate: null
        }
    }

    componentDidMount() {
        this.props.doFetchSummaryList();
    }

    handleModalVisibility = () => {
        this.setState((prevState) => ({visibility: !prevState.visibility}))
    }

    onToDateChange = date => {
        this.setState({
            toDate: date
        })
    }

    onFromDateChange = date => {
        this.setState({
            fromDate: date
        })
    }

    handleFilterItems = () => {

        let filteredItems = [...this.props.summary.summaryList]

        if (this.state.fromDate !== null) {
            filteredItems = this.props.summary.summaryList.filter(summary => new Date(summary.from) >= new Date(this.state.fromDate))
        }

        if (this.state.toDate !== null) {
            filteredItems = this.props.summary.summaryList.filter(summary => new Date(summary.to) <= new Date(this.state.toDate))
        }

        if (this.state.fromDate !== null && this.state.toDate !== null) {
            filteredItems = this.props.summary.summaryList.filter(summary => new Date(summary.from) >= new Date(this.state.fromDate) && new Date(summary.from) <= new Date(this.state.toDate))
        }

        return filteredItems
    }

    render() {
        // const filteredItems = this.props.summary.summaryList.filter(item => item.year && item.name.includes(this.state.filteredText));
        return (
          <div id="page-top">
              <div id="wrapper">
                  <Sidebar/>
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content" className="summary-content">
                          <Topbar/>
                          <div className="container-fluid">
                              <div className="card shadow">
                                  <DataTable
                                    columns={columns(this.props.doPublishStatus)}
                                    data={this.handleFilterItems()}
                                    // data={this.props.summary.summaryList}
                                    keyField={'id'}
                                    pagination
                                    paginationPerPage={30}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 75, 100]}
                                    defaultSortField={'created'}
                                    defaultSortAsc={false}
                                    noDataComponent={"No summary reports"}
                                    subHeader
                                    subHeaderComponent={<SummaryListHeaderMenu
                                      toDate={this.state.toDate}
                                      fromDate={this.state.fromDate}
                                      onToDateChange={this.onToDateChange}
                                      onFromDateChange={this.onFromDateChange}
                                      handleModalVisibility={this.handleModalVisibility}
                                      fetching={this.props.summary.fetchingGenerate}
                                    />}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                  <NewSummaryModal
                    visibility={this.state.visibility}
                    handleModalVisibility={this.handleModalVisibility}
                    doGenerateSummary={this.props.doGenerateSummary}
                  />
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        summary: state.summary,
    }
};

const mapActionToProps = (dispatch) => {
    return {
        doFetchSummaryList: () => {
            return dispatch(doFetchSummaryList())
        },
        doPublishStatus: (status, summaryId) => {
            return dispatch(doPublishStatus(status, summaryId))
        },
        doGenerateSummary: (data) => {
            dispatch(doGenerateSummary(data))
        }
    }
};

export default connect(mapStateToProps, mapActionToProps)(Summary);