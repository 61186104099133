import * as summaryActions from '../actions/summary/summary'

const initialState = {
    summaryList: [],
    fetching: false,
    fetchingGenerate: false,
    summary: null,
    summaryFetching: false
};

export default function summaryReducer(state = initialState, action) {
    switch (action.type) {
        case summaryActions.SET_FETCHING:
            return {
                ...state,
                fetching: action.payload.status
            };
        case summaryActions.SET_FETCHING_GENERATE:
            return {
                ...state,
                fetchingGenerate: action.payload.status
            };
        case summaryActions.SET_SUMMARY_LIST:
            return {
                ...state,
                summaryList: action.payload.summaryListData
            };
        case summaryActions.SET_SUMMARY:
            return {
                ...state,
                summary: action.payload.summary
            };
        case summaryActions.SET_SUMMARY_FETCHING:
            return {
                ...state,
                summaryFetching: action.payload.summaryFetching
            };
        case summaryActions.ADD_SUMMARY_TO_LIST:
            return {
                ...state,
                summaryList: [action.payload.summary, ...state.summaryList]
            }
        default:
            return state

    }
}