import {TOGGLE_SEARCH_TERM_MODAL, SET_SEARCH_TERM_FOR_MODAL} from "../actions/searchTerm/searchTermActions";

const initialState = {
    searchTerm: null,
    showSearchTermModal: false
}

export default function searchTermReducer(state = initialState, action) {
    switch (action.type) {

        case TOGGLE_SEARCH_TERM_MODAL:
            return {
                ...state,
                showSearchTermModal: action.payload.showSearchTermModal
            };
        case SET_SEARCH_TERM_FOR_MODAL:
            return {
                ...state,
                searchTerm: action.payload.searchTerm
            };
        default:
            return state
    }
}