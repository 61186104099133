import React, {Component} from "react";
import userProfilePic from '../assets/images/dupe-killer-profile.png';
import {loggingOut} from '../actions/authentication/authenticationAction';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {SsoSignOutButton} from "./sso/SsoSignOutButton";
import TopBarAdminCogMenu from "./TopBarAdminCogMenu";
import {fetchProjectStats} from "../actions/clientAndProjectList/clientAndProjectListAction";
import {fetchShortlistBasedOnFilters, setRemoteStatus, setStatusFilter} from "../actions/filter/filterAction";
import {getStatusFilterOptions} from "../utils/status/statusFilterOptions";
import prepareFilters from "../utils/filters/prepareFilters";

class Topbar extends Component {

    handleLogout = () => {
        this.props.handleLogout()
    }

    onStatusClick = (e, statusValue) => {
        e.preventDefault()
        this.props.setRemoteStatus(statusValue)
        this.props.setStatusFilter(statusValue)
        this.props.history.push(`/project/${JSON.parse(localStorage.getItem('project')).id}/shortlist`)

        if (this.props.location.pathname === `/project/${JSON.parse(localStorage.getItem('project')).id}/shortlist`) {
            let filters = prepareFilters(this.props.filter)
            filters.currentPageValue = 1
            filters.itemsCountPerPage = 40
            filters.status = statusValue.value
            this.props.fetchShortlistBasedOnFilters(this.props.match.params.projectId, filters, this.props.images.selectedShortlist);
        }
    }

    render() {

        const statsFontStyles = 'stat';
        const statsContainerClasses = 'd-flex flex-column align-items-center justify-content-center text-center mx-1 stat-object'

        return (
            <nav className="navbar navbar-nav navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>

                {this.props.project.selectedProject &&
                    <>
                        <li className="nav-item project-title">{this.props.project.selectedProject.display_name}</li>
                        <div className="topbar-divider d-none d-sm-block"></div>
                    </>
                }


                <div className="stats-container">

                    {this.props.withoutProjectStats !== true &&
                        <>
                            {this.props.project.fetchingStats !== true ?
                                (
                                    <>
                                        {this.props.project.projectStats &&
                                            <>
                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[4])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Shortlisted</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.shortlisted}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[1])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Auto shortlisted</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.auto_shortlisted}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[5])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Rejected</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.rejected}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[2])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Auto rejected</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.auto_rejected}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[6])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Confirmed</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.confirmed}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[7])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Staging</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.staging}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[3])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Query</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.query}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[8])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Incorrect</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.incorrect}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[9])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Counterfeit</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.counterfeit}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[10])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Current month</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.current_month}</span>
                                                </div>

                                                <div onClick={(e) => this.onStatusClick(e, getStatusFilterOptions()[12])} className={statsContainerClasses}>
                                                    <span className={statsFontStyles}>Previous Months</span>
                                                    <span
                                                        className={statsFontStyles}>{this.props.project.projectStats.previous_months}</span>
                                                </div>
                                            </>
                                        }
                                    </>

                                ) : (
                                    <div className="d-flex justify-content-center align-items-center mx-auto">
                                        <i className="fas fa-circle-notch fa-spin spinner mr-2"></i>
                                        <span>Fetching project stats...</span>
                                    </div>
                                )
                            }
                        </>
                    }

                </div>
                <div className="topbar-divider d-none d-sm-block"></div>
                <TopBarAdminCogMenu/>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span
                                className="mr-2 d-none d-lg-inline text-gray-600 small">{this.props.authentication.email}</span>
                            <img alt="profile pic" className="img-profile rounded-circle" src={userProfilePic}/>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                             aria-labelledby="userDropdown">
                            <SsoSignOutButton handleLogout={this.props.handleLogout}/>
                        </div>
                    </li>
                </ul>
            </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
        project: state.clientAndProjectList,
        images: state.images,
        filter: state.filter
    }
};

const mapActionsToProps = (dispatch, ownProps) => {
    return {
        handleLogout: () => {
            dispatch(loggingOut(ownProps.history))
        },
        fetchProjectStats: (projectId) => {
            dispatch(fetchProjectStats(projectId))
        },
        setRemoteStatus: (value) => {
            dispatch(setRemoteStatus(value))
        },
        setStatusFilter: (value) => {
            dispatch(setStatusFilter(value))
        },
        fetchShortlistBasedOnFilters: (value, projectId, filters) => {
            dispatch(fetchShortlistBasedOnFilters(value, projectId, filters))
        },
    }
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapActionsToProps),
)(Topbar);