import React, {Component} from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {fetchAuthData, fetchAuthDataSso} from "../actions/authentication/authenticationAction";
import {SignInButton} from "./sso/SsoSignInButton";

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
        }
    }

    onLoginSubmit = e => {
        e.preventDefault()
        this.props.onLoginSubmit(this.state.email, this.state.password)
    }

    render() {
        return (
            <div className="container login-page">
                <div className="row justify-content-center min-vh-100 align-items-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                    <div className="col-lg-6 d-flex flex-column justify-content-center">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Admin Login</h1>
                                            </div>
                                            {this.props.authentication.authError &&
                                            <div
                                                className="alert alert-danger bg-gradient-danger text-gray-100">{this.props.authentication.authError}</div>
                                            }
                                            {this.props.authentication.sessionExpiredStatus &&
                                            <div
                                                className="alert alert-warning bg-gradient-warning text-gray-100">Your session has expired. Please login again.</div>
                                            }
                                            <form formMethod="POST" onSubmit={this.onLoginSubmit} className="user">
                                                {process.env.REACT_APP_ENV !== 'production' &&
                                                <>
                                                    <div className="form-group">
                                                        <input onChange={e => this.setState({email: e.target.value})}
                                                               value={this.state.email} type="email"
                                                               className="form-control form-control-user"
                                                               id="exampleInputEmail" aria-describedby="emailHelp"
                                                               placeholder="Enter Email Address..."/>
                                                    </div>
                                                    <div className="form-group">
                                                        <input onChange={e => this.setState({password: e.target.value})}
                                                               value={this.state.password} type="password"
                                                               className="form-control form-control-user"
                                                               id="exampleInputPassword" placeholder="Password"/>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary btn-user btn-block">
                                                        Login
                                                    </button>
                                                </>
                                                }
                                                <SignInButton onLoginSubmit={this.props.onLoginSubmitSso} />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state
};

const mapActionsToProps = (dispatch, ownProps) => {
    return {
        onLoginSubmit: (email, password, history) => {
            dispatch(fetchAuthData(email, password, ownProps.history))
        },
        onLoginSubmitSso:(idToken, history)=> {
            dispatch(fetchAuthDataSso(idToken, ownProps.history))
        }
    }
};

export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(Login);