import React, {Component} from "react";
import {connect} from "react-redux";
import {
    createDownloadEntry, downloadImages,
    fetchDownloadList
} from "../../actions/imagesDownload/imagesDownloadActions";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar";
import DataTable, {memoize} from "react-data-table-component";
import ProcessStatus from "../reports/reportsList/ProcessStatus";
import DownloadLink from "../reports/reportsList/DownloadLink";
import ImagesDownloadTableHeader from "./ImagesDownloadTableHeader";
import {labelFromStatusCode, getStatusFilterOptions} from "../../utils/status/statusFilterOptions";
import DownloadImagesModal from "./DownloadImagesModal";
import moment from "moment";

const columns = memoize((projectId, downloadImages) => [
    {
        name: 'Name',
        selector: 'name',
        cell: (row) => row.title,
        maxWidth: '300px',
    },
    {
        name: 'Status',
        selector: 'status',
        center: true,
        cell: (row) => labelFromStatusCode(row.status),
    },
    {
        name: 'Created',
        selector: 'created',
        cell: (row) => moment(row.created).format('D-MM-YYYY'),
        center: true,
    },
    {
        name: 'Process status',
        selector: 'process_status',
        cell: (row) => <ProcessStatus row={row}/>,
        center: true,
    },
    {
        name: 'Download',
        selector: '',
        cell: (row) => <DownloadLink key={row.id} projectId={projectId} download={downloadImages} row={row}/>,
        center: true,
        maxWidth: '50px',
    },
]);

class List extends Component {

    constructor(props) {
        super(props);

        this.state = this.getInitialState()
    }

    getInitialState = () => {
        return {
            selectedStatus: getStatusFilterOptions()[4],
            showModal: false
        }
    }

    componentDidMount() {
        this.props.fetchDownloadList()
        this.interval = setInterval(() => this.props.fetchDownloadList(), 5000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleModalToggle = status => {
        this.setState({
            showModal: status
        })
    }

    render() {
        return (
          <div id="page-top">
              <div id="wrapper">
                  <Sidebar/>
                  <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content" className="images-download-content">
                          <Topbar/>
                          <div className="container-fluid">

                              {this.props.imagesDownload.downloading &&
                                  <div className="overlay text-center d-flex align-items-center justify-content-center">
                                      <i className="fas fa-circle-notch fa-spin fetching-spinner mt-5"></i>
                                  </div>
                              }

                              <div className="card shadow">
                                 <DataTable
                                    columns={columns(JSON.parse(localStorage.getItem('project')).id, this.props.downloadImages)}
                                    data={this.props.imagesDownload.list}
                                    keyField={'id'}
                                    pagination
                                    paginationPerPage={30}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 75, 100]}
                                    // defaultSortField={'created'}
                                    defaultSortAsc={false}
                                    noDataComponent={"No image records"}
                                    subHeader
                                    // subHeaderAlign={'right'}
                                    subHeaderComponent={
                                        <ImagesDownloadTableHeader
                                            toggleModal={this.handleModalToggle}
                                            selectedStatus={this.state.selectedStatus}
                                            setSelectedStatus={selectedStatus => this.setState({selectedStatus})}
                                        />
                                    }
                                  />
                              </div>

                              <DownloadImagesModal
                                  modalToggleStatus={this.state.showModal}
                                  toggleModal={this.handleModalToggle}
                                  projectId={'some-project-id'}
                                  createDownloadEntry={this.props.createDownloadEntry}
                              />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        imagesDownload: state.imagesDownload
    }
};

const mapActionToProps = (dispatch) => {
    return {
        fetchDownloadList: () => {dispatch(fetchDownloadList())},
        createDownloadEntry: (status, title) => {dispatch(createDownloadEntry(status, title))},
        downloadImages: downloadEntryId => {dispatch(downloadImages(downloadEntryId))}
    }
};

export default connect(mapStateToProps, mapActionToProps)(List)