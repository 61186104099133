import prepHeaders from '../../utils/prepHeaders';
import axios from "axios";

export const FETCH_MODELS = 'models:fetch';
export const SELECT_MODEL = 'models:select';
export const SELECT_MENU_MODEL = 'models:selectMenu';

export function fetchModels(list) {
    return {
        type: FETCH_MODELS,
        payload: {
            list
        }
    }
}

export function setSelectedModel(selectedValue) {
    return {
        type: SELECT_MODEL,
        payload: {
            selectedValue
        }
    }
}

export function setSelectedMenuModel(selectedMenuModelValue) {
    return {
        type: SELECT_MENU_MODEL,
        payload: {
            selectedMenuModelValue
        }
    }
}

export const doFetchModels = (projectId) => {

    return dispatch => {
        
        let payload = {
            project: projectId
        };

        axios({
            url: process.env.REACT_APP_API_BASE_URL + "/list-models",
            method: 'POST',
            withCredentials: true,
            headers: prepHeaders(),
            data: payload
        }).then(response => {
            dispatch(fetchModels(response.data.reverse()))
            dispatch(setSelectedModel(response.data[0].id))
            dispatch(setSelectedMenuModel(response.data[0].id))
        }).catch(error => {
            console.log(error)
        })
    }
}
