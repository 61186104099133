import * as domainMetaActions from '../actions/domainMeta/domainMeta';
import {ACTION_BUTTON_STATE} from "../utils/enums/ActionButtonState";

const initialState = {
    list: [],
    fetching: false,
    posting: false,
    selectedDomain: null,
    domain: null,
    csvButtonState: ACTION_BUTTON_STATE.DEFAULT
};

export default function domainMetaReducer(state = initialState, action) {
    switch (action.type) {
        case domainMetaActions.FETCHING:
            return {
                ...state,
                fetching: action.payload.status
            }

        case domainMetaActions.POSTING:
            return {
                ...state,
                posting: action.payload.status
            }

        case domainMetaActions.SET_SELECTED_DOMAIN:
            return {
                ...state,
                selectedDomain: action.payload.value
            }

        case domainMetaActions.SET_DOMAIN_LIST:
            return {
                ...state,
                list: action.payload.list
            }

        case domainMetaActions.SET_DOMAIN_DATA:
            return {
                ...state,
                domain: action.payload.domainData
            }

        case domainMetaActions.UPDATE_CSV_BUTTON_STATUS:
            return {
                ...state,
                csvButtonState: action.payload.buttonStatus
            }
        default:
            return state
    }
}
