import React, {Component, Fragment} from 'react';
import Modal from 'react-bootstrap4-modal';
import Toggle from 'react-toggle'

class SearchTermModal extends Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            if (nextProps.searchTerm !== null) {

                this.setState(
                  {
                      name: nextProps.searchTerm.name,
                      automated: nextProps.searchTerm.automated !== 0,
                      queryAllOFTheseWords: nextProps.searchTerm.query.keywords.join(', '),
                      queryNoneOfTheseWords: nextProps.searchTerm.query.omit.join(', '),
                      queryTheseExactWords: nextProps.searchTerm.query.exact_expression.join(', '),
                      queryAnyOfTheseWords: nextProps.searchTerm.query.alternative.join(', '),
                      limit: nextProps.searchTerm.query.limit,
                      scoreThreshold: nextProps.searchTerm.score_threshold || 70,
                      model_id: nextProps.searchTerm.model_id || '',
                      perFeatureScoreThreshold: nextProps.searchTerm.per_feature_score_threshold || false,
                      featureScoreThresholds: nextProps.searchTerm.feature_score_thresholds || {}
                  }
                );
            } else {
                this.setState(this.getInitialState())
            }
        }
    }

    getInitialState = () => {

        let initialState = {
            name: '',
            automated: false,
            queryAllOFTheseWords: '',
            queryNoneOfTheseWords: '',
            queryTheseExactWords: '',
            queryAnyOfTheseWords: '',
            limit: 40,
            scoreThreshold: 70,
            model_id: '',
            perFeatureScoreThreshold: false,
        }

        initialState.featureScoreThresholds = {}

        this.props.features.forEach(feature => {
            initialState.featureScoreThresholds[feature.label] = 70
        })

        return initialState
    };

    onToggle = () => {
        this.setState({automated: !this.state.automated});
        this.setState({model_id: this.props.modelList[0].id})
    }

    onPerFeatureToggle = () => {
        this.setState({perFeatureScoreThreshold: !this.state.perFeatureScoreThreshold});
    }

    handleModalToggle = () => {
        this.props.toggleSearchTermModal(false)
        this.props.setSearchTerm(null)
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.doQuery(this.state, this.props.projectId);
    };

    handleEdit = (e) => {
        e.preventDefault();
        this.props.doQueryEdit(this.state, this.props.searchTerm.id)
    };

    handleOnFeatureScoreThresholdChange = (e, feature) => {
        let theValue = e.target.value

        this.setState(prevState => {

            let copyOfPrevState = {...prevState}

            copyOfPrevState.featureScoreThresholds[feature] = parseInt(theValue)

            return {...copyOfPrevState}
        })
    }

    render() {
        let modalTitle = this.props.searchTerm ? 'Edit search' : 'New search';
        return (
          <div className="query-modal">
              <Modal dialogClassName="modal-lg" visible={this.props.modalToggleStatus} onClickBackdrop={() => {
                  this.handleModalToggle()
              }}>
                  <div className="modal-header">
                      <h5 className="modal-title">{modalTitle}</h5>
                  </div>
                  <div className="modal-body">
                      <Fragment>
                          <div className="form-group row">
                              <div className="col-md-6">
                                  <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                          <span className="input-group-text" id="">Name</span>
                                      </div>
                                      <input onChange={e => this.setState({name: e.target.value})} value={this.state.name}
                                             type="text" className="form-control form-control-user"
                                             placeholder="A search for oversize sneakers"/>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                          <span className="input-group-text" id="">Keywords</span>
                                      </div>
                                      <input onChange={e => this.setState({queryAllOFTheseWords: e.target.value})}
                                             value={this.state.queryAllOFTheseWords} type="text"
                                             className="form-control form-control-user"
                                             placeholder="oversized, sneakers"/>

                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                          <span className="input-group-text" id="">Exclude</span>
                                      </div>
                                      <input onChange={e => this.setState({queryNoneOfTheseWords: e.target.value})}
                                             value={this.state.queryNoneOfTheseWords} type="text"
                                             className="form-control form-control-user"
                                             placeholder="lego, people"/>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="input-group">
                                      <div className="input-group-prepend">
                                          <span className="input-group-text" id="">Exact phrase</span>
                                      </div>
                                      <input onChange={e => this.setState({queryTheseExactWords: e.target.value})}
                                             value={this.state.queryTheseExactWords} type="text"
                                             className="form-control form-control-user"
                                             placeholder="royal penguins, elephants"/>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="input-group">
                                      <div className="input-group-prepend">
                                          <span className="input-group-text" id="">Alternative</span>
                                      </div>
                                      <input onChange={e => this.setState({queryAnyOfTheseWords: e.target.value})}
                                             value={this.state.queryAnyOfTheseWords} type="text"
                                             className="form-control form-control-user"
                                             placeholder="shoes, cars"/>

                                  </div>
                              </div>
                              <div className="col-sm-6">
                                  <div className="input-group">
                                      <div className="input-group-prepend">
                                          <span className="input-group-text" id="">Results (each)</span>
                                      </div>
                                      <input onChange={(e) => {
                                          this.setState({limit: e.target.value})
                                      }} type="number" className="form-control form-control-user custom-control"
                                             value={this.state.limit} placeholder="Results limit (default 40)"/>
                                  </div>
                              </div>
                          </div>
                          <div className="form-group row">
                              <div className="col-12">
                                  <label className="mb-3">
                                      <span className='label-text mr-3'>Automated</span>
                                      <Toggle
                                        id="query-automated"
                                        onChange={this.onToggle}
                                        icons={false}
                                        checked={this.state.automated}
                                      />
                                  </label>
                              </div>
                              {this.state.automated &&
                              <Fragment>
                                  <div className="col-sm-6">
                                      <div className="input-group">
                                          <div className="input-group-prepend">
                                              <span className="input-group-text" id="">Model</span>
                                          </div>
                                          <select onChange={(e) => {
                                              this.setState({model_id: e.target.value})
                                          }} className="form-control form-control-user custom-control custom-select"
                                                  value={this.state.model_id}>
                                              {
                                                  this.props.modelList.map((model) => {
                                                      return <option key={model.id} value={model.id}>{model.display_name}</option>
                                                  })
                                              }
                                          </select>
                                      </div>
                                  </div>
                                  <div className="col-sm-6">
                                      <div className="input-group">
                                          <div className="input-group-prepend">
                                              <span className="input-group-text" id="">Score threshold (%)</span>
                                          </div>
                                          <input onChange={(e) => {
                                              this.setState({scoreThreshold: e.target.value})
                                          }} type="number" className="form-control form-control-user custom-control"
                                                 value={this.state.scoreThreshold} placeholder="Score threshold" disabled={this.state.perFeatureScoreThreshold === true}/>
                                      </div>
                                  </div>
                                  {this.props.hasFeatures &&
                                  <>
                                      <div className="col-12 mt-3">
                                          <label className="mb-3">
                                              <span className='label-text mr-3'>Per feature score threshold</span>
                                              <Toggle
                                                id="query-automated"
                                                onChange={this.onPerFeatureToggle}
                                                icons={false}
                                                checked={this.state.perFeatureScoreThreshold}
                                              />
                                          </label>
                                      </div>
                                      {this.state.perFeatureScoreThreshold &&
                                      <Fragment>
                                          {this.props.features.map(feature => {
                                              return (
                                                <div key={feature.id} className="col-sm-6 mt-3">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"
                                                                  id="">{feature.name}</span>
                                                        </div>
                                                        <input className="form-control form-control-user custom-control"
                                                               type="number"
                                                               value={this.state.featureScoreThresholds[feature.label]}
                                                               name={feature.id}
                                                               onChange={(e) => this.handleOnFeatureScoreThresholdChange(e, feature.label)}/>
                                                    </div>
                                                </div>
                                              )
                                          })}
                                      </Fragment>
                                      }
                                  </>
                                  }
                              </Fragment>
                              }
                          </div>
                      </Fragment>
                  </div>
                  <div className="modal-footer">
                      <button onClick={() => {
                          this.handleModalToggle()
                      }} type="button" className="btn btn-danger">
                          Cancel
                      </button>

                      {!this.props.searchTerm ? (
                        <button onClick={(e) => this.handleSearch(e)}
                                className="btn btn-success btn-icon-split">
                                          <span className="icon text-white-50">
                                            <i className="fas fa-search"></i>
                                          </span>
                            <span className="text">Search</span>
                        </button>
                        ) : (
                        <button onClick={e => {this.handleEdit(e)}} type="button" className="btn btn-success">
                            Save
                        </button>
                        )
                      }
                  </div>
              </Modal>
          </div>
        )
    }
}

export default SearchTermModal;