import React, {Component} from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import FilterPanel from '../filterPanel/FilterPanel';

class ShortlistFilterPanel extends Component {

    render() {
        return (
          <SlidingPanel
            type={'right'}
            isOpen={this.props.isOpen}
            size={48}
            backdropClicked={this.props.onBackdropClick}
            panelClassName='filters-panel-test'
          >
              <div className="panel-container">
                  <div className="panel-header">
                    <p>Filters</p>
                  </div>

                  <div className="panel-body">
                      <div className="row">
                          <div className="col-12 mt-3 d-flex justify-content-end">
                              <div onClick={() => this.props.handleClose()} className="close-icon"></div>
                          </div>
                      </div>
                      <FilterPanel />
                      <div className="row">
                          <div className="col-12 mt-3 d-flex justify-content-center">
                              <button onClick={() => this.props.handleFilterReset()} className="btn btn-secondary mr-2">
                                  Reset All
                              </button>
                              <button onClick={() => this.props.handleFilterChange()} className="btn btn-primary ml-2">
                                  Filter
                              </button>
                          </div>
                      </div>
                  </div>
                  {/*<div className="panel-footer">*/}

                  {/*</div>*/}
              </div>
          </SlidingPanel>
        )
    }
}

export default ShortlistFilterPanel