import React, {Component} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from 'moment';

import {getMonitoringData} from "../../actions/monitoring/monitoringActions"
import SuperAdminSidebar from "../Sidebar/SuperAdminSidebar";
import Topbar from "../Topbar";


class WorkerStats extends Component {
    render() {
        return (
            <div className={'p-3'}>
                <div className={'mb-2 font-weight-bold'}>
                    <div>{this.props.workerData.name}</div>
                </div>
                <div>
                    <div className={'font-weight-light'}>Remaining tasks:</div>
                    <div className={'text-right font-weight-light'}>{this.props.workerData.length}</div>
                </div>
                <div>
                    <div className={'font-weight-light'}>Total number of tasks:</div>
                    <div className={'text-right font-weight-light'}>{this.props.workerData.messages_count} </div>
                </div>
                <div>
                    <div className={'font-weight-light'}>Connected workers:</div>
                    <div className={(() => this.props.workerData.consumers < this.props.workerData.expected_consumers ? 'text-right text-danger font-weight-bold' : 'text-right text-primary font-weight-light')()}>
                        {this.props.workerData.consumers} / {this.props.workerData.expected_consumers}
                    </div>
                </div>

            </div>
        )
    }
}

class SearchResultStat extends Component {
    render() {
        return (
            <div className={`row m-0 ${this.props.monitoringLatestSearchResult.missing_description ? "alert-danger" : ""}`}>
                <div className='p-1 mb-1 col-1'>
                    <div>{this.props.monitoringLatestSearchResult.id}</div>
                </div>
                <div className='p-1 mb-1 col-4'>
                    <div><span className='font-weight-bold'>Date: </span>{moment(this.props.monitoringLatestSearchResult.created).format('YYYY-MM-DD H:ss')} </div>
                </div>
                <div className='p-1 mb-1 col-7'>
                    <div><span className='font-weight-bold'>Description: </span>{this.props.monitoringLatestSearchResult.description}</div>
                </div>
                <div className='p-1 mb-1 col-1'>
                </div>
                <div className='p-1 mb-1 col-4'>
                    <div><span className='font-weight-bold'>Project: </span>{this.props.monitoringLatestSearchResult.project}</div>
                </div>
                <div className='p-1 mb-1 col-7'>
                    <div><span className='font-weight-bold'>Search Term: </span>{this.props.monitoringLatestSearchResult.search_term}</div>
                </div>
            </div>
        )
    }
}


class ServiceStats extends Component {
    render() {
        return (
            <div className={'p-3'}>
                <div className={'font-weight-bold mb-2'}>
                    {this.props.serviceData.name}
                </div>
                {this.props.serviceData.status ? (
                    <span className={'text-primary'}>Healthy</span>
                ) : (
                    <span className={'text font-weight-bold text-danger'}>Unhealthy</span>
                )}
            </div>
        )
    }
}



class Monitoring extends Component {
    componentDidMount() {
        this.props.getMonitoringData()
        this.interval = setInterval(() => this.props.getMonitoringData(), 5000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div id="page-top">
                <div id="wrapper">
                    <SuperAdminSidebar/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content" className="svs">
                            <Topbar withoutProjectStats={true} withoutBranding={true}/>
                            <div className="container">
                                <div className="row">
                                    <div className="col-8">
                                        <h1 className="h3 mb-4 text-white-800">Monitoring</h1>
                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="row">
                                        <div className="col-12 monitoring-stats-section-title m-3">
                                            <h4 className="h4 font-weight-bold mt-2 mb-2">Scheduled tasks</h4>
                                        </div>
                                    </div>
                                    <div className={'monitoring-stats-container'}>
                                        {this.props.monitoringWorkers.map(
                                            workerData => {
                                                return (
                                                    <WorkerStats workerData={workerData}/>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="row">
                                        <div className="col-12 monitoring-stats-section-title m-3">
                                            <h4 className="h4 font-weight-bold mt-2 mb-2">Services</h4>
                                        </div>
                                    </div>
                                    <div className={'monitoring-stats-container'}>
                                        {this.props.monitoringServices.map(
                                            serviceData => {
                                                return (
                                                    <ServiceStats serviceData={serviceData}/>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="row">
                                        <div className="col-12 monitoring-stats-section-title m-3">
                                            <h4 className="h4 font-weight-bold mt-2 mb-2">Search Results</h4>
                                        </div>
                                    </div>
                                    <div className={'monitoring-stats-container msc_c1'}>
                                        {this.props.monitoringLatestSearchResults.map(
                                            monitoringLatestSearchResult => {
                                                return (
                                                    <SearchResultStat monitoringLatestSearchResult={monitoringLatestSearchResult}/>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        monitoringServices: state.monitoring.services,
        monitoringWorkers: state.monitoring.workers,
        monitoringLatestSearchResults: state.monitoring.latest_search_results,
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        getMonitoringData: () => {
            dispatch(getMonitoringData())
        }
    }
}


export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(Monitoring)