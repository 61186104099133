import React, {Component} from "react";
import {ROLES} from "../../utils/enums/Roles";
import {NavLink} from "react-router-dom";

class UserRow extends Component {

    render() {

        let missingRoleColour = 'text-gray-500';
        let presentRoleColour = 'text-dark';

        let superAdminColour = missingRoleColour;
        if (this.props.roles.includes(ROLES.SUPER_ADMIN)) { superAdminColour = presentRoleColour}
        let adminColour = missingRoleColour;
        if (this.props.roles.includes(ROLES.ADMIN)) { adminColour = presentRoleColour}
        let clientColour = missingRoleColour;
        if (this.props.roles.includes(ROLES.CLIENT)) { clientColour = presentRoleColour}

        let firstLogInStatus = <i className="fas fa-check text-gray-500"></i>;
        if (this.props.first_log_in) { firstLogInStatus = <i className="fas fa-check-double text-success"></i>; }

        return (
            <div className="row">
                <div className="col-4">
                    <p className={'font-weight-bold text-dark'}>{this.props.email}</p>
                </div>

                <div className="col-4">
                    <p>{[this.props.first_name, this.props.last_name].join(' ')}</p>
                </div>

                <div className="col-1">
                    <p>
                        <span className={'mr-1 font-weight-light ' + superAdminColour}>Sa</span>
                        <span className={'mr-1 font-weight-light ' + adminColour}>A</span>
                        <span className={'mr-1 font-weight-light ' + clientColour}>C</span>
                    </p>
                </div>

                <div className="col-1">
                    {firstLogInStatus}
                </div>

                <div className="col-2 text-right">
                    <NavLink to={"/users/edit/" + this.props.id} className="btn btn-primary image-info-btn mr-2"><i className="fa fa-edit"/></NavLink>
                    <NavLink to={"/users/projects/" + this.props.id} className="btn btn-primary image-info-btn mr-2"><i className="fa fa-list"/></NavLink>
                </div>

            </div>
        )
    }

}

export default UserRow;