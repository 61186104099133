import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar"
import Topbar from "./Topbar"

class Dashboard extends Component {

    render() {
        return (
            <div id="page-top">
                <div id="wrapper">
                    <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar />
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="card shadow mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Find dupes</h6>
                                            </div>
                                            <div className="card-body">
                                                <p>Search for dupes on a variety of platforms</p>
                                                <Link to={`/project/${this.props.match.params.projectId}/search`} className="btn btn-success btn-icon-split mb-3 mr-3">
                                                    <span className="icon text-white-50">
                                                        <i className="fas fa-search"></i>
                                                    </span>
                                                    <span className="text">Find dupes</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card shadow mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Shortlist</h6>
                                            </div>
                                            <div className="card-body">
                                                <p>See the list of images that you have marked as potential dupes</p>
                                                <Link to={`/project/${this.props.match.params.projectId}/shortlist`} className="btn btn-primary btn-icon-split mb-3 mr-3">
                                                    <span className="icon text-white-50">
                                                        <i className="fas fa-list"></i>
                                                    </span>
                                                    <span className="text">Shortlist</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;
