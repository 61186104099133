import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import DataTable, {memoize} from 'react-data-table-component';
import ListItemTitle from './reportsList/ListItemTitle';
import ProcessStatus from './reportsList/ProcessStatus';
import DownloadLink from './reportsList/DownloadLink';
import ReportListHeaderMenu from './reportsList/HeaderMenu';
import {doFetchReportsList, downloadReport} from "../../actions/reports/reportsAction";
import {labelFromStatusCode} from "../../utils/status/statusFilterOptions";

const columns = memoize((projectId, downloadReport) => [
    {
        name: 'Name',
        selector: 'name',
        cell: (row) => <ListItemTitle row={row}/>,
        maxWidth: '300px',
    },
    {
        name: 'Created',
        selector: 'created',
        cell: (row) => row.created,
    },
    {
        name: 'Status',
        selector: 'marked_sr_category',
        // cell: (row) => <Status row={row}/>,
        cell: row => labelFromStatusCode(row.marked_sr_category),
    },
    {
        name: 'Process status',
        selector: 'process_status',
        cell: (row) => <ProcessStatus row={row}/>,
        center: true,
        maxWidth: '150px',
    },
    {
        name: 'Download',
        selector: '',
        cell: (row) => <DownloadLink key={row.id} projectId={projectId} download={downloadReport} row={row}/>,
        center: true,
        maxWidth: '50px',
    },
]);

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filteredText: '',
            startDate: null,
            endDate: null
        }
    }

    componentDidMount() {
        this.props.doFetchReportsList(this.props.projectId)
        this.interval = setInterval(() => this.props.doFetchReportsList(this.props.projectId), 5000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleOnFilterTextChange = (value) => {
        this.setState({
            filteredText: value
        })
    };

    render() {


        let filtered = {
            items: [...this.props.reports.reportsList]
        }

        if (this.state.filteredText !== '') {
            filtered.items = filtered.items.filter(item => item.name.toLowerCase().includes(this.state.filteredText.toLowerCase()))
        }

        if (this.state.startDate !== null && this.state.endDate === null) {
            filtered.items = filtered.items.filter(item => new Date(item.created).getTime() >= new Date(this.state.startDate).getTime())
        }

        if (this.state.endDate !== null && this.state.startDate === null) {
            filtered.items = filtered.items.filter(item => new Date(item.created).getTime() <= new Date(this.state.endDate).setHours(23,59,59))
        }

        if (this.state.startDate !== null && this.state.endDate !== null) {
            filtered.items = filtered.items.filter(item => (new Date(item.created).getTime() >= new Date(this.state.startDate).getTime()) && (new Date(item.created).getTime() <= new Date(this.state.endDate).setHours(23, 59, 59)))
        }

        return (
          <Fragment>
              {this.props.reports.downloading &&
                  <div className="overlay text-center">
                          <i className="fas fa-circle-notch fa-spin fetching-spinner mt-5"></i>
                  </div>
              }
              <div className="card shadow">
                  <DataTable
                    columns={columns(this.props.projectId, this.props.downloadReport)}
                    data={filtered.items}
                    keyField={'id'}
                    pagination
                    paginationPerPage={30}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 75, 100]}
                    // defaultSortField={'created'}
                    defaultSortAsc={false}
                    noDataComponent={"No reports"}
                    subHeader
                    subHeaderComponent={
                        <ReportListHeaderMenu
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            handleStartDateChange={date => this.setState({startDate: date})}
                            handleEndDateChange={date => this.setState({endDate: date})}
                            value={this.state.filteredText}
                            handleOnFilterTextChange={this.handleOnFilterTextChange}
                            toggleModal={this.props.toggleModal}
                        />}
                  />
              </div>
          </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reports: state.reports,
    }
};

const mapActionToProps = (dispatch) => {
    return {
        doFetchReportsList: (projectId) => {dispatch(doFetchReportsList(projectId))},
        downloadReport: (payload) => {dispatch(downloadReport((payload)))}
    }
};

export default connect(mapStateToProps, mapActionToProps)(List);
