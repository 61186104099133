import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class ListItemTitle extends Component {

    render() {
        let singleSearchRoute = `/search/${this.props.row.id}`;

        return (
          <Link to={singleSearchRoute}>{this.props.row.name || ''}</Link>
        )
    }
}

export default ListItemTitle;